import Grid from "@material-ui/core/Grid";
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Charts from "../../components/reports/Charts";
import Payments from "../../components/reports/Payments";
import TicketDetail from "../../components/reports/TicketDetail";
import Tickets from "../../components/reports/Tickets";
import MenuIcon from "@material-ui/icons/Menu";
import { MobileContext } from "../Mobile";

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "hidden"
  },
  card: {
    margin: theme.spacing(2, 0)
  },
  title: {
    flexGrow: 1
  },
  container: {
    paddingTop: theme.spacing(2)
  }
}));

const reports = [
  { value: "charts", label: "Gráficos e indicadores", component: () => <Charts /> },
  { value: "ticket", label: "Detalle de caso", component: () => <TicketDetail /> },
  { value: "tickets", label: "Cartola de casos", component: () => <Tickets /> },
  { value: "payments", label: "Cartola de pagos", component: () => <Payments /> }
];

const Reports = () => {
  const [report, setReport] = useState(null);
  const { onDrawerOpen } = useContext(MobileContext);
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {onDrawerOpen && (
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              onClick={onDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h6" component="p">
            {report ? report.label : "Seleccionar tipo de reporte"}
          </Typography>
          {report && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="back"
              onClick={() => setReport(null)}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Grid container spacing={0} className={classes.overflowHidden}>
        {report ? (
          report.component()
        ) : (
          <Grid item xs={12}>
            <Container className={classes.container} maxWidth="sm">
              {reports.map((report, i) => (
                <Card key={`reporte-${i}`} className={classes.card} variant="outlined">
                  <CardActionArea onClick={() => setReport(report)}>
                    <CardContent>
                      <Typography variant="h6" component="p" align="center">
                        {report.label}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Container>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Reports;
