import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import React, { useEffect, useState, useContext } from "react";
import AppBarCreate from "../common/AppBarCreate";
import Loading from "../common/Loading";
import CreateTicketBanigualdad from "./Banigualdad/TicketCreate";
import CreateTicketAutofin from "./Autofin/TicketCreate";
import CreateTicketBCI from "./BCI/TicketCreate";
import CreateTicketHogar from "./BancoEstadoHogar/TicketCreate";
import CreateTicketApoyoMedico from "./ApoyoMedico/TicketCreate";
import CreateTicketRipleySura from "./RipleySura/TicketCreate";
import CreateTicketSouthBridge from "./SouthBridge/TicketCreate";
import CreateTicketAsistenciasServiclick from "./AsistenciasServiclick/TicketCreate";
import BaseCreateTicket from "./base/TicketCreate";

//Context
import { DesktopContext } from "../../views/Desktop";
import { MobileContext } from "../../views/Mobile";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100vw"
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  card: {
    margin: theme.spacing(2, 0)
  },
  pos: {
    marginBottom: theme.spacing(1)
  }
}));

const TicketCreate = ({ mobile, open, onClose, contact }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  //context
  const { removeContactFromTicket } = useContext(DesktopContext); 
  const { removeContactFromTicketMobile } = useContext(MobileContext); 

  useEffect(() => {
    const fetchCuentas = async () => {
      try {
        setLoading(true);

        const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

        let accounts = [];

        if (result.success) {
          if (result.data) accounts = result.data;
        } else console.warn("fetchCuentas", result.error);
        //Seleccion automatica de contacto
        let found = false;
        if (contact && accounts && accounts.length > 0){
          for (const acc in accounts){
            if (accounts[acc].nombre_cuenta === contact.nombre_cuenta){
              found = true;              
              setSelectedAccount(accounts[acc]);
              break;
            }
          }
        }
        setAccounts(accounts);  
      } catch (err) {
        console.error("fetchCuentas", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCuentas();
  }, []);

  const onBack_ticket = () =>
    {
      setSelectedAccount(null);
      if (mobile) {
        removeContactFromTicketMobile();
      }
      else{
        removeContactFromTicket();
      }
      
    };
  const onClose_ticket = () =>
    {
      setSelectedAccount(null);
      if (mobile) {
        removeContactFromTicketMobile();
      }
      else{
        removeContactFromTicket();
      }
      onClose();
    };
  const SelectedAccountCreate = () => {
    switch (selectedAccount.slug_cuenta) {
      case "banigualdad":
        return (
          <CreateTicketBanigualdad
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
          />
        );

      case "autofin":
        return (
          <CreateTicketAutofin
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
          />
        );

      case "BCI Urgencia":
        return (
          <CreateTicketBCI
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
          />
        );

      case "banco-estado-hogar":
        return (
          <CreateTicketHogar
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
          />
        );

      case "asistencia-apoyo-medico":
        return (
          <CreateTicketApoyoMedico
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
          />
        );
        
      case "ripley-sura":
        return (
          <CreateTicketRipleySura
          mobile={mobile}
          account={selectedAccount}
          onBack={onBack_ticket}
          onClose={onClose_ticket}
          />
        )

      case "southbridge-mascota":
        return (
          <CreateTicketSouthBridge
          mobile={mobile}
          account={selectedAccount}
          onBack={onBack_ticket}
          onClose={onClose_ticket}
          />
        )
      
        case "asistencias-serviclick":
          console.log('yey');
          return (
            <CreateTicketAsistenciasServiclick
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
            />
          )

      default:
        return (
          <BaseCreateTicket
            mobile={mobile}
            account={selectedAccount}
            onBack={onBack_ticket}
            onClose={onClose_ticket}
          />
        );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.paper
      }}
    >
      {!selectedAccount ? (
        <>
          <AppBarCreate title="Nuevo Caso" onClose={onClose} />
          {loading ? (
            <Loading />
          ) : (
            <Container className={classes.container} maxWidth="sm">
              <Typography variant="h6" component="p">
                Seleccionar cuenta
              </Typography>
              {accounts
                .filter(account => account.config_cuenta.creaTicket)
                .map((account, i) => (
                  <Card key={`cuenta-${i}`} className={classes.card} variant="outlined">
                    <CardActionArea onClick={() => setSelectedAccount(account)}>
                      <CardContent>
                        <Typography variant="h6" component="p" align="center">
                          {account.alias_cuenta}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary" align="center">
                          {account.config_cuenta.descripcion}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
            </Container>
          )}
        </>
      ) : (
        <SelectedAccountCreate />
      )}
    </Drawer>
  );
};

export default TicketCreate;
