import React, { useState, useEffect } from "react";
import dotenv from "dotenv";
import parseISO from "date-fns/parseISO";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import Amplify from "aws-amplify";
import { DateUtils } from "@aws-amplify/core";
import { Authenticator } from "aws-amplify-react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import CssBaseline from "@material-ui/core/CssBaseline";
import awsconfig from "../aws-exports";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import CompleteNewPassword from "./auth/CompleteNewPassword";
import ForgotPassword from "./auth/ForgotPassword";
import SignInSide from "./auth/SignInSide";
import Loading from "./common/Loading";
import ServerError from "../views/ServerError";

dotenv.config();
Amplify.configure(awsconfig);

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: "#28A0C0"
      },
      secondary: {
        main: "#8dc13d",
        contrastText: "#fff"
      }
    },
    overrides: {
      MuiPopover: {
        root: {
          zIndex: "1401 !important"
        }
      }
    }
  },
  esES
);

const MAX_SIGNV4_OFFSET = 300000;

const Root = () => {
  const [clockSkew, setClockSkew] = useState(null);
  const [isError, setIsError] = useState(false);

  async function setClockOffset() {
    try {
      const result = await fetch(`${process.env.REACT_APP_URL_UTILS}/time`);

      if (result.ok) {
        const data = await result.json();
        const servertime = parseISO(data);

        const timediff = Math.abs(differenceInMilliseconds(new Date(), servertime));

        if (timediff > MAX_SIGNV4_OFFSET) {
          DateUtils.setClockOffset(timediff);
        }

        setClockSkew(timediff);
      } else {
        setClockSkew(-1);
        throw new Error(result.statusText);
      }
    } catch (err) {
      console.log("Root.setClockOffset.error", err);
      setIsError(true);
    }
  }

  useEffect(() => {
    setClockOffset();
  }, []);

  if (!Boolean(clockSkew)) return <Loading variant="fullscreen" open={true} />;
  if (isError) return <ServerError />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Authenticator hideDefault>
          <SignInSide override="SignIn" />
          <ForgotPassword override="ForgotPassword" />
          <CompleteNewPassword override="RequireNewPassword" />
          <App />
        </Authenticator>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Root;
