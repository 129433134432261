import React, { useState } from "react";
import { Storage } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hidden from "@material-ui/core/Hidden";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilePreview from "../common/FilePreview";
import TicketHistory from "./TicketHistory";
import TicketDetailsBase from "./base/TicketDetails";
import TicketDetailsAutofin from "./Autofin/TicketDetails";
import TicketDetailsBanigualdad from "./Banigualdad/TicketDetails";
import TicketDetailsSegurosRipley from "./SegurosRipley/TicketDetails";
import TicketDetailsBCIUrgencias from "./BCI/TicketDetails";

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  container: {
    overflow: "auto",
    height: "calc(100vh - 64px)",
    padding: 0
  },
  img: {
    objectFit: "cover",
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    }
  }
}));

const tabs = [
  { key: "detail", label: "Detalle" },
  { key: "history", label: "Historial" }
];

const TicketDetailView = ({ mobile, ticket, onClose }) => {
  const classes = useStyles();
  const [mainTab, setMainTab] = useState(0);
  const [showCard, setShowCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewFileUrl, setPreviewFileUrl] = useState(null);

  const TabPanel = props => {
    const { value, index } = props;

    return (
      <Container>
        <div
          className={classes.tabContent}
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tarea-tab-${index}`}
        >
          {value === index && value === 0 ? (
            renderTabContentDetail()
          ) : (
            <TicketHistory ticket={ticket.id_ticket} />
          )}
        </div>
      </Container>
    );
  };

  const handleSelectCard = card => {
    if (card) {
      setShowCard(true);
      setSelectedCard(card);
    } else {
      setShowCard(false);
    }
  };

  const handlePreviewOpen = f => {
    setPreviewFile(f);
    setPreviewOpen(true);

    if (f.bucket) {
      var fbucket = f.bucket
      if (fbucket === "serviclickafiliadosstorage212216-master"){
        fbucket = "serviclickafiliadosprod01234055-staging"
      }
      const config = {
        bucket: fbucket
      };

      Storage.get(f.key, config)
        .then(result => setPreviewFileUrl(result))
        .catch(err => console.log(err));
    } else {
      Storage.get(f.key)
        .then(result => setPreviewFileUrl(result))
        .catch(err => console.log(err));
    }
  };

  const handlePreviewClose = e => {
    setPreviewOpen(false);
    setPreviewFileUrl(null);
  };

  const renderTabContentDetail = () => {
    switch (ticket.slug_cuenta) {
      case "autofin":
        return (
          <TicketDetailsAutofin
            mobile={mobile}
            ticket={ticket}
            onSelectCard={handleSelectCard}
            onClickFile={handlePreviewOpen}
          />
        );
      case "BCI Urgencia":
        return (
          <TicketDetailsBCIUrgencias
            mobile={mobile}
            ticket={ticket}
            onSelectCard={handleSelectCard}
            onClickFile={handlePreviewOpen}
          />
        );
      case "banigualdad":
        return (
          <TicketDetailsBanigualdad
            mobile={mobile}
            ticket={ticket}
            onSelectCard={handleSelectCard}
            onClickFile={handlePreviewOpen}
          />
        );
      case "seguros-ripley":
        return (
          <TicketDetailsSegurosRipley
            mobile={mobile}
            ticket={ticket}
            onSelectCard={handleSelectCard}
            onClickFile={handlePreviewOpen}
          />
        );
      default:
        return (
          <TicketDetailsBase
            mobile={mobile}
            ticket={ticket}
            onSelectCard={handleSelectCard}
            onClickFile={handlePreviewOpen}
          />
        );
    }
  };

  return (
    ticket && (
      <>
        <AppBar position="sticky">
          <Toolbar>
            {onClose && (
              <IconButton
                className={classes.backButton}
                edge="start"
                color="inherit"
                onClick={onClose}
              >
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography className={classes.title} variant="h6" component="p">
              {`${ticket.nombre_ticket} - #${ticket.folio_ticket}`}
            </Typography>

            <IconButton className={classes.searchButton} edge="end" color="inherit">
              <MoreVertIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <FilePreview
          file={previewFile}
          url={previewFileUrl}
          open={previewOpen}
          onClose={handlePreviewClose}
        />

        <Container className={classes.container}>
          <AppBar position="sticky">
            <Tabs
              value={mainTab}
              variant="fullWidth"
              onChange={(_, newValue) => setMainTab(newValue)}
              aria-label="ticket detail tabs"
            >
              {tabs.map((item, i) => (
                <Tab key={`tab-item-${i}`} label={item.label} />
              ))}
            </Tabs>
          </AppBar>

          {tabs.map((_, i) => (
            <TabPanel key={`tab-panel-${i}`} value={mainTab} index={i} />
          ))}
        </Container>

        <Hidden mdUp>
          <Drawer anchor={"bottom"} open={showCard} onClose={() => setShowCard(false)}>
            {selectedCard}
          </Drawer>
        </Hidden>
      </>
    )
  );
};

export default TicketDetailView;
