import Grid from "@material-ui/core/Grid";
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { API } from "aws-amplify";
import Snackbar from "@material-ui/core/Snackbar";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../common/Loading";
import { formatRut } from "../../functions";
import TicketHistory from "../tickets/TicketHistory";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    margin: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  }
}));

const TicketDetail = () => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [ticket, setTicket] = useState(null);
  const classes = useStyles();

  const fetchAccounts = useCallback(async () => {
    try {
      setLoading(true);

      const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

      if (result.success) {
        if (result.data) setAccounts(result.data);
      } else console.warn("fetchAccounts", result.error);
    } catch (err) {
      console.error("fetchAccounts", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    !account && fetchAccounts();
  }, [account, fetchAccounts]);

  const search = async value => {
    setLoading(true);

    if (value) {
      try {
        const init = {
          queryStringParameters: {
            cuenta: account.id_cuenta,
            folio: parseInt(value)
          }
        };

        const result = await API.get(process.env.REACT_APP_HUB_API, "/tickets/folio", init);

        if (result.success) {
          if (result.data) {
            setMessage("Caso encontrado");
            setTicket(result.data);
          } else {
            setMessage("Caso no encontrado");
            setTicket(null);
          }
          setOpen(true);
        }
      } catch (err) {
        console.error("fetchContactos", err);
      }
    }

    setLoading(false);
  };

  const alert = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = date => {
    const formattedDate = new Date(date);

    return formattedDate.toLocaleString("es-CL");
  };

  return (
    <Grid container justify="center" spacing={2}>
      {alert()}
      <Grid item xs={12} md={8}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" component="p">
              Seleccione una cuenta
            </Typography>
            <Select
              fullWidth
              classes={{ selectMenu: classes.select }}
              variant="outlined"
              value={account}
              onChange={e => {
                setAccount(e.target.value);
              }}
            >
              {accounts.map(item => (
                <MenuItem key={`item-${item.id_cuenta}`} value={item}>
                  {item.alias_cuenta}
                </MenuItem>
              ))}
            </Select>
          </CardContent>
        </Card>
      </Grid>
      {account && (
        <Grid item xs={10}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" component="p">
                    Ingresar número de caso
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <NumberFormat
                    fullWidth
                    id="ticket"
                    allowNegative={false}
                    customInput={TextField}
                    size="small"
                    variant="outlined"
                    placeholder="Ej: 1234"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyDown={e => {
                      e.keyCode === 13 && search(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              {loading && <Loading />}

              {ticket && (
                <Grid container spacing={0}>
                  <Grid item container spacing={2} className={classes.divider}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" component="p">
                        {`${ticket.nombre_ticket} - #${ticket.folio_ticket}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Cuenta:</strong>
                        <br />
                        {ticket.alias_cuenta}
                      </Typography>
                    </Grid>

                    {ticket.usuario && (
                      <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Ejecutivo:</strong>
                        <br />
                        {`${ticket.usuario.primer_nombre_usuario} ${ticket.usuario.apellido_paterno_usuario}`}
                      </Typography>
                    </Grid>
                    )}

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Estado:</strong>
                        <br />
                        {ticket.pasos_pipeline.length - 1 === ticket.estado_ticket
                          ? ticket.pasos_pipeline[ticket.estado_ticket]
                          : ticket.pasos_pipeline[ticket.estado_ticket + 1]}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Fecha de creación:</strong>
                        <br />
                        {formatDate(ticket.body_ticket.fecha)}
                      </Typography>
                    </Grid>

                    {ticket.body_ticket.certificado && (
                      <Grid item xs={4}>
                        <Typography variant="body1" component="p">
                          <strong>Certificado de defunción:</strong>
                          <br />
                          {ticket.body_ticket.certificado.toUpperCase()}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Ubicación:</strong>
                        <br />
                        {ticket.body_ticket.ubicacion}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body1" component="p">
                        <strong>Descripción:</strong>
                        <br />
                        {ticket.body_ticket.descripcion}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2} className={classes.divider}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" component="p">
                        Asegurado
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Nombre:</strong>
                        <br />
                        {ticket.body_ticket.asegurado.nombre_contacto}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>RUT:</strong>
                        <br />
                        {formatRut(ticket.body_ticket.asegurado.body_contacto.rut)}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Correo:</strong>
                        <br />
                        {ticket.body_ticket.asegurado.body_contacto.correo
                          ? ticket.body_ticket.asegurado.body_contacto.correo
                          : "No registrado"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Fecha de nacimiento:</strong>
                        <br />
                        {ticket.body_ticket.asegurado.body_contacto.fecha_nacimiento
                          ? formatDate(ticket.body_ticket.asegurado.body_contacto.fecha_nacimiento)
                          : "No registrado"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Fecha de otorgamiento de póliza:</strong>
                        <br />
                        {ticket.body_ticket.asegurado.body_contacto.fecha_otorgamiento
                          ? formatDate(
                              ticket.body_ticket.asegurado.body_contacto.fecha_otorgamiento
                            )
                          : "No registrado"}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Fecha de vencimiento de póliza:</strong>
                        <br />
                        {ticket.body_ticket.asegurado.body_contacto.fecha_termino
                          ? formatDate(ticket.body_ticket.asegurado.body_contacto.fecha_termino)
                          : "No registrado"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2} className={classes.divider}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" component="p">
                        Contacto
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Nombre:</strong>
                        <br />
                        {ticket.body_ticket.beneficiario.nombre_contacto}
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Teléfono:</strong>
                        <br />
                        <NumberFormat
                          value={ticket.body_ticket.beneficiario.body_contacto.telefono}
                          format="+56 9 #### ####"
                          displayType={"text"}
                        />
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body1" component="p">
                        <strong>Correo:</strong>
                        <br />
                        {ticket.body_ticket.asegurado.body_contacto.correo
                          ? ticket.body_ticket.asegurado.body_contacto.correo
                          : "No registrado"}
                      </Typography>
                    </Grid>
                  </Grid>

                  {ticket.body_ticket.gestionar_asistencia && (
                    <Grid item container spacing={2} className={classes.divider}>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="h6" component="p">
                          Prestador
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider />
                      </Grid>

                      {!ticket.body_ticket.gestionar_asistencia.red &&
                      ticket.body_ticket.gestionar_asistencia.prestador ? (
                        <>
                          <Grid item xs={4}>
                            <Typography variant="body1" component="p">
                              <strong>Nombre:</strong>
                              <br />
                              {ticket.body_ticket.gestionar_asistencia.prestador.nombre_contacto}
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="body1" component="p">
                              <strong>RUT:</strong>
                              <br />
                              {formatRut(
                                ticket.body_ticket.gestionar_asistencia.prestador.body_contacto.rut
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="body1" component="p">
                              <strong>Correo:</strong>
                              <br />
                              {
                                ticket.body_ticket.gestionar_asistencia.prestador.body_contacto
                                  .correo_contacto
                              }
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="body1" component="p">
                              <strong>Teléfono:</strong>
                              <br />
                              <NumberFormat
                                value={
                                  ticket.body_ticket.gestionar_asistencia.prestador.body_contacto
                                    .telefono_contacto
                                }
                                format="+56 9 #### ####"
                                displayType={"text"}
                              />
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="body1" component="p">
                              <strong>Dirección:</strong>
                              <br />
                              {
                                ticket.body_ticket.gestionar_asistencia.prestador.body_contacto
                                  .direccion.description
                              }
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Typography variant="body1" component="p">
                            <strong>Sin prestador en zona</strong>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  <Grid item container spacing={2} className={classes.divider}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" component="p">
                        Historial del caso
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <TicketHistory ticket={ticket.id_ticket} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default TicketDetail;
