import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import AppBarCreate from "../../common/AppBarCreate";
import { TYPES_KEYS, CONTACT_TYPES } from "./constants";
import AseguradoCreate from "./AseguradoCreate";
import PrestadorCreate from "./PrestadorCreate";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  card: {
    margin: theme.spacing(2, 0)
  },
  pos: {
    marginBottom: theme.spacing(1)
  }
}));

const ContactCreate = ({ account, accounts, sending, onBack, onClose, onCreate }) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState(null);

  const ContactCreateType = () => {
    switch (selectedType.key) {
      case TYPES_KEYS.asegurado:
        return (
          <AseguradoCreate
            account={account}
            sending={sending}
            onBack={() => setSelectedType(null)}
            onClose={onClose}
            onCreate={onCreate}
          />
        );
      case TYPES_KEYS.prestador:
        return (
          <>
            <PrestadorCreate
              account={account}
              accounts={accounts}
              sending={sending}
              onBack={() => setSelectedType(null)}
              onClose={onClose}
              onCreate={onCreate}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return !selectedType ? (
    <>
      <AppBarCreate title="Crear contacto" onBack={onBack} onClose={onClose} />
      <Container className={classes.container} maxWidth="sm">
        <Typography variant="h6" component="p">
          Seleccionar tipo de contacto
        </Typography>
        {CONTACT_TYPES.filter(type => type.create).map((type, i) => (
          <Card key={`contact-type-${i}`} className={classes.card} variant="outlined">
            <CardActionArea onClick={() => setSelectedType(type)}>
              <CardContent>
                <Typography variant="h6" component="p" align="center">
                  {type.label}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Container>
    </>
  ) : (
    <ContactCreateType />
  );
};

export default ContactCreate;
