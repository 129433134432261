import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0)
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

const ContentCardBeneficiary = ({ mobile, beneficiario }) => {
  const classes = useStyles();

  return (
    <Card className={!mobile && classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <GroupIcon color="disabled" />
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Nombre
          </Grid>
          <Grid item xs={6}>
            {beneficiario.nombre_contacto}
          </Grid>
          <Grid item xs={6}>
            Correo
          </Grid>
          <Grid item xs={6}>
            {beneficiario.body_contacto.correo}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentCardBeneficiary;
