import Hidden from "@material-ui/core/Hidden";
import Amplify, { API, Auth } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";
import { DRAWER_KEYS } from "../constants/drawer";
import Desktop from "../views/Desktop";
import Mobile from "../views/Mobile";
import Loading from "./common/Loading";
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);
API.configure(awsconfig);

export const AppContext = createContext("AppContext");

export const signOut = async () => {
  await Auth.signOut();
};

const App = ({ authState, authData }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(DRAWER_KEYS.tasks);

  useEffect(() => {
    const fetchUser = async userId => {
      try {
        setLoading(true);
        const init = {
          queryStringParameters: {
            userId
          }
        };
        const result = await API.get(process.env.REACT_APP_HUB_API, "/usuarios", init);
        if (result.success) {
          const data = result.data;
          if (data) {
            setUser(result.data);
          } else {
            setUser(null);
          }
        } else console.warn("fetchUser", result);
      } catch (err) {
        console.error("fetchUser", err);
        await signOut();
      } finally {
        setLoading(false);
      }
    };
    if (authState === "signedIn") {
      if (authData && !authData.challengeName) {
        fetchUser(authData.username);
      }
    }
  }, [authState, authData]);

  if (authState !== "signedIn" || (authData || {}).challengeName) {
    return null;
  }

  const context = {
    authState,
    authData,
    user,
    view,
    onViewChange: view => setView(view)
  };

  return (
    <AppContext.Provider value={context}>
      {loading && <Loading variant="fullscreen" open={loading} />}

      {!loading && (
        <Hidden mdUp>
          <Mobile />
        </Hidden>
      )}

      {!loading && (
        <Hidden smDown>
          <Desktop />
        </Hidden>
      )}
    </AppContext.Provider>
  );
};

export default App;
