import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import NumberFormat from "react-number-format";
import { API, Storage } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PolicyFileIcon from "@material-ui/icons/Policy";
import DescriptionIcon from "@material-ui/icons/Description";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import PersonIcon from "@material-ui/icons/Person";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { formatDate, formatRut } from "../../functions";
import FilePreview from "../common/FilePreview";
import ImageLoader from "../common/ImageLoader";
import Loading from "../common/Loading";
//Modal para mostrar la poliza
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import de coberturas
import { COBERTURAS } from "../contacts/BCI Urgencia/constants";
const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: "right"
  },
  alignCenter: {
    textAlign: "center"
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  bottomContainer: {
    padding: theme.spacing(2)
  },
  colorGrey: {
    color: "#777"
  },
  positionRelative: {
    position: "relative",
    height: "calc(100vh - 112px)"
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 125,
    [theme.breakpoints.down("sm")]: {
      minHeight: 80
    }
  },
  cardActionArea: {
    width: "auto",
    padding: theme.spacing(2)
  },
  imageLoader: {
    "& img": {
      objectFit: "cover",
      width: theme.spacing(10),
      height: theme.spacing(10)
    }
  },
  imageLoaderPreview: {
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  },
  img: {
    objectFit: "cover",
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    }
  },
  imgPreview: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  downloadButton: {
    fontSize: "20px"
  }
}));

const Detail = ({ task, mobile }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [user, setUser] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [previewFileUrl, setPreviewFileUrl] = useState(null);
  const [poliza, setPoliza] = useState(null);
  const [showPoliza, setShowPoliza] = useState(false);
  const [state, setState] = useState({
    bottom: false,
    component: "data"
  });
  const [actualTask, setActualTask] = useState([]);

  const fetchTask = useCallback(async () => {
    setLoading(true);

    const init = {
      queryStringParameters: {
        tarea: task.id_tarea
      }
    };

    try {
      const result = await API.get(process.env.REACT_APP_HUB_API, "/tareas", init);

      if (result.success) {
        setFiles(result.data.archivos);
        setUser(result.data.usuario);
        setActualTask(result.data);
      } else {
        console.warn("fetchTask", result.error);
      }
    } catch (err) {
      console.error("fetchTask", err);
    }

    setLoading(false);
  }, [task]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const toggleDrawer = (anchor, open, component) => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open, component: component });
  };

  const getCobertura = (cod) => {
    let cobertura = null;
    COBERTURAS.forEach(element => {    
      if (element.cod === cod){      
        cobertura = element.label;
      }
    });
    return cobertura;
  };

  const parse_direccion = (direccion) => {
    if (!direccion){
      return null;
    }
    const comuna = direccion.comuna ? direccion.comuna : null
    const descripcion = direccion.description ? direccion.description : (direccion.descripcion ? direccion.descripcion : direccion.direccion);
    const ciudad = direccion.ciudad;

    var actual_direccion = descripcion ? `${descripcion}` : descripcion
    if (actual_direccion && comuna){
      actual_direccion += `, ${comuna}`
    }
    if (actual_direccion && ciudad){
      actual_direccion += `, ${ciudad}`
    }
    return actual_direccion;
  }

  const component = () => {
    switch (state["component"]) {
      case "data":
        return data();
      case "asegurado":
        return asegurado();
      case "contacto":
        return contacto();
      case "archivos":
        return archivos();
      case "poliza":
        return detalle_poliza();
      default:
        return <></>;
    }
  };

  const getPoliza = () => {
      Storage.get(actualTask.config_cuenta.poliza, { download: true })
      .then(result => {
        try {
          const pdf_poliza = URL.createObjectURL(result.Body);
          setPoliza(pdf_poliza);
          if (mobile) {
            window.open(pdf_poliza);          
          }          
        } catch (error) {
          console.log("downloadFile", error);
          setPoliza(null);          
        }
      })
      .catch(err => console.log(err));  
  };

  const togglePoliza = () => {
    if (actualTask.config_cuenta.poliza){
      getPoliza();
      if (!mobile){
        setShowPoliza(true);
      }      
    }else{
      //console.log("no hay poliza adjunta")
      toggleDrawer("bottom", true, "poliza");
    }
  };
  const toggleOffPoliza = () => {
    setShowPoliza(false);
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "bottom"
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container maxWidth="sm" className={classes.bottomContainer}>
        {component()}
      </Container>
    </div>
  );

  const handlePreviewOpen = f => {
    setPreviewFile(f);
    setPreviewOpen(true);
    Storage.get(f.key)
      .then(result => setPreviewFileUrl(result))
      .catch(err => console.log(err));
  };

  const handlePreviewClose = e => {
    setPreviewOpen(false);
    setPreviewFileUrl(null);
  };

  const data = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="p">
            Datos de solicitud
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={0}>
          {user && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Ejecutivo creador</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${user.primer_nombre_usuario} ${user.apellido_paterno_usuario}`}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Fecha creación</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {formatRelative(new Date(actualTask.body_ticket.fecha), Date.now(), {
                locale: es
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <strong>Servicio</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{actualTask.alias_cuenta}</Typography>
          </Grid>
          {actualTask.body_ticket.ubicacion && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Ubicación</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${actualTask.body_ticket.ubicacion}`}</Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.plan && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Plan</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${actualTask.body_ticket.asegurado.body_contacto.plan}`}</Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.adicional1 && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Adicional 1</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${actualTask.body_ticket.asegurado.body_contacto.adicional1}`}</Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.adicional2 && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Adicional 2</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${actualTask.body_ticket.asegurado.body_contacto.adicional2}`}</Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.adicional3 && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Adicional 3</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${actualTask.body_ticket.asegurado.body_contacto.adicional3}`}</Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.adicional4 && (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Adicional 4</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{`${actualTask.body_ticket.asegurado.body_contacto.adicional4}`}</Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {actualTask.body_ticket.tipo_siniestro && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Tipo de siniestro</strong>
            </Typography>
            <Typography>{actualTask.body_ticket.tipo_siniestro}</Typography>
          </Grid>
        )}
        {getCobertura(actualTask.body_ticket.asegurado.body_contacto.cobertura) && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Cobertura</strong>
            </Typography>
            <Typography>{getCobertura(actualTask.body_ticket.asegurado.body_contacto.cobertura)}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Descripción del siniestro</strong>
          </Typography>
          <Typography>{actualTask.body_ticket.descripcion}</Typography>
        </Grid>
      </Grid>
    );
  };

  const asegurado = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="p">
            Asegurado
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={0}>
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.colorGrey}>
              <PersonIcon />
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">{actualTask.body_ticket.asegurado.nombre_contacto}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.colorGrey}>
              <FeaturedPlayListIcon />
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">
              {formatRut(actualTask.body_ticket.asegurado.body_contacto.rut)}
            </Typography>
          </Grid>
          {actualTask.body_ticket.asegurado.body_contacto.fecha_nacimiento && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Fecha de nacimiento</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${formatDate(actualTask.body_ticket.asegurado.body_contacto.fecha_nacimiento)}`}
                </Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.fecha_nacimiento && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Fecha de nacimiento</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${formatDate(actualTask.body_ticket.fecha_nacimiento)}`}
                </Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.fecha_otorgamiento && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Fecha de otorgamiento de póliza</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${formatDate(actualTask.body_ticket.asegurado.body_contacto.fecha_otorgamiento)}`}
                </Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.asegurado.body_contacto.fecha_termino && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Fecha de vencimiento de póliza</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${formatDate(actualTask.body_ticket.asegurado.body_contacto.fecha_termino)}`}
                </Typography>
              </Grid>
            </>
          )}
          {actualTask.body_ticket.prevision && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Previsión</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${actualTask.body_ticket.prevision}`}
                </Typography>
              </Grid>
            </>
          )}
          {parse_direccion(actualTask.body_ticket.asegurado.body_contacto.direccion) && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Dirección</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  {`${parse_direccion(actualTask.body_ticket.asegurado.body_contacto.direccion)}`}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const contacto = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="p">
            Contacto
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={0}>
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.colorGrey}>
              <PersonIcon />
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">{actualTask.body_ticket.beneficiario.nombre_contacto}</Typography>
          </Grid>
          {actualTask.body_ticket.beneficiario.body_contacto.datos_bancarios && (
            <>
              <Grid item xs={1}>
                <Typography variant="body1" className={classes.colorGrey}>
                  <FeaturedPlayListIcon />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">
                  {formatRut(
                    actualTask.body_ticket.beneficiario.body_contacto.datos_bancarios.rut_cuenta
                  )}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.colorGrey}>
              <MailOutlineIcon />
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">
              {actualTask.body_ticket.beneficiario.body_contacto.correo}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.colorGrey}>
              <PhoneAndroidIcon />
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body1">
              <NumberFormat
                value={actualTask.body_ticket.beneficiario.body_contacto.telefono}
                format="+56 9 #### ####"
                displayType={"text"}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const archivos = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="p">
            Archivos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          {files && files.length > 0 ? (
            files.map((file, i) => (
              <Grid key={`file-${i}`} item xs={6} sm={3}>
                <Card className={classes.cardContent} variant="outlined">
                  <CardActionArea
                    className={classes.cardActionArea}
                    onClick={e => handlePreviewOpen(file)}
                  >
                    {file.type.indexOf("audio") === 0 || file.type.indexOf("video") === 0 ? (
                      <PlayCircleFilledIcon style={{ fontSize: 50 }} />
                    ) : file.type.indexOf("application/pdf") === 0 ? (
                      <PictureAsPdfIcon style={{ fontSize: 50 }} />
                    ) : file.type.indexOf("application") === 0 ? (
                      <DescriptionIcon style={{ fontSize: 50 }} />
                    ) : file.type.indexOf("text") === 0 ? (
                      <DescriptionIcon style={{ fontSize: 50 }} />
                    ) : file.url ? (
                      <img
                        src={file.url}
                        alt={`file-${file.extension.substr(1)}-${i}`}
                        className={classes.img}
                      />
                    ) : (
                      <ImageLoader
                        imgKey={file.key}
                        bucket={file.bucket}
                        alt={`file-${file.extension.substr(1)}-${i}`}
                        className={classes.imageLoader}
                      />
                    )}
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              Sin archivos adjuntos
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const detalle_poliza = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="p">
            Poliza
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
              Sin poliza adjunta
            </Grid>
        </Grid>
      </Grid>
    );
  };

  const modal_poliza = () => {
    return (      
        <iframe          
          src={poliza}
          type="application/pdf"
          width="1000"
          height="800"
        >          
        </iframe>
    );
  };

  return (
    <div id="drawer-container" className={classes.positionRelative}>
      <FilePreview
        file={previewFile}
        url={previewFileUrl}
        open={previewOpen}
        onClose={handlePreviewClose}
      />

      <Dialog
        open={showPoliza}
        onClose={toggleOffPoliza}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogContent>
        {modal_poliza()}
        </DialogContent>
      </Dialog>

      {loading ? (
        <Loading />
      ) : (
        <List>
          <ListItem button alignItems="flex-start" onClick={toggleDrawer("bottom", true, "data")}>
            <ListItemAvatar className={classes.colorGrey}>
              <AssignmentOutlinedIcon fontSize="large" />
            </ListItemAvatar>

            <Grid container justify="center" alignItems="center">
              <Grid item xs={11}>
                <ListItemText
                  primary={actualTask.nombre_ticket}
                  secondary={
                    <>
                      {`Fecha de creación: ${formatRelative(
                        new Date(actualTask.body_ticket.fecha),
                        Date.now(),
                        { locale: es }
                      )}`}
                      <br />
                      {actualTask.alias_cuenta}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={1} className={classes.alignRight}>
                <Typography variant="body1" className={classes.colorGrey}>
                  <KeyboardArrowDownIcon fontSize="large" />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          <Divider variant="middle" />

          <ListItem
            button
            alignItems="flex-start"
            onClick={toggleDrawer("bottom", true, "asegurado")}
          >
            <ListItemAvatar className={classes.colorGrey}>
              <NightsStayIcon fontSize="large" />
            </ListItemAvatar>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11}>
                <ListItemText
                  primary={actualTask.body_ticket.asegurado.nombre_contacto}
                  secondary={formatRut(actualTask.body_ticket.asegurado.body_contacto.rut)}
                />
              </Grid>
              <Grid item xs={1} className={classes.alignRight}>
                <Typography variant="body1" className={classes.colorGrey}>
                  <KeyboardArrowDownIcon fontSize="large" />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          <Divider variant="middle" />

          <ListItem
            button
            alignItems="flex-start"
            onClick={toggleDrawer("bottom", true, "contacto")}
          >
            <ListItemAvatar className={classes.colorGrey}>
              <PersonIcon fontSize="large" />
            </ListItemAvatar>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11}>
                <ListItemText
                  primary={actualTask.body_ticket.beneficiario.nombre_contacto}
                  secondary={actualTask.body_ticket.beneficiario.body_contacto.correo}
                />
              </Grid>
              <Grid item xs={1} className={classes.alignRight}>
                <Typography variant="body1" className={classes.colorGrey}>
                  <KeyboardArrowDownIcon fontSize="large" />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          <Divider variant="middle" />

          <ListItem
            button
            alignItems="flex-start"
            onClick={toggleDrawer("bottom", true, "archivos")}
          >
            <ListItemAvatar className={classes.colorGrey}>
              <AttachFileIcon fontSize="large" />
            </ListItemAvatar>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11}>
                <ListItemText primary={`${files.length} archivos adjuntos`} />
              </Grid>
              <Grid item xs={1} className={classes.alignRight}>
                <Typography variant="body1" className={classes.colorGrey}>
                  <KeyboardArrowDownIcon fontSize="large" />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          <Divider variant="middle" />

          <ListItem
            button
            alignItems="flex-start"
            //onClick={toggleDrawer("bottom", true, "poliza")}
            onClick={actualTask.config_cuenta.poliza ? togglePoliza : toggleDrawer("bottom", true, "poliza")}
          >
            <ListItemAvatar className={classes.colorGrey}>
              <PolicyFileIcon fontSize="large" />
            </ListItemAvatar>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={11}>
                <ListItemText primary={`Poliza`} />
              </Grid>
              <Grid item xs={1} className={classes.alignRight}>
                <Typography variant="body1" className={classes.colorGrey}>
                  <KeyboardArrowDownIcon fontSize="large" />
                </Typography>
              </Grid>
            </Grid>
          </ListItem>

          <Drawer
            anchor={"bottom"}
            open={state["bottom"]}
            onClose={toggleDrawer("bottom", false)}
            PaperProps={{ style: { position: "absolute" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            transitionDuration={0}
            ModalProps={{
              container: document.getElementById("drawer-container"),
              style: { position: "absolute" }
            }}
          >
            {list("bottom")}
          </Drawer>
          <Divider variant="middle" />
        </List>
      )}
    </div>
  );
};

export default Detail;
