import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React, { useContext, useState } from "react";
import { AppContext, signOut } from "../components/App";
import { DRAWER_KEYS, drawer_main, drawer_secondary } from "../constants/drawer";
import SyncIcon from "@material-ui/icons/Sync";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

export const WIDTH = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: WIDTH,
    flexShrink: 0
  },
  drawerPaper: {
    width: WIDTH
  },
  drawerContainer: {
    overflow: "auto"
  },
  logoArea: {
    height: WIDTH,
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 2)
  },
  logo: {
    width: "100%"
  },
  listNested: {
    paddingLeft: theme.spacing(4)
  }
}));

const HubDrawer = ({
  mobile,
  open,
  onClose,
  onDrawerItemClick,
  onCreateTicket,
  onCreateContact
}) => {
  const classes = useStyles();
  const { user, view, onViewChange } = useContext(AppContext);

  const [openTickets, setOpenTickets] = useState(false);
  const [openContacts, setOpenContacts] = useState(false);

  const openTicketsMenu = () => {
    setOpenTickets(!openTickets);
  };

  const openContactsMenu = () => {
    setOpenContacts(!openContacts);
  };

  const handleDrawerClick = key => {
    onViewChange(key);
    onDrawerItemClick(key);
  };

  const ListItemTickets = ({ option }) => {
    return (
      <>
        <ListItem button onClick={openTicketsMenu}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          <ListItemText primary={option.label} primaryTypographyProps={{ variant: "body2" }} />
          {openTickets ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openTickets} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.listNested} onClick={onCreateTicket}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Nuevo Caso" primaryTypographyProps={{ variant: "body2" }} />
            </ListItem>

            <ListItem
              button
              className={classes.listNested}
              selected={view === option.key}
              onClick={() => handleDrawerClick(option.key)}
            >
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Casos Abiertos"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  };

  const ListItemContacts = ({ option }) => {
    return (
      <>
        <ListItem button onClick={openContactsMenu}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          <ListItemText primary={option.label} primaryTypographyProps={{ variant: "body2" }} />
          {openContacts ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openContacts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.listNested} onClick={onCreateContact}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                primary="Nuevo Contacto"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>

            <ListItem
              button
              className={classes.listNested}
              selected={view === option.key}
              onClick={() => handleDrawerClick(option.key)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText
                primary="Lista de Contactos"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>

            <ListItem
              button
              className={classes.listNested}
              selected={view === "upload"}
              onClick={() => handleDrawerClick("upload")}
            >
              <ListItemIcon>
                <SyncIcon />
              </ListItemIcon>
              <ListItemText
                primary="Carga de contactos"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>

            <ListItem
              button
              className={classes.listNested}
              selected={view === "removal"}
              onClick={() => handleDrawerClick("removal")}
            >
              <ListItemIcon>
                <SyncProblemIcon />
              </ListItemIcon>
              <ListItemText
                primary="Remover contactos"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  };

  return (
    <Drawer
      className={classes.drawer}
      variant={mobile ? "temporary" : "permanent"}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.logoArea}>
          <img
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`}
            alt="Serviclick Hub"
          />
        </div>

        <List component="nav">
          {drawer_main.map(option => {
            if (option.key === DRAWER_KEYS.tickets) {
              return <ListItemTickets key={option.key} option={option} />;
            } else if (option.key === DRAWER_KEYS.contacts) {
              return <ListItemContacts key={option.key} option={option} />;
            } else
              return (
                <ListItem
                  button
                  key={option.key}
                  selected={view === option.key}
                  onClick={() => handleDrawerClick(option.key)}
                >
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText
                    primary={option.label}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              );
          })}
        </List>

        <Divider />

        <List>
          {drawer_secondary.map(option => (
            <ListItem
              button
              key={option.key}
              onClick={option.key === DRAWER_KEYS.logout ? signOut : () => {}}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText
                primary={
                  option.key === DRAWER_KEYS.user && user
                    ? `${user.primer_nombre_usuario} ${user.apellido_paterno_usuario}`
                    : option.label
                }
                primaryTypographyProps={{ variant: "body2" }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default HubDrawer;
