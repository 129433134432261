import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";

const motives = [
  {
    id: 0,
    name: "Póliza vencida"
  },
  {
    id: 1,
    name: "Siniestro reportado fuera de plazo"
  },
  {
    id: 2,
    name: "Beneficiario no existe"
  },
  {
    id: 3,
    name: "Certificado de defunción inválido"
  },
  {
    id: 4,
    name: "Cliente solicita reembolso"
  },
  {
    id: 5,
    name: "Cliente ya contrató los servicios con otra funeraria "
  },
  {
    id: 6,
    name: "Cliente contactó solo por información"
  }
];

const EvaluarSiniestro = ({ data, setData, setShowDialog }) => {
  const [reembolso, setReembolso] = useState(data.reembolso ? data.reembolso : "si");
  const [motivo, setMotivo] = useState(data.motivo ? data.motivo : 0);

  const handleButtonClick = e => {
    setData({
      ...data,
      toLastTask: reembolso === "no" ? true : false,
      reembolso,
      motivo: reembolso === "no" ? motives[motivo] : null
    });
    setShowDialog(true);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Siniestro aprobado
          </Typography>
          <RadioGroup value={reembolso} onChange={({ target }) => setReembolso(target.value)}>
            <FormControlLabel className="mb-0" value={"si"} control={<Radio />} label="Si" />
            <FormControlLabel value={"no"} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {reembolso === "no" && (
          <Grid item xs={12}>
            <Typography variant="h6" component="p">
              Indicar motivo de rechazo
            </Typography>
            <Select
              variant="outlined"
              fullWidth
              value={motivo}
              onChange={({ target }) => setMotivo(target.value)}
            >
              {motives.map(item => (
                <MenuItem key={`item-${item.id}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={12}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="xs">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleButtonClick}
              >
                Aceptar
              </Button>
            </Container>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EvaluarSiniestro;
