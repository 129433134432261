import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRateRoundedIcon from "@material-ui/icons/StarRateRounded";
import React, { useReducer } from "react";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import { API } from "aws-amplify";

const initialState = {
  time: 1,
  attention: 1,
  quality: 1
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "time":
    case "attention":
    case "quality":
      return {
        ...state,
        [type]: payload
      };
    default:
      return { ...initialState };
  }
};

const Encuesta = ({ task, data, setData, setShowDialog }) => {
  const [datos, dispatch] = useReducer(reducer, initialState);
  const stars = [1, 2, 3, 4, 5];

  const rating = type => {
    return stars.map(star => {
      return (
        <IconButton
          key={`${type}-star-${star}`}
          color="primary"
          size="medium"
          onClick={() => {
            dispatch({ type: type, payload: star });
          }}
        >
          {datos[type] >= star ? <StarRateRoundedIcon /> : <StarBorderRoundedIcon />}
        </IconButton>
      );
    });
  };

  // const updateContact = async contacto => {
  //   try {
  //     const init = {
  //       body: {
  //         contacto
  //       }
  //     };

  //     const result = await API.put(process.env.REACT_APP_HUB_API, "/contactos", init);

  //     if (result.error) throw result.error;

  //     if (result.success) {
  //       return result.data;
  //     }
  //   } catch (err) {
  //     console.error("updateContact", err);
  //   }

  //   return false;
  // };

  // const getContact = async () => {
  //   try {
  //     const init = {
  //       queryStringParameters: {
  //         id: task.body_ticket.gestionar_asistencia.prestador.id_contacto
  //       }
  //     };

  //     const result = await API.get(process.env.REACT_APP_HUB_API, "/contactos", init);

  //     if (result.success) {
  //       return result.data;
  //     } else console.warn("fetchContacto", result.error);
  //   } catch (err) {
  //     console.error("fetchContacto", err);
  //   }
  // };

  // const updateRating = async () => {
  //   let calificaciones;
  //   const contacto = await getContact();

  //   if (contacto.body_contacto.hasOwnProperty("calificaciones")) {
  //     calificaciones = contacto.body_contacto.calificaciones;
  //   } else {
  //     const initialRating = {
  //       1: 0,
  //       2: 0,
  //       3: 0,
  //       4: 0,
  //       5: 0,
  //       total: 0
  //     };

  //     calificaciones = {
  //       atencion: initialRating,
  //       calidad: initialRating,
  //       tiempo: initialRating
  //     };
  //   }

  //   calificaciones = {
  //     atencion: {
  //       ...calificaciones.atencion,
  //       [datos.attention]: calificaciones.atencion[datos.attention] + 1
  //     },
  //     calidad: {
  //       ...calificaciones.calidad,
  //       [datos.quality]: calificaciones.calidad[datos.quality] + 1
  //     },
  //     tiempo: {
  //       ...calificaciones.tiempo,
  //       [datos.time]: calificaciones.tiempo[datos.time] + 1
  //     }
  //   };

  //   calificaciones.atencion.total = overall(calificaciones.atencion);
  //   calificaciones.calidad.total = overall(calificaciones.calidad);
  //   calificaciones.tiempo.total = overall(calificaciones.tiempo);

  //   updateContact({
  //     ...contacto,
  //     body_contacto: {
  //       ...contacto.body_contacto,
  //       calificaciones
  //     }
  //   });
  // };

  // const overall = calificaciones => {
  //   let total = 0;
  //   let votes = 0;

  //   stars.map(star => {
  //     let rate = calificaciones[star] * star;
  //     total = total + rate;
  //     votes = votes + calificaciones[star];

  //     return true;
  //   });

  //   total = total / votes;

  //   return total.toFixed(2);
  // };

  const handleButtonClick = e => {
    setData({
      ...data,
      tiempo: datos.time,
      atencion: datos.attention,
      calidad: datos.quality
    });

    setShowDialog(true);

    // updateRating();
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            {`Calificar el servicio`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p">
            Tiempo de respuesta
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating("time")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p">
            Atención teléfonica
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating("attention")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p">
            Calidad del servicio
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating("quality")}
        </Grid>

        <Grid item xs={12}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="xs">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleButtonClick}
              >
                Aceptar
              </Button>
            </Container>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Encuesta;
