import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { blue, green, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React from "react";
import { TICKETS_STATUS } from "../../constants/tickets_filter";
import { formatRut } from "../../functions";

const useStyles = makeStyles(theme => ({
  primaryInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  avatar: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2)
  },
  cardTicketContent: {
    display: "flex"
  },
  cardTicketContentInfo: {
    width: "100%"
  }
}));

const TicketItemView = ({ variant = "list", ticket, onItemClick }) => {
  const classes = useStyles();
  const {
    slug_cuenta,
    nombre_ticket,
    estado_ticket,
    alias_cuenta,
    body_ticket,
    folio_ticket
  } = ticket;
  const { asegurado } = body_ticket;

  const TicketAvatar = () => {
    let srcImg;

    switch (slug_cuenta) {
      case "banigualdad":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_banigualdad.png`;
        break;
      case "vias-chile":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_viaschile.png`;
        break;
      case "autofin":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_autofin.png`;
        break;
      case "seguros-ripley":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_ripley.png`;
        break;
      case "BCI Urgencia":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_bci.png`
        break;
      case "banco-estado-hogar":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_banco-estado.jpg`
        break;
      case "asistencia-apoyo-medico":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_apoyo-medico.jpg`
        break;
      case "ripley-sura":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_ripley-sura.jpeg`;
        break;
      case "southbridge-mascota":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_southbridge-mascota.jpeg`;
        break;
      default:
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`;
        break;
    }

    return <Avatar src={srcImg} classes={{ root: classes.logo }} alt="logo_cuenta" />;
  };

  const TicketPrimaryInfo = () => (
    <div className={classes.primaryInfo}>
      <Typography color="textPrimary" variant="body1">
        {nombre_ticket}
      </Typography>
      <TicketStatusIcon status={estado_ticket} />
    </div>
  );

  const TicketSecondaryInfo = () => (
    <>
      <Typography variant="caption" color="textSecondary" component="p">
        {alias_cuenta}
      </Typography>
      {asegurado && (
        <Typography variant="caption" color="textSecondary" component="p">{`Rut: ${formatRut(
          asegurado.body_contacto.rut
        )}`}</Typography>
      )}
      {body_ticket.tipo_siniestro && (
        <Typography variant="caption" color="textSecondary" component="p">{`Tipo Servicio: ${
          body_ticket.tipo_siniestro
        }`}</Typography>
      )}
      <Typography
        variant="caption"
        color="textSecondary"
        component="p"
      >{`Caso N°: ${folio_ticket}`}</Typography>
    </>
  );

  const TicketStatusIcon = ({ status }) => {
    switch (status) {
      case TICKETS_STATUS.done:
        return <CheckCircleIcon className={classes.avatar} style={{ color: green[500] }} />;
      case TICKETS_STATUS.rejected:
        return <BlockIcon className={classes.avatar} style={{ color: red[500] }} />;
      default:
        return <ScheduleIcon className={classes.avatar} style={{ color: blue[500] }} />;
    }
  };

  if (variant === "card") {
    return (
      <Grid item xs={6}>
        <ListItem button disableGutters dense onClick={() => onItemClick(ticket)}>
          <Card className={classes.paper}>
            <CardContent className={classes.cardTicketContent}>
              <TicketAvatar />
              <div className={classes.cardTicketContentInfo}>
                <TicketPrimaryInfo />
                <TicketSecondaryInfo />
              </div>
            </CardContent>
          </Card>
        </ListItem>
      </Grid>
    );
  } else {
    return (
      <ListItem button alignItems="flex-start" onClick={() => onItemClick(ticket)}>
        <ListItemAvatar>
          <TicketAvatar />
        </ListItemAvatar>
        <ListItemText
          primary={<TicketPrimaryInfo />}
          secondary={<TicketSecondaryInfo />}
          disableTypography
        />
      </ListItem>
    );
  }
};

export default TicketItemView;
