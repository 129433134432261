import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 1),
    marginBottom: "4px",
    padding: 0
  }
}));

const Conditions = ({ condiciones }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Modal = () => {
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">Condiciones de servicio</DialogTitle>
        <DialogContent>
          <DialogContentText>{condiciones}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Modal />
      <IconButton
        className={classes.button}
        aria-label="Condiciones"
        onClick={handleClickOpen}
        size="small"
      >
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
    </>
  );
};

export default Conditions;
