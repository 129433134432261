import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { API } from "aws-amplify";
import esLocale from "date-fns/locale/es";
import React, { useContext, useReducer, useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import {
  formatRut,
  nombreEsValido,
  rutEsValido,
  toTitleCase,
  unformatRut,
  validateEmail
} from "../../../functions";
import { AppContext } from "../../App";
import { DesktopContext } from "../../../views/Desktop";
import { MobileContext } from "../../../views/Mobile";
import AppBarCreate from "../../common/AppBarCreate";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2, 0)
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%"
    }
  },
  button: {
    margin: theme.spacing(2, 0)
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  },
  formControl: {
    width: "100%"
  }
}));

const initialState = {
  rut: "",
  nombre: "",
  fecha_nacimiento: new Date(),
  telefono: "",
  descripcion: "",
  tipo: "Sala de Urgencia: Accidental",
  prevision: "Fonasa",  
  ubicacion: "",
  fecha: new Date(),
  error: {
    rut: false,
    nombre: false,
    fecha_nacimiento: false,
    telefono: false,
    descripcion: false,
    tipo: false,
    ubicacion: false,
    certificado: false,
    fecha: false
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "rut":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, rut: !rutEsValido(payload) }
      };
    case "nombre":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, nombre: !nombreEsValido(payload) }
      };
    case "fecha_nacimiento":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, fecha: !payload || payload === "" }
      };
    case "prevision":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, prevision: !payload || payload === "" }
      };
    case "telefono":
      return {
        ...state,
        [type]: payload,
        error: {
          ...state.error,
          telefono: !payload || payload === "" || payload.length < 8
        }
      };
    case "descripcion":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, descripcion: !payload || payload === "" }
      };
    case "tipo":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, tipo: !payload || payload === "" }
      };
    case "ubicacion":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, ubicacion: !payload || payload === "" }
      };
    case "fecha":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, fecha: !payload || payload === "" }
      };
    case "error":
      return {
        ...state,
        error: { ...state.error, [payload]: true }
      };
    default:
      return { ...initialState };
  }
};

const types = [
  {
    id: 0,
    label: "Sala de Urgencia: Accidental"
  },
  {
    id: 1,
    label: "Sala de Urgencia: Enfermedad"
  },
  {
    id: 2,
    label: "Traslado Medico Terrestre"
  },
  {
    id: 3,
    label: "Telemedicina"
  },
  {
    id: 4,
    label: "Orientacion Medica Telefonica"
  },
  {
    id: 5,
    label: "Medico a Domicilio"
  }
];

const previsiones = [
  {
    id: 0,
    label: "Fonasa"
  },
  {
    id: 1,
    label: "Isapre"
  }
]



const TicketCreate = ({ mobile, account, onBack, onClose, rut }) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const { onTicketCreated } = useContext(DesktopContext);
  const { onTicketCreatedMobile } = useContext(MobileContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [fetchingData, setFetchingData] = useState(false);
  const [datos, dispatch] = useReducer(reducer, initialState);

  //context
  const { getContactFromTicket } = useContext(DesktopContext);   
  const { getContactFromTicketMobile } = useContext(MobileContext);

  useEffect(
    () => {
      if (mobile){
        if (getContactFromTicketMobile && getContactFromTicketMobile.body_contacto){      
          dispatch({ type: "rut", payload: getContactFromTicketMobile.body_contacto.rut });      
          dispatch({ type: "nombre", payload: getContactFromTicketMobile.nombre_contacto });
          dispatch({ type: "telefono", payload: getContactFromTicketMobile.body_contacto.telefono_contacto });    
        };  
      }else{
        if (getContactFromTicket && getContactFromTicket.body_contacto){      
          dispatch({ type: "rut", payload: getContactFromTicket.body_contacto.rut });      
          dispatch({ type: "nombre", payload: getContactFromTicket.nombre_contacto });
          dispatch({ type: "telefono", payload: getContactFromTicket.body_contacto.telefono_contacto });    
        };
      }      
    }, []);

  const validate = () => {
    if (!rutEsValido(datos.rut)) dispatch({ type: "error", payload: "rut" });

    if (!nombreEsValido(datos.nombre)) dispatch({ type: "error", payload: "nombre" });

    if (!datos.fecha_nacimiento || datos.fecha_nacimiento === "") dispatch({ type: "error", payload: "fecha_nacimiento" });

    if (!datos.telefono || datos.telefono === "" || datos.telefono.length < 8)
      dispatch({ type: "error", payload: "telefono" });

    if (!datos.prevision || datos.prevision === "") dispatch({ type: "error", payload: "prevision" });

    if (!datos.descripcion || datos.descripcion === "")
      dispatch({ type: "error", payload: "descripcion" });    

    if (!datos.tipo || datos.tipo === "") dispatch({ type: "error", payload: "tipo" });

    if (!datos.fecha || datos.fecha === "") dispatch({ type: "error", payload: "fecha" });

    if (!datos.ubicacion || datos.ubicacion === "")
      dispatch({ type: "error", payload: "ubicacion" });

    return (
      rutEsValido(datos.rut) &&
      nombreEsValido(datos.nombre) &&
      datos.fecha_nacimiento &&
      datos.fecha_nacimiento !== "" &&
      datos.telefono &&
      datos.telefono.length >= 8 &&
      datos.descripcion &&
      datos.descripcion !== "" &&
      datos.prevision &&
      datos.prevision !== "" &&
      datos.tipo &&
      datos.tipo !== "" &&
      datos.fecha &&
      datos.fecha !== "" &&
      datos.ubicacion &&
      datos.ubicacion !== ""
    );
  };

  const create = async () => {
    setFetchingData(true);

    if (validate()) {
      try {
        const init = {
          body: {
            id_usuario_crea: user.id_usuario,
            rut: datos.rut,
            nombre: datos.nombre,
            fecha_nacimiento: datos.fecha_nacimiento,
            telefono: datos.telefono,
            descripcion: datos.descripcion,
            prevision: datos.prevision,
            tipo_siniestro: datos.tipo,
            fecha: datos.fecha,
            ubicacion: datos.ubicacion,
            cuenta: account.id_cuenta
          }
        };

        const result = await API.post(process.env.REACT_APP_TICKETS_API, "/tickets/bci-urgencia", init);

        if (result.success) {
          if (mobile) {
            onTicketCreatedMobile();
          } else {
            onTicketCreated();
          }
          onClose();
        }
      } catch (err) {
        console.error("ticketCreate.create", err);
      }
    }

    setFetchingData(false);
  };

  const searchContact = async () => {
    setFetchingData(true);

    if (datos.rut && rutEsValido(datos.rut)) {
      try {
        const init = {
          queryStringParameters: {
            rut: datos.rut,
            tipo: "asegurado",
            cuenta: account.id_cuenta
          }
        };

        const result = await API.get(process.env.REACT_APP_HUB_API, "/contactos", init);

        if (result.success) {
          if (result.data.length === 0) {
            setMessage("Asegurado no encontrado");
            dispatch({
              type: "nombre",
              payload: ""
            });
          }else if (result.data.estado_contacto === 0){
            setMessage("Asegurado inactivo");
            dispatch({
              type: "rut",
              payload: ""
            })
          }
           else {
            setMessage("Asegurado encontrado");
            if (result.data.nombre_contacto) {
              dispatch({
                type: "nombre",
                payload: result.data.nombre_contacto
              });
            }
            if (result.data.body_contacto.telefono) {
              dispatch({
                type: "telefono",
                payload: result.data.body_contacto.telefono
              });
            }
          }
          setOpen(true);
        }
      } catch (err) {
        console.error("fetchContactos", err);
      }
    }

    setFetchingData(false);
  };


  const alert = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderForm = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        {alert()}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="p">
              Datos del asegurado
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="rut"
              size="small"
              variant="outlined"
              fullWidth
              label="Rut *"
              placeholder="Ej: 12.345.678-9"
              InputLabelProps={{
                shrink: true
              }}
              value={datos.rut && formatRut(datos.rut)}
              disabled={fetchingData}
              onChange={({ target }) => {
                dispatch({ type: "rut", payload: unformatRut(target.value) });
              }}
              onBlur={() => {
                searchContact();
                dispatch({ type: "rut", payload: datos.rut });
              }}
              error={datos.error.rut}
              helperText={datos.error.rut && "Debe ingresar un Rut valido"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              disabled={fetchingData}
              label="Nombre"
              InputLabelProps={{
                shrink: true
              }}
              value={datos.nombre && toTitleCase(datos.nombre)}
              onChange={({ target }) =>
                dispatch({
                  type: "nombre",
                  payload: target.value
                })
              }
              onBlur={() => dispatch({ type: "nombre", payload: datos.nombre })}
              error={datos.error.nombre}
              helperText={datos.error.nombre && "Debe ingresar un nombre"}
            />
          </Grid>

          <Grid item xs={12}>
            <KeyboardDatePicker
              fullWidth
              disabled={fetchingData}
              disableToolbar
              disableFuture
              size="small"
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              id="date-picker-inline"
              label="Fecha de Nacimiento"
              value={datos.fecha_nacimiento}
              onChange={date =>
                dispatch({
                  type: "fecha_nacimiento",
                  payload: date
                })
              }
              onBlur={() => dispatch({ type: "fecha_nacimiento", payload: datos.fecha_nacimiento })}
              error={datos.error.fecha_nacimiento}
              helperText={datos.error.fecha_nacimiento && "Debe ingresar una fecha"}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <NumberFormat
              id="phone"
              customInput={TextField}
              format="+56 9 #### ####"
              allowEmptyFormatting
              size="small"
              variant="outlined"
              fullWidth
              type="tel"
              label="Teléfono celular *"
              placeholder="Ej: +56 9 1234 5678"
              InputLabelProps={{
                shrink: true
              }}
              value={datos.telefono}
              disabled={fetchingData}
              onValueChange={({ value }) => dispatch({ type: "telefono", payload: value })}
              onBlur={() => dispatch({ type: "telefono", payload: datos.telefono })}
              error={datos.error.telefono}
              helperText={datos.error.telefono && "Debe ingresar un celular valido"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="prevision">Previsión</InputLabel>
              <Select
                labelId="prevision"
                fullWidth
                classes={{ selectMenu: classes.select }}
                label="Prevision"
                variant="outlined"
                value={datos.prevision}
                onChange={({ target }) =>
                  dispatch({
                    type: "prevision",
                    payload: target.value
                  })
                }
                error={datos.error.prevision}
                helperText={datos.error.prevision && "Debe seleccionar una previsión"}
              >
                {previsiones.map(item => (
                  <MenuItem key={`tipo-${item.id}`} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              size="small"
              variant="outlined"
              disabled={fetchingData}
              label="Descripción del siniestro"
              InputLabelProps={{
                shrink: true
              }}
              value={datos.descripcion}
              onChange={({ target }) =>
                dispatch({
                  type: "descripcion",
                  payload: target.value
                })
              }
              onBlur={() => dispatch({ type: "descripcion", payload: datos.descripcion })}
              error={datos.error.descripcion}
              helperText={datos.error.descripcion && "Debe ingresar un nombre"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="tipo-siniestro">Tipo de siniestro</InputLabel>
              <Select
                labelId="tipo-siniestro"
                fullWidth
                classes={{ selectMenu: classes.select }}
                label="Tipo de siniestro"
                variant="outlined"
                value={datos.tipo}
                onChange={({ target }) =>
                  dispatch({
                    type: "tipo",
                    payload: target.value
                  })
                }
                error={datos.error.tipo}
                helperText={datos.error.tipo && "Debe seleccionar un tipo de siniestro"}
              >
                {types.map(item => (
                  <MenuItem key={`tipo-${item.id}`} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              fullWidth
              disabled={fetchingData}
              disableToolbar
              disableFuture
              size="small"
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              id="date-picker-inline"
              label="Fecha del siniestro"
              value={datos.fecha}
              onChange={date =>
                dispatch({
                  type: "fecha",
                  payload: date
                })
              }
              onBlur={() => dispatch({ type: "fecha", payload: datos.fecha })}
              error={datos.error.fecha}
              helperText={datos.error.fecha && "Debe ingresar una fecha"}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              disabled={fetchingData}
              label="Ubicación actual"
              InputLabelProps={{
                shrink: true
              }}
              value={datos.ubicacion}
              onChange={({ target }) =>
                dispatch({
                  type: "ubicacion",
                  payload: target.value
                })
              }
              onBlur={() => dispatch({ type: "ubicacion", payload: datos.ubicacion })}
              error={datos.error.ubicacion}
              helperText={datos.error.ubicacion && "Debe ingresar una ubicacion"}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  return (
    <>
      <AppBarCreate title="Crear Caso" onBack={onBack} onClose={onClose} />
      <Container className={classes.container} maxWidth="sm">
        {renderForm()}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              fullWidth
              disabled={fetchingData}
              onClick={create}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TicketCreate;
