export const TICKETS_STATUS = {
  open: "open",
  rejected: "rejected",
  completed: "completed",
  viaschile: "vias-chile",
  banigualdad: "banigualdad",
  autofin: "autofin",
  segurosripley: "seguros-ripley"
};

export const tickets_filter = [
  { key: TICKETS_STATUS.open, checkbox: false, label: "En curso" },
  { key: TICKETS_STATUS.rejected, checkbox: false, label: "Rechazados" },
  { key: TICKETS_STATUS.completed, checkbox: false, label: "Completados" },
  { key: TICKETS_STATUS.viaschile, checkbox: true, label: "Vias Chile" },
  { key: TICKETS_STATUS.banigualdad, checkbox: true, label: "Banigualdad" },
  { key: TICKETS_STATUS.autofin, checkbox: true, label: "Autofin" },
  { key: TICKETS_STATUS.segurosripley, checkbox: true, label: "Seguros Ripley" }
];
