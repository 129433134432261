export const CONTACTS_TYPES = {
  all: "all",
  asegurado: "asegurado",
  beneficiario: "beneficiario",
  prestador: "prestador",
  viaschile: "vias-chile",
  banigualdad: "banigualdad",
  autofin: "autofin"
};

export const contacts_filter = [
  { key: CONTACTS_TYPES.all, checkbox: false, label: "Todos" },
  { key: CONTACTS_TYPES.asegurado, checkbox: false, label: "Asegurados" },
  { key: CONTACTS_TYPES.beneficiario, checkbox: false, label: "Beneficiarios" },
  { key: CONTACTS_TYPES.prestador, checkbox: false, label: "Prestadores" },
  { key: CONTACTS_TYPES.viaschile, checkbox: true, label: "Vias Chile" },
  { key: CONTACTS_TYPES.banigualdad, checkbox: true, label: "Banigualdad" },
  { key: CONTACTS_TYPES.autofin, checkbox: true, label: "Autofin" }
];
