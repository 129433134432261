import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { format, parseJSON } from "date-fns";
import { es } from "date-fns/locale";
import { API } from "aws-amplify";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { formatNumber } from "../../../functions";
import Loading from "../../common/Loading";

const motives = [
  {
    id: 0,
    name: "Póliza vencida"
  },
  {
    id: 1,
    name: "Siniestro reportado fuera de plazo"
  },
  {
    id: 2,
    name: "Asegurado supera límite mensual"
  },
  {
    id: 3,
    name: "Cliente contactó solo por información"
  },
  {
    id: 4,
    name: "No envía documentación"
  }
];

const EvaluarSiniestro = ({ task, data, setData, setShowDialog, notificarContacto }) => {
  const [reembolso, setReembolso] = useState(data.reembolso ? data.reembolso : "si");
  const [motivo, setMotivo] = useState(data.motivo ? data.motivo : 0);
  const [monto, setMonto] = useState("");
  const [montoError, setMontoError] = useState("");
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);

  const fetchTickets = async (contacto, cuenta) => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          contacto,
          cuenta
        }
      };

      const results = await API.get(
        process.env.REACT_APP_HUB_API,
        "/contactos/tickets/latest",
        init
      );

      if (results.success && results.data) {
        setTickets(results.data);
      }
    } catch (err) {
      console.log("EvaluarSiniestro.fetchTickets.error", err);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (task && task.body_ticket && task.body_ticket.asegurado) {
      fetchTickets(task.body_ticket.asegurado.id_contacto, task.id_cuenta);
    }
  }, [task]);

  const getEstado = estado => {
    let value = "Desconocido";

    if (estado < task.pasos_pipeline.length) {
      value = task.pasos_pipeline[estado];
    }

    return value;
  };

  const validateMonto = value => {
    value = value || monto;

    if (reembolso === "no") return true;

    // if (value > (task.body_ticket.monto * task.config_cuenta.porcentajeTopeCobertura) / 100) {
    //   setMontoError(
    //     `El monto aprobado no puede superar el tope de cobertura del ${task.config_cuenta.porcentajeTopeCobertura}% del monto solicitado`
    //   );
    //   return false;
    // } else if (value > task.config_cuenta.montoTopeMensual) {
    //   setMontoError(
    //     `El monto aprobado no puede superar el tope mensual de ${formatNumber(
    //       task.config_cuenta.montoTopeMensual,
    //       "currency"
    //     )}`
    //   );
    //   return false;
    // } else {
    //   setMontoError("");
    //   return true;
    // }
    setMontoError("");
    return true;
  };

  const handleButtonClick = e => {
    if (validateMonto(monto)) {
      setData({
        ...data,
        saveToTicketBody: true,
        toLastTask: reembolso === "no" ? true : false,
        reembolso,
        motivo: reembolso === "no" ? motives[motivo] : null,
        monto: reembolso === "si" ? monto : null
      });
      setShowDialog(true);

      let message = null;

      if (reembolso === "si") {
        message = `Su solicitud ha sido aprobada por un monto de ${formatNumber(
          monto,
          "currency"
        )}. Pronto gestionaremos el pago de su reembolso.`;
      }

      if (reembolso === "no") {
        message = `Su solicitud no ha sido aprobada por: ${motives[motivo].name}`;
      }

      notificarContacto({
        email: task.body_ticket.asegurado.body_contacto.correo,
        subject: `Notificación de solicitud #${task.folio_ticket}`,
        message
      });
    }
  };

  const handleMontoChange = value => {
    setMonto(value);
    validateMonto(value);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NumberFormat
            value={task.body_ticket.monto}
            thousandSeparator="."
            decimalSeparator=","
            prefix={"Monto solicitado $ "}
            displayType={"text"}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Siniestro aprobado
          </Typography>
          <RadioGroup value={reembolso} onChange={({ target }) => setReembolso(target.value)}>
            <FormControlLabel className="mb-0" value={"si"} control={<Radio />} label="Si" />
            <FormControlLabel value={"no"} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>

        {reembolso === "si" && (
          <Grid item xs={12}>
            <Typography variant="h6" component="p">
              Indicar monto aprobado
            </Typography>
            <NumberFormat
              fullWidth
              id="monto"
              allowNegative={false}
              customInput={TextField}
              size="small"
              variant="outlined"
              prefix={"$ "}
              thousandSeparator="."
              decimalSeparator=","
              placeholder="Ej: $12.000"
              InputLabelProps={{
                shrink: true
              }}
              value={monto}
              onValueChange={({ value }) => handleMontoChange(value)}
              error={Boolean(montoError)}
              helperText={montoError}
            />
          </Grid>
        )}

        {reembolso === "no" && (
          <Grid item xs={12}>
            <Typography variant="h6" component="p">
              Indicar motivo de rechazo
            </Typography>
            <Select
              variant="outlined"
              fullWidth
              value={motivo}
              onChange={({ target }) => setMotivo(target.value)}
            >
              {motives.map(item => (
                <MenuItem key={`item-${item.id}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        <Grid item xs={12}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="xs">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleButtonClick}
              >
                Aceptar
              </Button>
            </Container>
          </Hidden>
        </Grid>

        <Grid item xs={12}>
          {loading ? (
            <Loading />
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={2}>
                Folio
              </Grid>
              <Grid item xs={3}>
                Fecha
              </Grid>
              <Grid item xs={3}>
                Estado
              </Grid>
              <Grid item xs={2}>
                Monto solicitado
              </Grid>
              <Grid item xs={2}>
                Monto liquidado
              </Grid>

              {tickets.length > 0 ? (
                tickets.map((ticket, index) => (
                  <React.Fragment key={`ticket-${index}`}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={2}>
                      {ticket.folio_ticket}
                    </Grid>
                    <Grid item xs={3}>
                      {format(parseJSON(ticket.fecha_creacion_ticket), "dd MMM, p", {
                        locale: es
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      {getEstado(ticket.estado_ticket)}
                    </Grid>
                    <Grid item xs={2}>
                      <NumberFormat
                        value={ticket.body_ticket.monto}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="$"
                        displayType={"text"}
                      />
                    </Grid>
                    {ticket.body_ticket.liquidar_siniestro && (
                      <Grid item xs={2}>
                        <NumberFormat
                          value={ticket.body_ticket.liquidar_siniestro.monto}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="$"
                          displayType={"text"}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Typography color="textSecondary" component="div" align="center">
                  No se encontraron solicitudes en el último mes
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default EvaluarSiniestro;
