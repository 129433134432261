import React, { useMemo } from "react";
import { Storage } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";
import ImageLoader from "./ImageLoader";

const useStyles = makeStyles(theme => ({
  downloadButton: {
    fontSize: "20px"
  },
  imageLoaderPreview: {
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  }
}));

const FilePreview = ({ file, url, open, onClose }) => {
  const classes = useStyles();

  const imgComponent = useMemo(() => {
    let imgComponent = null;

    if (file && file.key) {
      if (file.type.indexOf("audio") === 0) {
        imgComponent = <audio controls src={url} />;
      } else if (file.type.indexOf("video") === 0) {
        imgComponent = (
          <video controls width="100%" maxWidth="500px">
            <source src={url} type={file.type} />
          </video>
        );
      } else if (file.type.indexOf("application") === 0 || file.type.indexOf("text") === 0) {
        imgComponent = (
          <Tooltip title="Descargar archivo" aria-label="descargar">
            <Button
              startIcon={<GetAppIcon style={{ fontSize: 40 }} />}
              onClick={() => downloadFile(file)}
              classes={{ label: classes.downloadButton }}
            >
              Descargar archivo
            </Button>
          </Tooltip>
        );
      } else {
        imgComponent = (
          <ImageLoader
            className={classes.imageLoaderPreview}
            imgKey={file.key}
            bucket={file.bucket}
            alt="file-preview"
          />
        );
      }
    }

    return imgComponent;
  }, [file, url, classes]);

  const downloadFile = ({ key, name, type }) => {
    Storage.get(key, { download: true })
      .then(result => {
        try {
          let blob = new Blob([result.Body], { type });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, name);
          } else {
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = objectUrl;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } catch (error) {
          console.log("downloadFile", error);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle disableTypography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{imgComponent}</DialogContent>
    </Dialog>
  );
};

export default FilePreview;
