import React, { useContext, useEffect, useReducer, useState } from "react";
import NumberFormat from "react-number-format";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import NoteIcon from "@material-ui/icons/Note";
import Alert from "@material-ui/lab/Alert";
import { formatRut, formatNumber, rutEsValido, unformatRut } from "../../../functions";
import { AppContext } from "../../App";
import FileUploader from "../../common/FileUploader";
import SelectBank, { getBank } from "../../common/SelectBank";
import SelectBankAccount, { getAccountType } from "../../common/SelectBankAccount";

const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: "right"
  },
  alignCenter: {
    textAlign: "center"
  }
}));

const initialState = {
  monto: "",
  rut_cuenta: "",
  banco: "",
  tipo_cuenta: "",
  numero_cuenta: "",
  archivos: [],
  bitacora: [],
  commentary: "",
  monto_mensaje: "",
  valeVista: false,
  error: {
    monto: false,
    rut_cuenta: false,
    banco: false,
    tipo_cuenta: false,
    numero_cuenta: false,
    archivos: false,
    commentary: false
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "monto":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, monto: !payload || payload === "" }
      };
    case "monto_mensaje":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, monto: !payload || payload === "" }
      };
    case "rut_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, rut_cuenta: !rutEsValido(payload) }
      };
    case "banco":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, banco: !payload || payload === "" }
      };
    case "tipo_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, tipo_cuenta: !payload || payload === "" }
      };
    case "numero_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, numero_cuenta: !payload || payload === "" || payload.length < 4 }
      };
    case "file_add":
      return {
        ...state,
        archivos: payload
      };
    case "file_remove":
      return {
        ...state,
        archivos: payload
      };
    case "bitacora":
      return {
        ...state,
        [type]: payload
      };
    case "commentary":
      return {
        ...state,
        [type]: payload
      };
    case "error":
      return {
        ...state,
        error: { ...state.error, [payload]: true }
      };
    case "noError":
      return {
        ...state,
        error: { ...state.error, [payload]: false }
      };
    case "load":
      return {
        ...initialState,
        ...payload
      };
    default:
      return { ...initialState };
  }
};

const LiquidarSiniestro = ({ task, data, setData, setShowDialog }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [datos, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (task) {
      if (task.body_ticket.opcionPago.metodo === "transferencia-bancaria") {
        dispatch({
          type: "load",
          payload: {
            monto: (task.body_ticket.evaluar_siniestro || {}).monto || "",
            rut_cuenta: task.body_ticket.asegurado.body_contacto.rut,
            banco: getBank(task.body_ticket.opcionPago.banco.value),
            tipo_cuenta: getAccountType(task.body_ticket.opcionPago.tipoCuenta.value),
            numero_cuenta: task.body_ticket.opcionPago.numeroCuenta,
            bitacora: task.body_tarea.bitacora ? task.body_tarea.bitacora : []
          }
        });
      } else {
        dispatch({
          type: "load",
          payload: {
            monto: (task.body_ticket.evaluar_siniestro || {}).monto || "",
            rut_cuenta: task.body_ticket.asegurado.body_contacto.rut,
            valeVista: true,
            bitacora: task.body_tarea.bitacora ? task.body_tarea.bitacora : []
          }
        });
      }
    }
  }, [task]);

  const handleAdd = newFile => {
    if (!datos.archivos) {
      dispatch({ type: "file_add", payload: [newFile] });
    } else {
      dispatch({ type: "file_add", payload: [...datos.archivos, newFile] });
    }
  };

  const handleDelete = removeFile => {
    dispatch({ type: "file_remove", payload: datos.archivos.filter(file => file !== removeFile) });
  };

  const handleButtonClick = e => {
    const updatedData = {
      ...data,
      saveToTicketBody: true,
      monto: datos.monto,
      archivos: datos.archivos,
      bitacora: datos.bitacora
    };

    task &&
      task.body_ticket.gestionar_asistencia.red === true &&
      (updatedData.datos_bancarios = {
        rut_cuenta: datos.rut_cuenta,
        numero_cuenta: datos.numero_cuenta,
        tipo_cuenta: datos.tipo_cuenta,
        banco: datos.banco,
        valeVista: datos.valeVista
      });

    setData(updatedData);

    validate() && setShowDialog(true);
  };

  const showRefund = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Datos bancarios
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="rut_cuenta"
            key="rut_cuenta"
            size="small"
            variant="outlined"
            label="Rut cuenta *"
            placeholder="Ej: 12.345.678-9"
            InputLabelProps={{
              shrink: true
            }}
            value={formatRut(datos.rut_cuenta)}
            onChange={({ target }) => {
              dispatch({ type: "rut_cuenta", payload: unformatRut(target.value) });
            }}
            onBlur={() => dispatch({ type: "rut_cuenta", payload: datos.rut_cuenta })}
            error={datos.error.rut_cuenta}
            helperText={datos.error.rut_cuenta && "Debe ingresar un Rut valido"}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectBank
            fullWidth
            className="control"
            size="small"
            variant="outlined"
            value={datos.banco}
            onChange={value => dispatch({ type: "banco", payload: value })}
            onBlur={() => dispatch({ type: "banco", payload: datos.banco })}
            error={datos.error.banco}
            helperText={datos.error.banco && "Debe seleccionar un banco"}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectBankAccount
            fullWidth
            className="control"
            size="small"
            variant="outlined"
            value={datos.tipo_cuenta}
            onChange={value => dispatch({ type: "tipo_cuenta", payload: value })}
            onBlur={() => dispatch({ type: "tipo_cuenta", payload: datos.tipo_cuenta })}
            error={datos.error.tipo_cuenta}
            helperText={datos.error.tipo_cuenta && "Debe seleccionar un tipo de cuenta"}
          />
        </Grid>

        <Grid item xs={12}>
          <NumberFormat
            fullWidth
            id="bank_account_number"
            customInput={TextField}
            size="small"
            variant="outlined"
            type="tel"
            label="Numero de cuenta *"
            placeholder="Ej: 123456789"
            InputLabelProps={{
              shrink: true
            }}
            value={datos.numero_cuenta}
            onValueChange={({ value }) => dispatch({ type: "numero_cuenta", payload: value })}
            onBlur={() => dispatch({ type: "numero_cuenta", payload: datos.numero_cuenta })}
            error={datos.error.numero_cuenta}
            helperText={datos.error.numero_cuenta && "Debe ingresar un numero de cuenta"}
          />
        </Grid>
      </Grid>
    );
  };

  const showBitacora = () => {
    if (datos.bitacora && datos.bitacora.length > 0) {
      const list = (
        <Grid item xs={12}>
          <List>
            {datos.bitacora.map(item => {
              return (
                <ListItem key={`commentary-${item.id}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <NoteIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.commentary} secondary={item.date} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      );

      return list;
    }

    return null;
  };

  const updateTask = async updateBitacora => {
    setLoading(true);

    try {
      const init = {
        body: {
          id: task.id_tarea,
          body: JSON.stringify({
            ...task.body_tarea,
            ...data,
            bitacora: updateBitacora
          }),
          estado: 1,
          actividad: null,
          ticket: task.id_ticket,
          responsable: user.id_responsable
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/tickets/tarea", init);

      setLoading(false);

      if (result.success) {
        return { actividad: result.data.actividad, tarea: result.data.tarea };
      } else {
        console.warn("updateTask", result.error);
      }
    } catch (error) {
      console.error("updateTask", error);
    }

    return false;
  };

  const validateCommentary = () => {
    if (datos.commentary.length >= 1000 || datos.commentary.length === 0) {
      dispatch({ type: "error", payload: "commentary" });

      return false;
    }

    return true;
  };

  const handleAddCommentary = async () => {
    if (validateCommentary()) {
      const date = new Date().toLocaleString("es-CL");
      const updateBitacora = [
        ...datos.bitacora,
        {
          id: datos.bitacora.length + 1,
          date,
          commentary: datos.commentary
        }
      ];

      dispatch({ type: "bitacora", payload: updateBitacora });

      const isTaskUpdated = await updateTask(updateBitacora);

      if (isTaskUpdated) {
        dispatch({ type: "commentary", payload: "" });
        return true;
      }
    }

    return false;
  };

  const validate = () => {
    if (!datos.monto || datos.monto === "") {
      dispatch({ type: "monto_mensaje", payload: "Debe ingresar un monto" });
      dispatch({ type: "error", payload: "monto" });
    }

    const montoMaximo = (task.body_ticket.monto * task.config_cuenta.porcentajeTopeCobertura) / 100;

    // if (datos.monto > montoMaximo) {
    //   dispatch({
    //     type: "monto_mensaje",
    //     payload: `El monto aprobado no puede superar el tope de cobertura del ${task.config_cuenta.porcentajeTopeCobertura}% del monto solicitado`
    //   });
    //   dispatch({ type: "error", payload: "monto" });
    // } else if (datos.monto > task.config_cuenta.montoTopeMensual) {
    //   dispatch({
    //     type: "monto_mensaje",
    //     payload: `El monto aprobado no puede superar el tope mensual de ${formatNumber(
    //       task.config_cuenta.montoTopeMensual,
    //       "currency"
    //     )}`
    //   });
    //   dispatch({ type: "error", payload: "monto" });
    // }

    !datos.archivos || datos.archivos.length === 0
      ? dispatch({ type: "error", payload: "archivos" })
      : dispatch({ type: "noError", payload: "archivos" });

    if (task && task.body_ticket.gestionar_asistencia.red === true && !datos.valeVista) {
      if (!rutEsValido(datos.rut_cuenta)) dispatch({ type: "error", payload: "rut_cuenta" });

      if (!datos.banco) dispatch({ type: "error", payload: "banco" });

      if (!datos.tipo_cuenta) dispatch({ type: "error", payload: "tipo_cuenta" });

      if (!datos.numero_cuenta || datos.numero_cuenta === "") {
        dispatch({ type: "error", payload: "numero_cuenta" });
      }

      if (task.config_cuenta.montoTopeMensual && task.config_cuenta.porcentajeTopeCobertura) {
        return (
          datos.monto &&
          datos.monto <= task.config_cuenta.montoTopeMensual &&
          datos.monto <= montoMaximo &&
          rutEsValido(datos.rut_cuenta) &&
          datos.banco !== null &&
          datos.tipo_cuenta !== null &&
          datos.numero_cuenta &&
          datos.numero_cuenta.length > 2 &&
          datos.archivos.length > 0
        );
      } else {
        return (
          datos.monto &&
          rutEsValido(datos.rut_cuenta) &&
          datos.banco !== null &&
          datos.tipo_cuenta !== null &&
          datos.numero_cuenta &&
          datos.numero_cuenta.length > 2 &&
          datos.archivos.length > 0
        );
      }
    } else {
      if (task.config_cuenta.montoTopeMensual && task.config_cuenta.porcentajeTopeCobertura) {
        return (
          datos.monto &&
          datos.monto <= task.config_cuenta.montoTopeMensual &&
          datos.monto <= montoMaximo &&
          datos.archivos.length > 0 &&
          datos.archivos.length > 0
        );
      } else {
        return datos.monto && datos.archivos.length > 0 && datos.archivos.length > 0;
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        {task && task.body_ticket.gestionar_asistencia.red === true && (
          <Grid item xs={12} className={classes.alignCenter}>
            <Alert severity="info">Reembolso al beneficiario</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Monto a pagar
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NumberFormat
            fullWidth
            id="monto"
            allowNegative={false}
            customInput={TextField}
            size="small"
            variant="outlined"
            prefix={"$ "}
            thousandSeparator="."
            decimalSeparator=","
            placeholder="Ej: 123456789"
            InputLabelProps={{
              shrink: true
            }}
            value={datos.monto}
            onValueChange={({ value }) => dispatch({ type: "monto", payload: value })}
            onBlur={() => dispatch({ type: "monto", payload: datos.monto })}
            error={datos.error.monto}
            helperText={datos.error.monto && datos.monto_mensaje}
          />
        </Grid>

        {datos.valeVista ? (
          <Grid item xs={12}>
            <Alert severity="warning">El asegurado solicita la generación de vale vista</Alert>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {task && task.body_ticket.gestionar_asistencia.red === true && showRefund()}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Comprobantes
          </Typography>
          <FileUploader
            parent="liquidar-siniestro"
            data={datos.archivos}
            maxFiles={8}
            onAdd={handleAdd}
            onRemove={handleDelete}
            error={datos.error.archivos}
            helperText={datos.error.archivos && "Debe subir al menos un archivo"}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Comentarios
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows="3"
            size="small"
            value={datos.commentary}
            onChange={({ target }) => {
              dispatch({ type: "commentary", payload: target.value });
            }}
            InputLabelProps={{
              shrink: true
            }}
            error={datos.error.commentary}
            helperText={
              datos.error.commentary && "El comentario no debe tener mas de 1000 caracteres"
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.alignRight}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              disabled={loading || datos.commentary === ""}
              onClick={handleAddCommentary}
            >
              <AddIcon /> Agregar comentario
            </Button>
          </Hidden>
          <Hidden smDown>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={loading || datos.commentary === ""}
              onClick={handleAddCommentary}
            >
              <AddIcon /> Agregar comentario
            </Button>
          </Hidden>
        </Grid>
        {showBitacora()}
        <Grid item xs={12}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="xs">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleButtonClick}
              >
                Aceptar
              </Button>
            </Container>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LiquidarSiniestro;
