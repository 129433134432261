import React from "react";
import AdjuntarAntecedentes from "./AdjuntarAntecedentes";
import AdjuntarGrabaciones from "./AdjuntarGrabaciones";
import Encuesta from "./Encuesta";
import EvaluarSiniestro from "./EvaluarSiniestro";
import GestionarAsistencia from "./GestionarAsistencia";
import LiquidarSiniestro from "./LiquidarSiniestro";
import PagarServicios from "./PagarServicios";
import Seguimiento from "./Seguimiento";

export default {
  value: "default",
  adjuntarAntecedentes: {
    value: "adjuntar-antecedentes",
    title: "Adjuntar Antecedentes",
    next: "evaluar-siniestro",
    component: props => {
      return <AdjuntarAntecedentes {...props} />;
    }
  },
  evaluarSiniestro: {
    value: "evaluar-siniestro",
    title: "Evaluar Siniestro",
    next: "gestionar-asistencia",
    component: props => {
      return <EvaluarSiniestro {...props} />;
    }
  },
  gestionarAsistencia: {
    value: "gestionar-asistencia",
    title: "Gestionar Asistencia",
    next: "seguimiento",
    component: props => {
      return <GestionarAsistencia {...props} />;
    }
  },
  seguimiento: {
    value: "seguimiento",
    title: "Seguimiento",
    next: "liquidar-siniestro",
    component: props => {
      return <Seguimiento {...props} />;
    }
  },
  liquidarSiniestro: {
    value: "liquidar-siniestro",
    title: "Liquidar Siniestro",
    next: "pagar-servicios",
    component: props => {
      return <LiquidarSiniestro {...props} />;
    }
  },
  pagarServicios: {
    value: "pagar-servicios",
    title: "Pagar Servicios",
    next: ["encuesta", "adjuntar-grabaciones"],
    component: props => {
      return <PagarServicios {...props} />;
    }
  },
  encuesta: {
    value: "encuesta",
    title: "Encuesta",
    component: props => {
      return <Encuesta {...props} />;
    }
  },
  adjuntarGrabaciones: {
    value: "adjuntar-grabaciones",
    title: "Adjuntar Grabaciones",
    next: false,
    component: props => {
      return <AdjuntarGrabaciones {...props} />;
    }
  }
};
