import React, { createContext, useState } from "react";
import ContactCreate from "../components/contacts/ContactCreate";
import HubDrawer from "../components/HubDrawer";
import TicketCreate from "../components/tickets/TicketCreate";
import { DRAWER_KEYS } from "../constants/drawer";
import Contacts from "./mobile/Contacts";
import Tasks from "./mobile/Tasks";
import Tickets from "./mobile/Tickets";
import Upload from "./mobile/Upload";
import Removal from "./mobile/Removal";
import Reports from "./mobile/Reports";
import Search from "./mobile/Search";

export const MobileContext = createContext("MobileContext");

const Mobile = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedView, setSelectedView] = useState("tasks");
  const [openContactCreate, setOpenContactCreate] = useState(false);
  const [openTicketCreate, setOpenTicketCreate] = useState(false);

  //Creacion de ticket desde contacto
  const [contacto, setContacto] = useState(null);

  const setCrearTicket = (contacto) => {
    setOpenTicketCreate(true);
    setContacto(contacto);
  };
  const context = {
    drawerOpen,
    onDrawerOpen: () => setDrawerOpen(true),
    onDrawerClose: () => setDrawerOpen(false),

    onViewOpen: key => {
      setDrawerOpen(false);
      setSelectedView(key);
    },
    onViewClose: () => setSelectedView(null),
    onContactCreatedMobile: () => setSelectedView("contacts"),
    onTicketCreatedMobile: () => setSelectedView("tickets"),
    onTicketCreateMobile: setCrearTicket,
    removeContactFromTicketMobile: () => setContacto(null),
    getContactFromTicketMobile: contacto ? contacto : null
  };

  const CurrentView = () => {
    switch (selectedView) {
      case DRAWER_KEYS.tasks:
        return <Tasks />;
      case DRAWER_KEYS.tickets:
        return <Tickets />;
      case DRAWER_KEYS.contacts:
        return <Contacts />;
      case "removal":
        return <Removal />;
      case "upload":
        return <Upload />;
      case DRAWER_KEYS.reports:
        return <Reports />;
      case DRAWER_KEYS.search:
        return <Search />;
      default:
        return <></>;
    }
  };

  return (
    <MobileContext.Provider value={context}>
      <HubDrawer
        mobile={true}
        open={context.drawerOpen}
        onClose={context.onDrawerClose}
        onDrawerItemClick={key => context.onViewOpen(key)}
        onCreateTicket={() => setOpenTicketCreate(true)}
        onCreateContact={() => setOpenContactCreate(true)}
      />

      <CurrentView />

      {openContactCreate && (
        <ContactCreate
          mobile={true}
          open={openContactCreate}
          onClose={() => setOpenContactCreate(false)}
        />
      )}
      {openTicketCreate && (
        <TicketCreate
          mobile={true}
          open={openTicketCreate}
          onClose={() => setOpenTicketCreate(false)}
          contact={contacto}
        />
      )}
    </MobileContext.Provider>
  );
};

export default Mobile;
