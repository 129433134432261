import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useReducer, useState } from "react";
import NumberFormat from "react-number-format";
import {
  formatRut,
  nombreEsValido,
  rutEsValido,
  toTitleCase,
  unformatRut,
  validateEmail
} from "../../../functions";
import AppBarCreate from "../../common/AppBarCreate";
import SelectBank from "../../common/SelectBank";
import SelectBankAccount from "../../common/SelectBankAccount";
import { TYPES_KEYS } from "./constants";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%"
    }
  },
  button: {
    margin: theme.spacing(2, 0)
  }
}));

const initialState = {
  rut: "",
  nombre: "",
  direccion: "",
  telefono_contacto: "",
  correo_contacto: "",
  banco: "",
  tipo_cuenta: "",
  numero_cuenta: "",
  error: {
    rut: false,
    nombre: false,
    celular: false,
    correo_contacto: false,
    direccion: false,
    banco: false,
    tipo_cuenta: false,
    numero_cuenta: false
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "rut":
      return { ...state, [type]: payload, error: { ...state.error, rut: !rutEsValido(payload) } };
    case "nombre":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, nombre: !nombreEsValido(payload) }
      };
    case "telefono_contacto":
      return {
        ...state,
        [type]: payload,
        error: {
          ...state.error,
          telefono_contacto: !payload || payload === "" || payload.length < 8
        }
      };
    case "correo_contacto":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, correo_contacto: !validateEmail(payload) }
      };
    case "direccion":

      return {
        ...state,
        [type]: payload,
        error: { ...state.error, direccion: !payload || payload === "" }
      };
    case "banco":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, banco: !payload || payload === "" }
      };
    case "tipo_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, tipo_cuenta: !payload || payload === "" }
      };
    case "numero_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, numero_cuenta: !payload || payload === "" || payload.length < 4 }
      };
    case "error":
      return {
        ...state,
        error: { ...state.error, [payload]: true }
      };
    default:
      return { ...initialState };
  }
};

const AseguradoCreate = ({ account, sending, onBack, onClose, onCreate }) => {
  const classes = useStyles();
  const stepCount = 2;
  const [step, setStep] = useState(0);
  const [data, dispatch] = useReducer(reducer, initialState);

  const validate = () => {
    switch (step) {
      case 0:
        if (!rutEsValido(data.rut)) dispatch({ type: "error", payload: "rut" });

        if (!nombreEsValido(data.nombre)) dispatch({ type: "error", payload: "nombre" });

        if (
          !data.telefono_contacto ||
          data.telefono_contacto === "" ||
          data.telefono_contacto.length < 8
        )
          dispatch({ type: "error", payload: "telefono_contacto" });

        if (!data.direccion || data.direccion === "")
          dispatch({ type: "error", payload: "direccion" });

        if (!validateEmail(data.correo_contacto))
          dispatch({ type: "error", payload: "correo_contacto" });

        return (
          rutEsValido(data.rut) &&
          nombreEsValido(data.nombre) &&
          data.direccion &&
          data.direccion !== "" &&
          data.telefono_contacto &&
          data.telefono_contacto !== "" &&
          validateEmail(data.correo_contacto)
        );
      case 1:
        if (!data.banco) dispatch({ type: "error", payload: "banco" });

        if (!data.tipo_cuenta) dispatch({ type: "error", payload: "tipo_cuenta" });

        if (!data.numero_cuenta || data.numero_cuenta === "")
          dispatch({ type: "error", payload: "numero_cuenta" });

        return (
          data.banco !== null &&
          data.tipo_cuenta !== null &&
          data.numero_cuenta &&
          data.numero_cuenta.length > 2
        );
      default:
        return false;
    }
  };

  const create = () => {
    const {
      rut,
      nombre,
      telefono_contacto,
      correo_contacto,
      direccion,
      banco,
      tipo_cuenta,
      numero_cuenta
    } = data;

    const body = {
      rut,
      correo_contacto,
      telefono_contacto,
      direccion: {description: direccion},
      datos_bancarios: { banco, tipo_cuenta, numero_cuenta }
    };

    const tags = [];
    const id_cliente = null;
    const id_cuenta = account.id_cuenta;

    if (validate()) {
      onCreate(TYPES_KEYS.asegurado, nombre.trim(), body, tags, id_cliente, id_cuenta);
    }
  };

  const onPrevStep = () => {
    if (step === 0) {
      onBack();
    } else {
      setStep(s => --s);
    }
  };

  const onNextStep = () => {
    if (step < stepCount - 1) {
      validate() && setStep(s => ++s);
    } else {
      create();
    }
  };

  const AseguradoCreatePersonal = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <Typography variant="h6" component="p">
          Datos personales
        </Typography>

        <TextField
          id="rut"
          size="small"
          variant="outlined"
          label="Rut *"
          placeholder="Ej: 12.345.678-9"
          InputLabelProps={{
            shrink: true
          }}
          value={formatRut(data.rut)}
          disabled={sending}
          onChange={({ target }) => {
            dispatch({ type: "rut", payload: unformatRut(target.value) });
          }}
          onBlur={() => dispatch({ type: "rut", payload: data.rut })}
          error={data.error.rut}
          helperText={data.error.rut && "Debe ingresar un Rut valido"}
        />

        <TextField
          id="name"
          size="small"
          variant="outlined"
          label="Nombre *"
          placeholder="Ej: Juan Pérez"
          InputLabelProps={{
            shrink: true
          }}
          value={data.nombre && toTitleCase(data.nombre)}
          disabled={sending}
          onChange={({ target }) => dispatch({ type: "nombre", payload: target.value })}
          onBlur={() => dispatch({ type: "nombre", payload: data.nombre })}
          error={data.error.nombre}
          helperText={data.error.nombre && "Debe ingresar un nombre"}
        />
        <Typography variant="h6" component="p">
          Datos de contacto
        </Typography>

        <NumberFormat
          id="phone"
          customInput={TextField}
          format="+56 9 #### ####"
          allowEmptyFormatting
          size="small"
          variant="outlined"
          type="tel"
          label="Teléfono celular *"
          placeholder="Ej: +56 9 1234 5678"
          InputLabelProps={{
            shrink: true
          }}
          value={data.telefono_contacto}
          disabled={sending}
          onValueChange={({ value }) => dispatch({ type: "telefono_contacto", payload: value })}
          onBlur={() => dispatch({ type: "telefono_contacto", payload: data.telefono_contacto })}
          error={data.error.telefono_contacto}
          helperText={data.error.telefono_contacto && "Debe ingresar un celular valido"}
        />

        <TextField
          id="email"
          size="small"
          variant="outlined"
          type="email"
          label="Email *"
          placeholder="Ej: juanperez@gmail.com"
          InputLabelProps={{
            shrink: true
          }}
          value={data.correo_contacto}
          disabled={sending}
          onChange={({ target }) =>
            dispatch({ type: "correo_contacto", payload: target.value.toLowerCase() })
          }
          onBlur={() => dispatch({ type: "correo_contacto", payload: data.correo_contacto })}
          error={data.error.correo_contacto}
          helperText={data.error.correo_contacto && "Debe ingresar un correo valido"}
        />

        <Typography variant="h6" component="p">
          Ubicación
        </Typography>

        {/* <SelectAddress
          id="address"
          size="small"
          variant="outlined"
          label="Dirección *"
          value={data.direccion}
          disabled={sending}
          onChange={value =>
            dispatch({
              type: "direccion",
              payload: value
            })
          }
          onBlur={() => dispatch({ type: "direccion", payload: data.direccion })}
          error={data.error.direccion}
          helperText={data.error.direccion && "Debe ingresar una direccion"}
        /> */}

        <TextField
          id="address"
          fullWidth
          size="small"
          variant="outlined"
          disabled={sending}
          label="Dirección *"
          InputLabelProps={{
            shrink: true
          }}
          value={data.direccion}
          onChange={({ target }) =>
            dispatch({ type: "direccion", payload: target.value.toLowerCase() })
          }
          onBlur={() => dispatch({ type: "direccion", payload: data.direccion })}
          error={data.error.direccion}
          helperText={data.error.direccion && "Debe ingresar una direccion"}
        />
      </form>
    );
  };

  const AseguradoCreateBank = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <Typography variant="h6" component="p">
          Datos bancarios
        </Typography>

        <SelectBank
          className="control"
          size="small"
          variant="outlined"
          value={data.banco}
          disabled={sending}
          onChange={value => dispatch({ type: "banco", payload: value })}
          onBlur={() => dispatch({ type: "banco", payload: data.banco })}
          error={data.error.banco}
          helperText={data.error.banco && "Debe seleccionar un banco"}
        />

        <SelectBankAccount
          className="control"
          size="small"
          variant="outlined"
          value={data.tipo_cuenta}
          disabled={sending}
          onChange={value => dispatch({ type: "tipo_cuenta", payload: value })}
          onBlur={() => dispatch({ type: "tipo_cuenta", payload: data.tipo_cuenta })}
          error={data.error.tipo_cuenta}
          helperText={data.error.tipo_cuenta && "Debe seleccionar un tipo de cuenta"}
        />

        <NumberFormat
          id="bank_account_number"
          customInput={TextField}
          size="small"
          variant="outlined"
          type="tel"
          label="Numero de cuenta *"
          placeholder="Ej: 123456789"
          InputLabelProps={{
            shrink: true
          }}
          value={data.numero_cuenta}
          disabled={sending}
          onValueChange={({ value }) => dispatch({ type: "numero_cuenta", payload: value })}
          onBlur={() => dispatch({ type: "numero_cuenta", payload: data.numero_cuenta })}
          error={data.error.numero_cuenta}
          helperText={data.error.numero_cuenta && "Debe ingresar un numero de cuenta"}
        />
      </form>
    );
  };

  const CurrentStep = () => {
    switch (step) {
      case 0:
        return AseguradoCreatePersonal();
      case 1:
        return AseguradoCreateBank();
      default:
        return <></>;
    }
  };

  return (
    <>
      <AppBarCreate
        title="Crear Beneficiario"
        disabled={sending}
        onBack={onPrevStep}
        onClose={onClose}
      />
      <Container className={classes.container} maxWidth="sm">
        {CurrentStep()}
        {step + 1 <= stepCount && (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={sending}
            onClick={onNextStep}
          >
            {step + 1 < stepCount ? "Siguiente" : "Guardar"}
          </Button>
        )}
      </Container>
    </>
  );
};

export default AseguradoCreate;
