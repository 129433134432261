import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BuildIcon from "@material-ui/icons/Build";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PhoneIcon from "@material-ui/icons/Phone";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Rating from "@material-ui/lab/Rating";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { formatRut, formatDate } from "../../../functions";
import Loading from "../../common/Loading";
import Conditions from "../../common/Conditions";
import TicketItemView from "../../tickets/TicketItemView";
import TicketDetailView from "../../tickets/TicketDetailView";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2, 3),
  },
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    minHeight: theme.spacing(4),
  },
  cardIcon: {
    marginRight: theme.spacing(3),
  },
  contactListItem: {
    minHeight: theme.spacing(9),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  container: {
    overflow: "auto",
    height: "calc(100vh - 64px)",
    padding: 0,
  },
}));


const getCobertura = (cobertura) => {
  switch (cobertura){
    case "CQ":
      return "Titular 9UF";
    case "CR":
      return "Titular 21UF"
    case "CS":
      return "Titular 43UF"      
    case "CT":
      return "Familiar 9UF"
    case "CU":
      return "Familiar 21UF"
    case "CV":
      return "Familiar 43UF"
    case "CX":
      return "Gratuito 9UF"
    default:
      return ""
  }
};

  const ContentCardAccount = ({ accountName, contactType, contactTags, contact, getLogo }) => {
    const classes = useStyles();
    let avatar = (
      <Avatar className={classes.cardIcon} src={getLogo(contact.slug_cuenta)} />
    );
    let title = "Datos Cuenta";
    let subtitle = (<>
    Cuenta
    <br/>
    Número de documento
    <br/>
    Fecha de inicio de póliza
    <br/>
    Fecha de término póliza
    <br/>
    Cobertura
    </>);
    let subtitleText = (
      <>
        {accountName}        
        <br />
        {contact.body_contacto.numero_documento}
        <br/>
        {formatDate(contact.body_contacto.fecha_inicio)}
        <br />
        {formatDate(contact.body_contacto.fecha_termino)}
        <br />
        {getCobertura(contact.body_contacto.cobertura)}
      </>
    );

    if (contactType.includes("prestador")) {
      avatar = <BuildIcon className={classes.cardIcon} color="disabled" />;
      title = "Datos Prestador";
      subtitle = "Cuenta";
      subtitleText = (
        <>
          {accountName}
          {contact.config_cuenta && contact.config_cuenta.condiciones && (
            <Conditions condiciones={contact.config_cuenta.condiciones} />
          )}
          <br />
          {contactTags && contactTags.join(" , ")}
        </>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardTitle}>
            {avatar}
            <Typography>{title}</Typography>
          </div>
          <Divider className={classes.divider} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {subtitle}
            </Grid>
            <Grid item xs={6}>
              {subtitleText}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  export default ContentCardAccount;
