import React from "react";
import { formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import AssignmentIcon from "@material-ui/icons/Assignment";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0)
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

const ContentCardDetails = ({ mobile, ejecutivo, fecha, cuenta }) => {
  const classes = useStyles();

  return (
    <Card className={!mobile && classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <AssignmentIcon color="disabled" />
        </div>

        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          {ejecutivo && (
            <>
              <Grid item xs={6}>
                Ejecutivo
              </Grid>
              <Grid item xs={6}>
                {`${ejecutivo.primer_nombre_usuario} ${ejecutivo.apellido_paterno_usuario}`}
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            Fecha de creación
          </Grid>
          <Grid item xs={6}>
            {formatRelative(new Date(fecha), Date.now(), {
              locale: es
            })}
          </Grid>
          <Grid item xs={6}>
            Servicio
          </Grid>
          <Grid item xs={6}>
            {cuenta}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentCardDetails;
