export const formatNumber = (str, style) => {
  if (str === "") return "";
  else return Number(str).toLocaleString("es-CL", { style, currency: "CLP" });
};

export const unformatNumber = number => number.toString().replace(/\$/g, "").replace(/\./g, "");

export const formatRut = rut => {
  if (!rut) return null;

  let formatted = rut.toUpperCase();

  if (rut.length > 1) {
    try {
      const number = rut.substring(0, rut.length - 1);
      const dv = rut.charAt(rut.length - 1);

      formatted = `${formatNumber(number, "decimal")}-${dv}`;
    } catch (err) {}
  }

  return formatted;
};

export const nombreEsValido = nombre => {
  const rgxNombre = new RegExp(/^[a-zA-Z' \-áéíóúÁÉÍÓÚñÑäÄëËïÏöÖüÜ]+$/);
  return rgxNombre.test(nombre);
};

export const unformatRut = rut => rut.toUpperCase().replace(/[^0-9K]/g, "");

const calculateDV = rut => {
  const cuerpo = `${rut}`;

  // Calcular Dígito Verificador
  let suma = 0;
  let multiplo = 2;

  // Para cada dígito del Cuerpo
  for (let i = 1; i <= cuerpo.length; i++) {
    // Obtener su Producto con el Múltiplo Correspondiente
    const index = multiplo * cuerpo.charAt(cuerpo.length - i);

    // Sumar al Contador General
    suma += index;

    // Consolidar Múltiplo dentro del rango [2,7]
    if (multiplo < 7) {
      multiplo += 1;
    } else {
      multiplo = 2;
    }
  }

  // Calcular Dígito Verificador en base al Módulo 11
  const dvEsperado = 11 - (suma % 11);
  if (dvEsperado === 10) return "K";
  if (dvEsperado === 11) return "0";
  return `${dvEsperado}`;
};

const repeatChar = (char, times) => {
  let chars = "";
  let len = times;

  while (len--) {
    chars += char;
  }

  return chars;
};

const isRutCharRepeated = rut => {
  let char = 10;

  while (char--) {
    let chars = repeatChar(char.toString(), 7);
    if (rut === chars) return true;

    chars += char.toString();
    if (rut === chars) return true;

    chars += char.toString();
    if (rut === chars) return true;
  }

  return false;
};

export const rutEsValido = rut => {
  const rutLimpio = unformatRut(rut);

  if (rutLimpio.length < 7) return false;
  if (isRutCharRepeated(rutLimpio)) return false;

  const num = rutLimpio.substring(0, rutLimpio.length - 1);
  const dgv = rutLimpio.charAt(rutLimpio.length - 1);

  const dvCalc = calculateDV(num);
  return dvCalc === dgv;
};

export const formatCelular = numero => {
  let str = unformatCelular(numero);
  let parte1 = "";
  let parte2 = "";

  if (str.length > 4) {
    parte1 = str.substring(0, 4);
    parte2 = str.substring(4);

    return `+56 9 ${parte1} ${parte2}`;
  }

  return `+56 9 ${str}`;
};

export const unformatCelular = celular =>
  celular
    .toString()
    .replace(/[^0-9+]/g, "")
    .replace("+569", "");

export const formatDate = date =>
  new Date(date).toLocaleDateString("es-CL", {
    dateStyle: "medium",
    timeZone: "UTC"
  });

export const toTitleCase = str => {
  if (!str) return null;

  return str
    .toString()
    .replace(/^\w/g, c => c.toUpperCase())
    .replace(/ {1,}\w/g, c => c.toUpperCase());
};

export const validateEmail = email => {
  const rgxEmail = new RegExp(/([0-9a-z._]{2,50})@{1}([0-9a-z._-]{2,50})\.{1}([\w]{2,10})/);

  return rgxEmail.test(email);
};

export const removeEmptyStringElements = obj => {
  for (var prop in obj) {
    if (typeof obj[prop] === "object") {
      removeEmptyStringElements(obj[prop]);
    } else if (obj[prop] === "") {
      delete obj[prop];
    }
  }
};

export const hoy = () => {
  const today = new Date();
  const mes = today.getMonth() + 1;
  const dia = today.getDate();

  return `${today.getFullYear()}-${mes < 10 ? "0" : ""}${mes}-${dia < 10 ? "0" : ""}${dia}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
