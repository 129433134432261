import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React, { useState, useEffect, useCallback } from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { API } from "aws-amplify";
import Loading from "../common/Loading";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1, 2)
  },
  historyAvatarActive: {
    margin: theme.spacing(1, 0),
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: "white",
    backgroundColor: green[500]
  },
  historyAvatar: {
    margin: theme.spacing(1, 0),
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  bitacora: {
    margin: theme.spacing(1, 0)
  }
}));

const TicketHistory = ({ ticket }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [mobile, setMobile] = useState(false);

  const fetchTicket = useCallback(async () => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          id: ticket
        }
      };

      const result = await API.get(process.env.REACT_APP_HUB_API, "/tickets/actividades", init);

      if (result.success) {
        setActivities(result.data);
      } else {
        console.warn("fetchTicket", result.error);
      }
    } catch (err) {
      console.error("fetchTicket", err);
    }

    setLoading(false);
  }, [ticket]);

  useEffect(() => {
    if (ticket) {
      fetchTicket();
    }
  }, [ticket, fetchTicket]);

  useEffect(() => {
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    width <= 600 ? setMobile(true) : setMobile(false);
  }, []);

  const activityCard = activity => {
    if (!activity || mobile) {
      return null;
    }

    if (activity.body_actividad.tipo && activity.body_actividad.tipo === "tarea") {
      return (
        <>
          {activity.body_actividad.tarea.body_tarea.bitacora &&
            activity.body_actividad.tarea.body_tarea.bitacora.length > 0 && (
              <>
                <Divider className={classes.divider} />
                <Grid item xs={12}>
                  {activity.body_actividad.tarea.body_tarea.bitacora.map(item => {
                    return (
                      <div
                        key={`${activity.body_actividad.tarea.body_tarea.tipo_tarea}-${item.id}`}
                        className={classes.bitacora}
                      >
                        <Typography variant="body1">{item.commentary}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {item.date}
                        </Typography>
                      </div>
                    );
                  })}
                </Grid>
              </>
            )}
          {activity.body_actividad.tarea.body_tarea.comentario && (
            <>
              <Divider className={classes.divider} />
              <Typography variant="body1">
                {`${activity.body_actividad.tarea.body_tarea.comentario}`}
              </Typography>
            </>
          )}
        </>
      );
    } else {
      return <Typography variant="body1">{activity.body_actividad.contenido}</Typography>;
    }
  };

  const formatDate = date => {
    const formattedDate = new Date(date);

    return formattedDate.toLocaleString("es-CL");
  };

  return (
    <Timeline align="alternate">
      {loading ? (
        <Loading />
      ) : (
        activities.map((item, i) => (
          <TimelineItem key={`timeline-item-${i}`}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {formatDate(item.fecha_creacion_actividad)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <Avatar
                className={
                  activities.length === i + 1 ? classes.historyAvatarActive : classes.historyAvatar
                }
              >
                {i + 1}
              </Avatar>

              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant={!mobile ? "subtitle1" : "body2"}>
                  {item.body_actividad.tipo === "tarea"
                    ? item.body_actividad.tarea.nombre_tarea
                    : item.pasos_pipeline[i]}
                </Typography>

                {activityCard(item)}

                {item.usuario && (
                  <Typography variant={!mobile ? "body2" : "caption"}>
                    {`${item.usuario.primer_nombre_usuario} ${item.usuario.apellido_paterno_usuario}`}
                  </Typography>
                )}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))
      )}
    </Timeline>
  );
};

export default TicketHistory;
