import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import FileUploader from "../../common/FileUploader";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

const AdjuntarGrabaciones = ({ data, setData, setShowDialog }) => {
  const { archivos } = data;

  const handleAdd = newFile => {
    if (!archivos) {
      setData({ ...data, archivos: [newFile] });
    } else setData({ ...data, archivos: [...archivos, newFile] });
  };

  const handleDelete = removeFile => {
    setData({ ...data, archivos: archivos.filter(file => file !== removeFile) });
  };

  const handleButtonClick = e => {
    setShowDialog(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FileUploader
          parent="adjuntar-grabaciones"
          data={archivos}
          maxFiles={8}
          onAdd={handleAdd}
          onRemove={handleDelete}
          type="audio"
        />
      </Grid>
      <Grid item xs={12}>
        <Hidden mdUp>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleButtonClick}
          >
            Aceptar
          </Button>
        </Hidden>
        <Hidden smDown>
          <Container maxWidth="xs">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Container>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default AdjuntarGrabaciones;
