import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { formatBytes } from "../../functions";
import ImageLoader from "./ImageLoader";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DescriptionIcon from "@material-ui/icons/Description";

const COMPRESSION_LEVEL = process.env.REACT_APP_FILEUPLOADER_COMPRESSION
  ? process.env.REACT_APP_FILEUPLOADER_COMPRESSION
  : 0.8;

const FileUploader = ({
  parent,
  className,
  variant = "grid",
  data = [],
  onAdd,
  onRemove,
  maxFiles = 5,
  type = "all",
  error = false,
  helperText = "Debe subir al menos un archivo"
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState("");

  const useStyles = makeStyles(theme => ({
    cardContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 125,
      [theme.breakpoints.down("sm")]: {
        minHeight: 80
      },
      borderColor: error ? "#f44336" : "rgba(0, 0, 0, 0.12)"
    },
    cardActionArea: {
      width: "auto",
      padding: theme.spacing(2)
    },
    imageLoader: {
      img: {
        objectFit: "cover",
        width: theme.spacing(10),
        height: theme.spacing(10)
      }
    },
    imageLoaderPreview: {
      img: {
        objectFit: "cover",
        width: "100%",
        height: "100%"
      }
    },
    img: {
      objectFit: "cover",
      width: theme.spacing(10),
      height: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(5),
        height: theme.spacing(5)
      }
    },
    imgPreview: {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    },
    red: {
      color: "#f44336"
    },
    textCenter: {
      textAlign: "center"
    }
  }));

  const classes = useStyles();

  const compressImage = (img, callback) => {
    const reader = new FileReader();
    reader.onload = e => {
      const img = document.createElement("img");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.height = img.height;
        canvas.width = img.width;

        ctx.drawImage(
          img,
          0, //dx
          0 //dy
        );

        canvas.toBlob(callback, "image/jpeg", parseFloat(COMPRESSION_LEVEL));
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(img);
  };

  const handleAdd = newFiles => {
    if (onAdd) {
      setAlertOpen(false);
      newFiles = Array.from(newFiles);

      if (data.length + newFiles.length <= maxFiles) {
        for (let index = 0; index < newFiles.length; index++) {
          const currentFile = newFiles[index];

          if (currentFile.type.includes("image")) {
            const type = "image/jpeg";
            const extension = ".jpeg";
            const name = currentFile.name.split(".")[0];

            compressImage(currentFile, blob => {
              const newFile = {
                name,
                extension,
                type,
                blob,
                url: URL.createObjectURL(blob),
                size: formatBytes(blob.size)
              };

              if (previewOpen && previewFile) {
                onRemove(previewFile);
                setPreviewOpen(false);
              }
              onAdd(newFile);
            });
          } else {
            const type = currentFile.type;
            const name = currentFile.name.split(".")[0];

            const extensions = currentFile.name.split(".").slice(1);
            const extension = "." + extensions.join(".");

            const newFile = {
              name,
              extension,
              type,
              blob: currentFile,
              url: URL.createObjectURL(currentFile),
              size: formatBytes(currentFile.size)
            };

            onAdd(newFile);
          }
        }
      } else {
        console.warn("FileUploader", "Exceeds max files rule of " + maxFiles);
        setAlertMsg("No se pueden agregar mas de " + maxFiles + " archivos");
        setAlertOpen(true);
      }
    }
  };

  const handlePreviewOpen = f => {
    setAlertOpen(false);
    setPreviewFile(f);
    setPreviewOpen(true);
  };

  const handlePreviewClose = e => {
    setPreviewOpen(false);
  };

  const handlePreviewDelete = e => {
    setPreviewOpen(false);
    if (onRemove) onRemove(previewFile);
  };

  const ErrorMessage = () => (
    <Collapse in={alertOpen}>
      <Alert
        severity="warning"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlertOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {alertMsg}
      </Alert>
    </Collapse>
  );

  const FilesGrid = () => {
    let showAddButton = false;

    if (data && data.length < maxFiles) showAddButton = true;

    return (
      <Grid container spacing={2}>
        {data.map((file, i) => (
          <Grid key={`file-${i}`} item xs={3}>
            <Card className={classes.cardContent} variant="outlined">
              <CardActionArea
                className={classes.cardActionArea}
                onClick={e => handlePreviewOpen(file)}
              >
                {file.type.indexOf("audio") === 0 || file.type.indexOf("video") === 0 ? (
                  <PlayCircleFilledIcon style={{ fontSize: 50 }} />
                ) : file.type.indexOf("application/pdf") === 0 ? (
                  <PictureAsPdfIcon style={{ fontSize: 50 }} />
                ) : file.type.indexOf("application") === 0 ? (
                  <DescriptionIcon style={{ fontSize: 50 }} />
                ) : file.type.indexOf("text") === 0 ? (
                  <DescriptionIcon style={{ fontSize: 50 }} />
                ) : file.url ? (
                  <img
                    src={file.url}
                    alt={`file-${file.extension.substr(1)}-${i}`}
                    className={classes.img}
                  />
                ) : (
                  <ImageLoader
                    imgKey={file.key}
                    alt={`file-${file.extension.substr(1)}-${i}`}
                    className={classes.imageLoader}
                  />
                )}
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        {showAddButton && (
          <Grid key={`file-upload`} item xs={3}>
            <UploadCard />
          </Grid>
        )}
      </Grid>
    );
  };

  const UploadCard = () => (
    <Card className={classes.cardContent} variant="outlined">
      <label htmlFor={"upload-" + parent}>
        <IconButton component="span">
          <AttachFileIcon fontSize="large" />
        </IconButton>
      </label>
    </Card>
  );

  const FilePreview = () => {
    let imgComponent = "";

    if (previewFile && previewFile.url) {
      if (previewFile.type.indexOf("audio") === 0) {
        imgComponent = <audio controls src={previewFile.url}></audio>;
      } else if (previewFile.type.indexOf("video") === 0) {
        imgComponent = (
          <video controls width="300">
            <source src={previewFile.url} type={previewFile.type} />
          </video>
        );
      } else if (
        previewFile.type.indexOf("application") === 0 ||
        previewFile.type.indexOf("text") === 0
      ) {
        imgComponent = (
          <Tooltip title="Descargar archivo" aria-label="descargar">
            <Link href={previewFile.url} target="_blank">
              <GetAppIcon style={{ fontSize: 50 }} />
            </Link>
          </Tooltip>
        );
      } else {
        imgComponent = (
          <img className={classes.imgPreview} src={previewFile.url} alt="file-preview" />
        );
      }
    } else if (previewFile && previewFile.key) {
      imgComponent = (
        <ImageLoader
          className={classes.imageLoaderPreview}
          imgKey={previewFile.key}
          alt="file-preview"
        />
      );
    }

    return (
      <Dialog
        maxWidth="md"
        open={previewOpen}
        onClose={handlePreviewClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography>
          <IconButton aria-label="close" onClick={handlePreviewClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.textCenter}>{imgComponent}</DialogContent>
        <DialogActions>
          <label>
            <Button size="large" startIcon={<DeleteIcon />} onClick={handlePreviewDelete}>
              Eliminar archivo
            </Button>
          </label>
        </DialogActions>
      </Dialog>
    );
  };

  const fileTypes = type => {
    switch (type) {
      case "all":
        return "*";
      case "audio":
        return "audio/*";
      case "video":
        return "video/*";
      case "image":
        return "image/*";
      case "csv":
        return ".csv";
      default:
        return "*";
    }
  };

  return (
    <div>
      <ErrorMessage />

      <Typography variant="body1" gutterBottom>
        Haga click para subir un archivo
      </Typography>

      <FilesGrid />

      <input
        accept={fileTypes(type)}
        style={{ display: "none" }}
        id={"upload-" + parent}
        type="file"
        onChange={({ target }) => handleAdd(target.files)}
      />

      <FilePreview />
      <Grid container>
        <Grid item xs={12}>
          {error && <FormHelperText className={classes.red}>{helperText}</FormHelperText>}
        </Grid>
      </Grid>
    </div>
  );
};

export default FileUploader;
