import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const types = [
  { label: "Cuenta de Ahorros", value: "CUENTA_AHORRO" },
  { label: "Cuenta Vista", value: "CUENTA_VISTA" },
  { label: "Cuenta Corriente", value: "CUENTA_CORRIENTE" },
  { label: "Chequera Electrónica", value: "CHEQUERA_ELECTRONICA" }
];

const SelectBankAccount = ({
  className,
  disabled,
  variant,
  size,
  value,
  onChange = () => {},
  label = "Tipo de cuenta",
  placeholder = "Ej: Cuenta corriente",
  onBlur = () => {},
  error,
  helperText
}) => {
  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      options={types}
      getOptionLabel={option => (option.label ? option.label : option)}
      value={value}
      onChange={(_, value) => onChange(value)}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          size={size}
          variant={variant}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          onBlur={onBlur}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export const getAccountType = value => types.filter(t => t.value === value)[0] || null;

export default SelectBankAccount;
