import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { validateEmail } from "../../functions";
import { getAwsAuthException } from "./authFunctions";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/welcome.jpg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "light" ? "white" : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "right"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  logo: {
    width: "65%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  alertMsg: {
    margin: theme.spacing(2, 0)
  },
  submit: {
    margin: theme.spacing(2, 0),
    minHeight: theme.spacing(6)
  },
  forgot: {
    marginTop: theme.spacing(2)
  }
}));

const SignInSide = props => {
  const classes = useStyles();
  const { authState, authData, onStateChange } = props;

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMsg] = useState({ open: false, type: "error", msg: "" });

  useEffect(() => {
    if (authData && authData.forgotPassword)
      setAlertMsg({ open: true, type: "success", msg: "Contraseña cambiada existosamente" });
  }, [authData]);

  useEffect(() => {
    setAlertMsg({ open: false, type: "error", msg: "" });
  }, [authState]);

  const reset = () => {
    setUsername("");
    setUsernameErrorMessage("");
    setPassword("");
    setPasswordErrorMessage("");
    setAlertMsg({ ...alertMessage, open: false, msg: "" });
  };

  const validate = () => {
    let result = true;

    if (!username || username === "") {
      setUsernameErrorMessage("El email no puede estar vacío");
      result = false;
    } else if (!validateEmail(username)) {
      setUsernameErrorMessage("Debe ingresar un email válido");
      result = false;
    } else {
      setUsernameErrorMessage("");
    }

    if (!password || password === "") {
      setPasswordErrorMessage("La contraseña no puede estar vacía");
      result = false;
    } else {
      setPasswordErrorMessage("");
    }

    return result;
  };

  const signIn = async e => {
    e.preventDefault();
    setAlertMsg({ ...alertMessage, open: false, msg: "" });

    if (validate()) {
      setLoading(true);

      try {
        const user = await Auth.signIn({
          username,
          password
        });

        reset();
        onStateChange("signedIn", user);
      } catch (err) {
        const msg = getAwsAuthException(err.code, err.message);
        setAlertMsg({
          open: true,
          type: "error",
          msg
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const gotoForgotPassword = () => {
    reset();
    onStateChange("forgotPassword");
  };

  if (authState !== "signIn") {
    return null;
  }

  return (
    <Grid container component="main" className={`auth ${classes.root}`}>
      <CssBaseline />
      <Grid item xs={false} sm={5} md={7} lg={8} xl={9} className={classes.image} />
      <Grid item xs={12} sm={7} md={5} lg={4} xl={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <img
            className={classes.logo}
            src={process.env.PUBLIC_URL + "/assets/images/logo_serviclick.png"}
            alt="home-logo"
          />
          <form className={classes.form} noValidate onSubmit={signIn}>
            <Grid container>
              <Grid item xs={12}>
                <Collapse in={alertMessage.open}>
                  <Alert
                    className={classes.alertMsg}
                    variant="filled"
                    severity={alertMessage.type}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setAlertMsg({ ...alertMessage, open: false })}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {alertMessage.msg}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={username}
                  onChange={({ target }) => setUsername(target.value.toLowerCase())}
                  disabled={loading}
                  error={usernameErrorMessage ? true : false}
                  helperText={usernameErrorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  disabled={loading}
                  error={passwordErrorMessage ? true : false}
                  helperText={passwordErrorMessage}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              className={classes.submit}
              disabled={loading}
            >
              {!loading ? "Entrar" : <CircularProgress size={30} />}
            </Button>

            <Grid container justify="center">
              <Grid item>
                <Button className={classes.forgot} color="primary" onClick={gotoForgotPassword}>
                  ¿Olvidaste tu contraseña?
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5} mb={1}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignInSide;
