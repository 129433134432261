import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { fade, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { API } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { tickets_filter } from "../../constants/tickets_filter";
import { rutEsValido } from "../../functions";
import Loading from "../common/Loading";
import TicketItemView from "./TicketItemView";

const useStyles = makeStyles(theme => ({
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  filterButton: {
    marginLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  list: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1)
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%"
  }
}));

const TicketsListView = ({ onClick, onDrawerOpen }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(tickets_filter.filter(item => item.checkbox));
  const [tickets, setTickets] = useState([]);
  const [initialTickets, setInitialTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [title, setTitle] = useState("Todos");
  const searchRef = useRef();

  const fetchTickets = useCallback(async () => {
    setLoading(true);

    try {
      const result = await API.get(process.env.REACT_APP_HUB_API, "/tickets");

      if (result.success) {
        setTickets(result.data.filter(ticket => ticket.estado_ticket < 9));
        setInitialTickets(result.data);
      } else {
        console.warn("fetchTickets", result.error);
      }
    } catch (err) {
      console.error("fetchTickets", err);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const openFilter = Boolean(anchorEl);

  const openFilterMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let tempTickets = [];

    if (value.checkbox) {
      currentIndex === -1 ? newChecked.push(value) : newChecked.splice(currentIndex, 1);
      setChecked(newChecked);
    }

    tempTickets = filterAccounts(newChecked);

    /**
     * TODO establecer estados generales de los tickets para estos casos
     */
    if (!value.checkbox) {
      setTitle(value.label);
      switch (value.key) {
        case "open":
          tempTickets = tempTickets.filter(ticket => ticket.estado_ticket < 9);
          break;
        case "rejected":
          tempTickets = tempTickets.filter(ticket => ticket.estado_ticket === 99);
          break;
        case "completed":
          tempTickets = tempTickets.filter(ticket => ticket.estado_ticket === 9);
          break;
        default:
          break;
      }
    } else {
      setTitle("Todos");
    }

    setTickets(tempTickets);
  };

  const filterAccounts = accounts => {
    const results = [];

    accounts.map(account => {
      results.push(
        ...initialTickets.filter(ticket => {
          return ticket.slug_cuenta === account.key;
        })
      );

      return true;
    });

    return results;
  };

  const TicketListItem = ({ ticket }) => (
    <>
      <TicketItemView ticket={ticket} onItemClick={onClick} />
      <Divider variant="middle" />
    </>
  );

  const isNumeric = value => {
    return /^\d+$/.test(value);
  };

  const search = value => {
    if (!value) {
      setTickets(initialTickets);
    }

    // Búsqueda por número de folio
    if (isNumeric(value) && !rutEsValido(value)) {
      const results = initialTickets.filter(ticket => {
        return parseInt(value) === ticket.folio_ticket;
      });

      setTickets(results);
    }

    // Búsqueda por RUT
    if (rutEsValido(value)) {
      const results = initialTickets.filter(ticket => {
        return value === ticket.body_ticket.asegurado.body_contacto.rut;
      });

      setTickets(results);
    }

    // Búsqueda por nombre del asegurado
    if (value && !isNumeric(value) && !rutEsValido(value)) {
      const results = initialTickets.filter(ticket => {
        return ticket.body_ticket.asegurado.nombre_contacto
          .toLowerCase()
          .includes(value.toLowerCase());
      });

      setTickets(results);
    }
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {onDrawerOpen && (
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              onClick={onDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}

          {showSearchBar && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "buscador" }}
                inputRef={searchRef}
                onKeyDown={e => {
                  e.keyCode === 13 && search(e.target.value);
                }}
                onBlur={e => {
                  search(e.target.value);
                  setShowSearchBar(false);
                }}
              />
            </div>
          )}

          {!showSearchBar && (
            <>
              <Typography className={classes.title} variant="h6" component="p">
                {`Casos - ${title}`}
              </Typography>

              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
                onClick={() => fetchTickets()}
              >
                <RefreshIcon />
              </IconButton>

              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
                onClick={() => {
                  setShowSearchBar(!showSearchBar);
                  setTimeout(() => {
                    searchRef.current.focus();
                  }, [100]);
                }}
              >
                <SearchIcon />
              </IconButton>

              <IconButton
                className={classes.filterButton}
                edge="end"
                color="inherit"
                onClick={openFilterMenu}
              >
                <FilterListIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={openFilter} onClose={closeFilterMenu}>
                {tickets_filter.map(item => {
                  const labelId = `checkbox-list-label-${item.key}`;

                  return (
                    <div key={`tickets-menu-item-${item.key}`}>
                      <MenuItem onClick={handleToggle(item)}>
                        {item.checkbox && (
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(item) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={item.label} />
                      </MenuItem>
                      {!item.checkbox && <Divider variant="fullWidth" />}
                    </div>
                  );
                })}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {loading ? (
        <Loading variant="list" listSubText={2} />
      ) : (
        <List className={classes.list}>
          {tickets.map((item, i) => (
            <TicketListItem key={`ticket-item-${i}`} ticket={item} />
          ))}
        </List>
      )}
    </>
  );
};

export default TicketsListView;
