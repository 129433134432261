import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import ContactsListView from "../../components/contacts/ContactsListView";
import ContactDetailView from "../../components/contacts/ContactDetailView";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "hidden"
  }
}));

const Contacts = () => {
  const [contact, setContact] = useState(null);
  const classes = useStyles();

  const selectContact = contact => {
    setContact(contact);
  };

  return (
    <Grid container spacing={0} className={classes.overflowHidden}>
      <Grid item xs={12} sm={4}>
        <ContactsListView onClick={selectContact} />
      </Grid>

      <Grid item xs={12} sm={8}>
        <ContactDetailView contact={contact} />
      </Grid>
    </Grid>
  );
};

export default Contacts;
