import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { API } from "aws-amplify";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { fade, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { tasks_filter } from "../../constants/tasks_filter";
import { rutEsValido } from "../../functions";
import { AppContext } from "../App";
import Loading from "../common/Loading";
import TaskListItem from "./TaskListItem";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
  filterButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  secondaryAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const TaskListView = ({ task, onSelectedTask, onDrawerOpen }) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(
    tasks_filter.filter((item) => item.checkbox)
  );
  const [selectedTask, setSelectedTask] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [initialTasks, setInitialTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [title, setTitle] = useState("Todas");
  const searchRef = useRef();

  const fetchTasks = useCallback(async () => {
    setLoading(true);

    try {
      const result = await API.get(process.env.REACT_APP_HUB_API, "/tareas");

      if (result.success) {
        setTasks(result.data);
        setInitialTasks(result.data);
      } else {
        console.warn("fetchTasks", result.error);
      }
    } catch (err) {
      console.error("fetchTasks", err);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    !task && fetchTasks(); // TODO: Actualizar lista sin hacer fetch
  }, [task, fetchTasks]);

  const openFilter = Boolean(anchorEl);

  const openFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let tempTasks = [];

    if (value.checkbox) {
      currentIndex === -1
        ? newChecked.push(value)
        : newChecked.splice(currentIndex, 1);
      setChecked(newChecked);
    }

    tempTasks = filterAccounts(newChecked);

    /**
     * TODO establecer estados generales de los tickets para estos casos
     */
    if (!value.checkbox) {
      setTitle(value.label);
      switch (value.key) {
        case "mine":
          tempTasks = tempTasks.filter(
            (task) => task.id_usuario_responsable_tarea === user.id_usuario
          );
          break;
        case "all":
          break;
        default:
          break;
      }
    } else {
      setTitle("Todas");
    }

    setTasks(tempTasks);
  };

  const filterAccounts = (accounts) => {
    const results = [];

    accounts.map((account) => {
      results.push(
        ...initialTasks.filter((task) => {
          return task.slug_cuenta === account.key;
        })
      );

      return true;
    });

    return results;
  };

  const asignTask = async () => {
    try {
      const init = {
        body: {
          id: selectedTask.id_tarea,
          estado: 1,
          actividad: null,
          ticket: selectedTask.id_ticket,
          responsable: user.id_usuario,
        },
      };

      const result = await API.put(
        process.env.REACT_APP_HUB_API,
        "/tickets/tarea",
        init
      );

      if (result.success) {
        setShowDialog(false);
        getTask(selectedTask.id_tarea);
        //onSelectedTask(selectedTask);
      } else console.warn("fetchTasks", result.error);
    } catch (err) {
      console.error("fetchContacto", err);
    } finally {
    }
  };

  const getTask = async (id_tarea) => {
    //console.log("DOIN THE QUERY");
    try {
      const init = {
        queryStringParameters: {
          tarea: id_tarea,
        },
      };

      const result = await API.get(
        process.env.REACT_APP_HUB_API,
        "/tareas",
        init
      );

      if (result.success) {
        //setShowDialog(false);
        setSelectedTask(result.data)
        //onSelectedTask(selectedTask);
        //console.log("SELECTED TASK ES: ", result.data);
        //console.log("TASK CONSULTADO ES: ", selectedTask);
        onSelectedTask(result.data);    
      } else console.warn("fetchTask", result.error);
    } catch (err) {
      console.error("fetchTask", err);
    } finally {
    }
  };

  const DialogOpenTask = () => {
    return (
      <Dialog
        maxWidth="md"
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <DialogTitle align="center">
          {selectedTask && "Reclamar tarea - " + selectedTask.nombre_tarea}
        </DialogTitle>

        <DialogContent>
          <DialogContentText align="center">
            ¿Esta seguro de asignarte esta tarea?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            fullWidth
            //disabled={loadingAsign}
            onClick={() => {
              setShowDialog(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            fullWidth
            //disabled={loadingAsign}
            onClick={() => {
              asignTask();
            }}
          >
            Comenzar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  const search = (value) => {
    if (!value) {
      setTasks(initialTasks);
    }

    // Búsqueda por número de folio
    if (isNumeric(value) && !rutEsValido(value)) {
      const results = initialTasks.filter((task) => {
        return parseInt(value) === task.folio_ticket;
      });

      setTasks(results);
    }

    // Búsqueda por RUT
    if (rutEsValido(value)) {
      const results = initialTasks.filter((task) => {
        return value === task.body_ticket.asegurado.body_contacto.rut;
      });

      setTasks(results);
    }

    // Búsqueda por nombre del asegurado
    if (value && !isNumeric(value) && !rutEsValido(value)) {
      const results = initialTasks.filter((task) => {
        return task.body_ticket.asegurado.nombre_contacto
          .toLowerCase()
          .includes(value.toLowerCase());
      });

      setTasks(results);
    }
  };

  return (
    <>
      <DialogOpenTask />

      <AppBar position="sticky">
        <Toolbar>
          {onDrawerOpen && (
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              onClick={onDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}

          {showSearchBar && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                id="search-bar"
                autoFocus
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "buscador" }}
                inputRef={searchRef}
                onKeyDown={(e) => {
                  e.keyCode === 13 && search(e.target.value);
                }}
                onBlur={(e) => {
                  search(e.target.value);
                  setShowSearchBar(false);
                }}
              />
            </div>
          )}

          {!showSearchBar && (
            <>
              <Typography className={classes.title} variant="h6" component="p">
                {`Tareas - ${title}`}
              </Typography>

              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
                onClick={() => fetchTasks()}
              >
                <RefreshIcon />
              </IconButton>

              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
                onClick={() => {
                  setShowSearchBar(!showSearchBar);
                  setTimeout(() => {
                    searchRef.current.focus();
                  }, [100]);
                }}
              >
                <SearchIcon />
              </IconButton>

              <IconButton
                className={classes.filterButton}
                edge="end"
                color="inherit"
                onClick={openFilterMenu}
              >
                <FilterListIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openFilter}
                onClose={closeFilterMenu}
              >
                {tasks_filter.map((item, index) => {
                  const labelId = `checkbox-list-label-${item.key}`;

                  return (
                    <div key={`tasks-menu-item-${item.key}`}>
                      <MenuItem onClick={handleToggle(item)}>
                        {item.checkbox && (
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(item) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={item.label} />
                      </MenuItem>
                      {!item.checkbox && <Divider variant="fullWidth" />}
                    </div>
                  );
                })}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      {loading ? (
        <Loading variant="list" listSubText={3} />
      ) : (
        <List className={classes.list}>
          {tasks.map((item, i) => (
            <TaskListItem
              key={`task-item-${i}`}
              item={item}
              onClick={() => {
                setSelectedTask(item);
                setShowDialog(true);
              }}
            />
          ))}
        </List>
      )}
    </>
  );
};

export default TaskListView;
