import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import React, { useReducer, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileUploader from "../../common/FileUploader";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import SelectBank from "../../common/SelectBank";
import SelectBankAccount from "../../common/SelectBankAccount";
import { formatRut, rutEsValido, unformatRut } from "../../../functions";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { API } from "aws-amplify";
import { AppContext } from "../../App";

const useStyles = makeStyles(theme => ({
  alignRight: {
    "@media (min-width: 600px)": {
      textAlign: "right"
    }
  },
  textRight: {
    paddingRight: "31px !important"
  },
  marginTop: {
    marginTop: "20px"
  }
}));

const PagarServicios = ({ task, data, setData, setShowDialog }) => {
  const [sending, setSending] = useState(false);
  const [updated, setUpdated] = useState(false);
  const classes = useStyles();
  const { user } = useContext(AppContext);

  const prestadorData = () => {
    const {
      prestador: {
        nombre_contacto: nombre,
        body_contacto: {
          datos_bancarios: { rut_cuenta: rut, numero_cuenta, banco, tipo_cuenta }
        }
      }
    } = task.body_ticket.gestionar_asistencia;

    const tipo = "Prestador";

    return { rut, numero_cuenta, nombre, banco, tipo_cuenta, tipo };
  };

  const beneficiarioData = () => {
    const {
      datos_bancarios: { numero_cuenta, rut_cuenta: rut, banco, tipo_cuenta }
    } = task.body_ticket.liquidar_siniestro;

    const nombre = null;
    const tipo = null;

    return { rut, numero_cuenta, nombre, banco, tipo_cuenta, tipo };
  };

  const red = () => {
    return task.body_ticket.gestionar_asistencia.red;
  };

  const initialState = {
    archivos: [],
    modify: "no",
    rut_cuenta: red() ? beneficiarioData().rut : prestadorData().rut,
    banco: red() ? beneficiarioData().banco : prestadorData().banco,
    tipo_cuenta: red() ? beneficiarioData().tipo_cuenta : prestadorData().tipo_cuenta,
    numero_cuenta: red() ? beneficiarioData().numero_cuenta : prestadorData().numero_cuenta,
    error: {
      archivos: false,
      rut_cuenta: false,
      banco: false,
      tipo_cuenta: false,
      numero_cuenta: false
    }
  };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "modify":
      case "rut_cuenta":
      case "banco":
      case "tipo_cuenta":
      case "numero_cuenta":
        return {
          ...state,
          [type]: payload
        };
      case "file_add":
        return {
          ...state,
          archivos: payload
        };
      case "file_remove":
        return {
          ...state,
          archivos: payload
        };
      case "error":
        return {
          ...state,
          error: { ...state.error, [payload]: true }
        };
      case "noError":
        return {
          ...state,
          error: { ...state.error, [payload]: false }
        };
      default:
        return { ...initialState };
    }
  };

  const [datos, dispatch] = useReducer(reducer, initialState);

  const handleAdd = newFile => {
    if (!datos.archivos) {
      dispatch({ type: "file_add", payload: [newFile] });
    } else {
      dispatch({ type: "file_add", payload: [...datos.archivos, newFile] });
    }
  };

  const handleDelete = removeFile => {
    dispatch({ type: "file_remove", payload: datos.archivos.filter(file => file !== removeFile) });
  };

  const handleButtonClick = e => {
    const updatedData = {
      ...data,
      archivos: datos.archivos
    };

    setData(updatedData);

    validate() && setShowDialog(true);
  };

  const showPrestador = () => {
    if (updated) {
      return showData({
        monto: task.body_ticket.liquidar_siniestro.monto,
        rut: datos.rut_cuenta,
        numero_cuenta: datos.numero_cuenta,
        nombre: task.body_ticket.gestionar_asistencia.prestador.nombre_contacto,
        banco: datos.banco,
        tipo_cuenta: datos.tipo_cuenta,
        tipo: "Prestador"
      });
    } else {
      return showData(prestadorData());
    }
  };

  const showBeneficiario = () => {
    if (updated) {
      return showData({
        monto: task.body_ticket.liquidar_siniestro.monto,
        rut: datos.rut_cuenta,
        numero_cuenta: datos.numero_cuenta,
        nombre: null,
        banco: datos.banco,
        tipo_cuenta: datos.tipo_cuenta,
        tipo: null
      });
    } else {
      return showData(beneficiarioData());
    }
  };

  const updateTicketBody = async body => {
    try {
      const init = {
        body: {
          id: task.id_ticket,
          body: JSON.stringify(body),
          actividad: null,
          responsable: user.id_usuario
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/tickets/body", init);

      if (result.success) {
        const actividad = JSON.parse(result.data.actividad);

        return { actividad };
      }
    } catch (error) {
      console.error("updateTicketBody", error);
    }

    return false;
  };

  const updateContact = async contacto => {
    try {
      const init = {
        body: {
          contacto,
          usuario: user.id_usuario
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/contactos", init);

      if (result.error) throw result.error;

      if (result.success) {
        return result.data;
      }
    } catch (err) {
      console.error("updateContact", err);
    }

    return false;
  };

  const updatePrestador = async () => {
    if (validateContact()) {
      setSending(true);

      const { prestador } = task.body_ticket.gestionar_asistencia;

      const contacto = {
        ...prestador,
        body_contacto: {
          ...prestador.body_contacto,
          datos_bancarios: {
            banco: datos.banco,
            numero_cuenta: datos.numero_cuenta,
            rut_cuenta: datos.rut_cuenta,
            tipo_cuenta: datos.tipo_cuenta
          }
        }
      };

      const updatedContact = await updateContact(contacto);

      if (updatedContact) {
        const ticket_body = await updateTicketBody({
          ...task.body_ticket,
          gestionar_asistencia: {
            ...task.body_ticket.gestionar_asistencia,
            prestador: contacto
          }
        });

        if (ticket_body) {
          setUpdated(true);
          dispatch({ type: "modify", payload: "no" });
        }
      }

      setSending(false);
    }
  };

  const updateBeneficiario = async () => {
    if (validateContact()) {
      setSending(true);

      /**
       * Comentado mientras se define el caso SIN PRESTADOR EN AREA
       */

      // const { beneficiario } = task.body_ticket;

      // const contacto = {
      //   ...beneficiario,
      //   body_contacto: {
      //     ...beneficiario.body_contacto,
      //     datos_bancarios: {
      //       banco: datos.banco,
      //       numero_cuenta: datos.numero_cuenta,
      //       rut_cuenta: datos.rut_cuenta,
      //       tipo_cuenta: datos.tipo_cuenta
      //     }
      //   }
      // };

      // const updatedContact = await updateContact(contacto);

      // if (updatedContact) {
      //   const ticket_body = await updateTicketBody({
      //     ...task.body_ticket,
      //     beneficiario: contacto
      //   });

      //   if (ticket_body) {
      //     setUpdated(true);
      //     dispatch({ type: "modify", payload: "no" });
      //   }
      // }

      setSending(false);
    }
  };

  const editForm = () => {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="rut_cuenta"
            key="rut_cuenta"
            size="small"
            variant="outlined"
            label="Rut cuenta *"
            placeholder="Ej: 12.345.678-9"
            InputLabelProps={{
              shrink: true
            }}
            value={formatRut(datos.rut_cuenta)}
            disabled={sending}
            onChange={({ target }) => {
              dispatch({ type: "rut_cuenta", payload: unformatRut(target.value) });
            }}
            onBlur={() => dispatch({ type: "rut_cuenta", payload: datos.rut_cuenta })}
            error={datos.error.rut_cuenta}
            helperText={datos.error.rut_cuenta && "Debe ingresar un Rut valido"}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectBank
            fullWidth
            className="control"
            size="small"
            variant="outlined"
            value={datos.banco}
            disabled={sending}
            onChange={value => dispatch({ type: "banco", payload: value })}
            onBlur={() => dispatch({ type: "banco", payload: datos.banco })}
            error={datos.error.banco}
            helperText={datos.error.banco && "Debe seleccionar un banco"}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectBankAccount
            fullWidth
            className="control"
            size="small"
            variant="outlined"
            value={datos.tipo_cuenta}
            disabled={sending}
            onChange={value => dispatch({ type: "tipo_cuenta", payload: value })}
            onBlur={() => dispatch({ type: "tipo_cuenta", payload: datos.tipo_cuenta })}
            error={datos.error.tipo_cuenta}
            helperText={datos.error.tipo_cuenta && "Debe seleccionar un tipo de cuenta"}
          />
        </Grid>

        <Grid item xs={12}>
          <NumberFormat
            fullWidth
            id="bank_account_number"
            customInput={TextField}
            size="small"
            variant="outlined"
            type="tel"
            label="Numero de cuenta *"
            placeholder="Ej: 123456789"
            InputLabelProps={{
              shrink: true
            }}
            value={datos.numero_cuenta}
            disabled={sending}
            onValueChange={({ value }) => dispatch({ type: "numero_cuenta", payload: value })}
            onBlur={() => dispatch({ type: "numero_cuenta", payload: datos.numero_cuenta })}
            error={datos.error.numero_cuenta}
            helperText={datos.error.numero_cuenta && "Debe ingresar un numero de cuenta"}
          />
        </Grid>

        <Grid item xs={12} className={classes.alignRight}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              disabled={sending}
              onClick={red() ? updateBeneficiario : updatePrestador}
            >
              <ImportExportIcon /> {red() ? "Actualizar beneficiario" : "Actualizar prestador"}
            </Button>
          </Hidden>
          <Hidden smDown>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={sending}
              onClick={red() ? updateBeneficiario : updatePrestador}
            >
              <ImportExportIcon /> {red() ? "Actualizar beneficiario" : "Actualizar prestador"}
            </Button>
          </Hidden>
        </Grid>
      </>
    );
  };

  const showData = bankData => {
    const { rut, numero_cuenta, nombre, banco, tipo_cuenta, tipo } = bankData;

    return (
      <>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>RUT:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.alignRight}>
          <Typography variant="body1">
            {formatRut(rut)}
            <Tooltip title={"Copiar"} placement="right">
              <CopyToClipboard text={rut}>
                <IconButton size={"small"}>
                  <FileCopyOutlinedIcon size={"small"} />
                </IconButton>
              </CopyToClipboard>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Número de cuenta:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.alignRight}>
          <Typography variant="body1">
            {numero_cuenta}
            <Tooltip title={"Copiar"} placement="right">
              <CopyToClipboard text={numero_cuenta}>
                <IconButton size={"small"}>
                  <FileCopyOutlinedIcon size={"small"} />
                </IconButton>
              </CopyToClipboard>
            </Tooltip>
          </Typography>
        </Grid>
        {tipo && nombre && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>{tipo}:</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.alignRight}>
              <Typography variant="body1" className={classes.textRight}>
                {nombre}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Banco:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.alignRight}>
          <Typography variant="body1" className={classes.textRight}>
            {banco.label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Tipo de cuenta:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.alignRight}>
          <Typography variant="body1" className={classes.textRight}>
            {tipo_cuenta.label}
          </Typography>
        </Grid>
      </>
    );
  };

  const validate = () => {
    !datos.archivos || datos.archivos.length === 0
      ? dispatch({ type: "error", payload: "archivos" })
      : dispatch({ type: "noError", payload: "archivos" });

    return datos.archivos.length > 0 && datos.modify === "no";
  };

  const validateContact = () => {
    if (datos.modify === "si") {
      if (!rutEsValido(datos.rut_cuenta)) dispatch({ type: "error", payload: "rut_cuenta" });

      if (!datos.banco) dispatch({ type: "error", payload: "banco" });

      if (!datos.tipo_cuenta) dispatch({ type: "error", payload: "tipo_cuenta" });

      if (!datos.numero_cuenta || datos.numero_cuenta === "")
        dispatch({ type: "error", payload: "numero_cuenta" });

      return (
        rutEsValido(datos.rut_cuenta) &&
        datos.banco !== null &&
        datos.tipo_cuenta !== null &&
        datos.numero_cuenta &&
        datos.numero_cuenta.length > 2
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Monto a pagar
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Total:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.alignRight}>
          <Typography variant="body1" component="p">
            <NumberFormat
              prefix={"$ "}
              displayType={"text"}
              thousandSeparator="."
              decimalSeparator=","
              value={task.body_ticket.liquidar_siniestro.monto}
            />
            <Tooltip title={"Copiar"} placement="right">
              <CopyToClipboard text={task.body_ticket.liquidar_siniestro.monto}>
                <IconButton size={"small"}>
                  <FileCopyOutlinedIcon size={"small"} />
                </IconButton>
              </CopyToClipboard>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Modificar datos bancarios
          </Typography>
          <RadioGroup
            value={datos.modify}
            onChange={({ target }) => {
              dispatch({ type: "modify", payload: target.value });
            }}
          >
            <FormControlLabel className="mb-0" value={"no"} control={<Radio />} label="No" />
            <FormControlLabel value={"si"} control={<Radio />} label="Si" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Datos bancarios
          </Typography>
        </Grid>
        {datos.modify === "si" ? editForm() : red() ? showBeneficiario() : showPrestador()}
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <Typography variant="h6" component="p">
          Comprobantes
        </Typography>
        <FileUploader
          parent="pagar-servicios"
          data={datos.archivos}
          maxFiles={8}
          onAdd={handleAdd}
          onRemove={handleDelete}
          error={datos.error.archivos}
          helperText={datos.error.archivos && "Debe subir al menos un archivo"}
        />
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <Hidden mdUp>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleButtonClick}
          >
            Aceptar
          </Button>
        </Hidden>
        <Hidden smDown>
          <Container maxWidth="xs">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Container>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default PagarServicios;
