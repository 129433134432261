import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import ContactDetailView from "../../components/contacts/ContactDetailView";
import ContactsListView from "../../components/contacts/ContactsListView";
import { MobileContext } from "../Mobile";

const drawerWidth = "100vw";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  filterButton: {
    marginLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fafafa"
  }
}));

const Contacts = () => {
  const classes = useStyles();
  const { onDrawerOpen } = useContext(MobileContext);
  const [contact, setContacts] = useState(null);

  const selectContacts = contact => {
    setContacts(contact);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        open={Boolean(contact)}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <ContactDetailView mobile contact={contact} onClose={() => selectContacts(null)} />
      </Drawer>

      <ContactsListView mobile onClick={selectContacts} onDrawerOpen={onDrawerOpen} />
    </>
  );
};

export default Contacts;
