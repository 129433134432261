import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import { formatRut, formatDate } from "../../../../functions";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0)
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

const ContentCardInsured = ({ mobile, asegurado }) => {
  const classes = useStyles();

  return (
    <Card className={!mobile && classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <PersonIcon color="disabled" />
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Nombre
          </Grid>
          <Grid item xs={6}>
            {asegurado.nombre_contacto}
          </Grid>
          <Grid item xs={6}>
            Rut
          </Grid>
          <Grid item xs={6}>
            {formatRut(asegurado.body_contacto.rut)}
          </Grid>
          {asegurado.body_contacto.fecha_nacimiento && (
            <>
              <Grid item xs={6}>
                Fecha de nacimiento
              </Grid>
              <Grid item xs={6}>
                {formatDate(asegurado.body_contacto.fecha_nacimiento)}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentCardInsured;
