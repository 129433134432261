/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:abbd025f-313f-4985-9c74-da8fc2cb944c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7Yh84rebf",
    "aws_user_pools_web_client_id": "3sleulbckipmn6ef4lnd9igend",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "apihubtest",
            "endpoint": "https://zqhioeqs0d.execute-api.us-east-1.amazonaws.com/sampledev",
            "region": "us-east-1"
        },
        {
            "name": "apihubtickettest",
            "endpoint": "https://b7gjc4qgb7.execute-api.us-east-1.amazonaws.com/sampledev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "serviclickhubtestbucket123105620-sampledev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
