import React, { useState, useEffect, useContext, useCallback } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import FileUploader from "../common/FileUploader";
import { API, Storage } from "aws-amplify";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Loading from "../common/Loading";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { AppContext } from "../App";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    minHeight: theme.spacing(4)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  },
  container: {
    overflow: "auto",
    height: "calc(100vh - 64px)",
    padding: 0
  },
  loadingText: {
    margin: theme.spacing(0, 0, 3, 0)
  },
  loading: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const ContactsRemove = ({ onDrawerOpen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [error, setError] = useState(false);
  const [file, setFile] = useState([]);
  const [account, setAccount] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [type, setType] = useState("");
  const [sent, setSent] = useState(false);
  const { user } = useContext(AppContext);

  const types = [
    {
      id: 0,
      label: "Asegurados",
      name: "asegurado"
    }
  ];

  const fetchCuentas = useCallback(async () => {
    try {
      setLoading(true);

      const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

      let updatedAccounts = [];

      if (result.success) {
        if (result.data) updatedAccounts = result.data;
      } else console.warn("fetchCuentas", result.error);

      setAccounts(updatedAccounts);
      setAccount(updatedAccounts[0]);
      setType(types[0].label);
    } catch (err) {
      console.error("fetchCuentas", err);
    } finally {
      setLoading(false);
    }
  }, [types]);

  useEffect(() => {
    if (accounts.length === 0) {
      fetchCuentas();
    }
  }, [accounts, fetchCuentas]);

  const handleAdd = (newFile) => {
    setFile([newFile]);
  };

  const handleDelete = () => {
    setFile([]);
  };

  const processCsv = async ({ key, account, slug, type }) => {
    setLoadingMessage("Procesando contactos...");

    try {
      const init = {
        body: { key, account, slug, type, user }
      };

      const result = await API.put(
        process.env.REACT_APP_HUB_API,
        "/contactos/remove_asegurados",
        init
      );

      if (result.success) {
        console.log(result);
      } else {
        setError(true);
        console.log(result.error);
      }
    } catch (err) {
      // setError(true);
      console.error("contactsUpload.processCsv", err);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    setLoadingMessage("Cargando archivo...");

    const selectedType = types.find((item) => item.label === type);

    let csv = file[0];
    let config = {
      level: "public",
      contentType: csv.fileType
    };

    csv.name = `latest${csv.extension}`;
    csv.accountId = account.id_cuenta;
    csv.key = `${csv.accountId}/contacts/${csv.name}`;

    if (csv.extension !== ".csv") {
      setError(true);
      setLoading(false);
      return null;
    }

    await Storage.put(csv.key, csv.blob, config)
      .then((result) =>
        processCsv({
          key: result.key,
          account: csv.accountId,
          slug: account.slug_cuenta,
          type: selectedType.name
        })
      )
      .catch((err) => {
        setError(true);
        console.log(err);
      })
      .finally(() => {
        setSent(true);
      });

    setLoadingMessage(null);
    setLoading(false);
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {onDrawerOpen && (
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              onClick={onDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h6" component="p">
            Carga de contactos
          </Typography>
        </Toolbar>
      </AppBar>
      {loading ? (
        <Container className={classes.container}>
          {loadingMessage ? (
            <>
              <Container maxWidth="sm" className={classes.loading}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="p"
                      className={classes.loadingText}
                    >
                      {loadingMessage}
                    </Typography>
                    <LinearProgress />
                  </CardContent>
                </Card>
              </Container>
            </>
          ) : (
            <Loading />
          )}
        </Container>
      ) : (
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} sm={10}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.cardTitle}>
                  <Typography>Carga de contactos</Typography>
                </div>

                <Divider className={classes.divider} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="p">
                      Seleccione una cuenta
                    </Typography>
                    <Select
                      fullWidth
                      classes={{ selectMenu: classes.select }}
                      variant="outlined"
                      value={account}
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                    >
                      {accounts.map((item) => (
                        <MenuItem key={`cuenta-${item.id_cuenta}`} value={item}>
                          {item.alias_cuenta}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="p">
                      Seleccione un tipo de contacto
                    </Typography>
                    <Select
                      fullWidth
                      classes={{ selectMenu: classes.select }}
                      variant="outlined"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      {types.map((item) => (
                        <MenuItem key={`tipo-${item.id}`} value={item.label}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  {error ? (
                    <Grid item xs={12}>
                      <Alert variant="filled" severity="error">
                        Ocurrió un error en la carga de contactos. Por favor
                        contacte al administrador.
                      </Alert>
                    </Grid>
                  ) : (
                    <>
                      {sent ? (
                        <Grid item xs={12}>
                          <Alert variant="filled" severity="success">
                            La carga se realizó exitosamente.
                          </Alert>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <FileUploader
                              parent="contacts"
                              data={file}
                              maxFiles={1}
                              onAdd={handleAdd}
                              onRemove={handleDelete}
                              type="csv"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Hidden mdUp>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                fullWidth
                                onClick={handleUpload}
                                disabled={sent}
                              >
                                Aceptar
                              </Button>
                            </Hidden>
                            <Hidden smDown>
                              <Container maxWidth="xs">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="large"
                                  fullWidth
                                  onClick={handleUpload}
                                  disabled={sent}
                                >
                                  Aceptar
                                </Button>
                              </Container>
                            </Hidden>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ContactsRemove;
