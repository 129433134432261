import React, { useMemo } from "react";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { formatRut } from "../../functions";

const TaskListItem = ({ item, onClick }) => {
  const { logo, ItemText } = useMemo(() => {
    let logo = null;
    let ItemText = null;

    switch (item.slug_cuenta) {
      case "banigualdad":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_banigualdad.png`;
        ItemText = (
          <>
            {`${item.nombre_ticket} ${item.alias_cuenta}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "vias-chile":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_viaschile.png`;
        ItemText = (
          <>
            {`${item.nombre_ticket} ${item.alias_cuenta}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "autofin":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_autofin.png`;
        ItemText = (
          <>
            {`${item.nombre_ticket} ${item.alias_cuenta}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "seguros-ripley":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_ripley.png`;
        ItemText = (
          <>
            {`${item.nombre_ticket} ${item.alias_cuenta}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "BCI Urgencia":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_bci.png`;
        ItemText = (
          <>
            {`${item.nombre_ticket} - ${item.config_cuenta.descripcion}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "banco-estado-hogar":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_banco-estado.jpg`;
        ItemText = (
          <>
            {`${item.nombre_ticket} - ${item.config_cuenta.descripcion}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "asistencia-apoyo-medico":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_apoyo-medico.jpg`;
        ItemText = (
          <>
            {`${item.nombre_ticket} - ${item.config_cuenta.descripcion}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "ripley-sura":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_ripley-sura.jpg`;
        ItemText = (
          <>
            {`${item.nombre_ticket} - ${item.config_cuenta.descripcion}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      case "southbridge-mascota":
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_southbridge-mascota.jpg`;
        ItemText = (
          <>
            {`${item.nombre_ticket} - ${item.config_cuenta.descripcion}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Nombre:
            </Typography>
            {` ${item.body_ticket.asegurado.nombre_contacto}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Rut:
            </Typography>
            {` ${formatRut(item.body_ticket.asegurado.body_contacto.rut)}`}
            <br />
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
      default:
        logo = `${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`;
        ItemText = (
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              Caso N°:
            </Typography>
            {` ${item.folio_ticket}`}
          </>
        );
        break;
    }

    return { logo, ItemText };
  }, [item]);

  return (
    <>
      <ListItem button alignItems="flex-start" onClick={onClick}>
        <ListItemAvatar>
          <Avatar src={logo} />
        </ListItemAvatar>
        <ListItemText primary={item.nombre_tarea} secondary={ItemText} />
      </ListItem>
      <Divider variant="middle" />
    </>
  );
};

export default TaskListItem;
