import React from "react";
import NumberFormat from "react-number-format";
import { formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Conditions from "../../../common/Conditions";
import { METODOS_PAGO } from "../constants";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0)
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

const ContentCardDetails = ({ mobile, ticket }) => {
  const classes = useStyles();

  return (
    <Card className={!mobile && classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <AssignmentIcon color="disabled" />
        </div>

        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          {ticket.usuario && (
            <>
              <Grid item xs={6}>
                Ejecutivo
              </Grid>
              <Grid item xs={6}>
                {`${ticket.usuario.primer_nombre_usuario} ${ticket.usuario.apellido_paterno_usuario}`}
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            Fecha de creación
          </Grid>
          <Grid item xs={6}>
            {formatRelative(new Date(ticket.body_ticket.fecha), Date.now(), {
              locale: es
            })}
          </Grid>
          <Grid item xs={6}>
            Servicio
          </Grid>
          <Grid item xs={6}>
            {ticket.alias_cuenta}
            {(ticket.config_cuenta || {}).condiciones && (
              <Conditions condiciones={ticket.config_cuenta.condiciones} />
            )}
          </Grid>
          <Grid item xs={6}>
            Monto solicitado
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              value={ticket.body_ticket.monto}
              thousandSeparator="."
              decimalSeparator=","
              prefix="$"
              displayType={"text"}
            />
          </Grid>
          {ticket.body_ticket.evaluar_siniestro && ticket.body_ticket.evaluar_siniestro.monto && (
            <>
              <Grid item xs={6}>
                Monto aprobado
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  value={ticket.body_ticket.evaluar_siniestro.monto}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="$"
                  displayType={"text"}
                />
              </Grid>
            </>
          )}
          {ticket.body_ticket.liquidar_siniestro && ticket.body_ticket.liquidar_siniestro.monto && (
            <>
              <Grid item xs={6}>
                Monto liquidado
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  value={ticket.body_ticket.liquidar_siniestro.monto}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="$"
                  displayType={"text"}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            Método de pago
          </Grid>
          <Grid item xs={6}>
            {METODOS_PAGO[ticket.body_ticket.opcionPago.metodo].label}
          </Grid>
          {ticket.body_ticket.opcionPago.metodo ===
            METODOS_PAGO["transferencia-bancaria"].value && (
            <>
              <Grid item xs={6}>
                Cuenta bancaria
              </Grid>
              <Grid item xs={6}>
                {ticket.body_ticket.opcionPago.banco.label}
                <br />
                {ticket.body_ticket.opcionPago.tipoCuenta.label}
                <br />
                {ticket.body_ticket.opcionPago.numeroCuenta}
              </Grid>
            </>
          )}
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              Descripción
            </Typography>
            {ticket.body_ticket.descripcion}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentCardDetails;
