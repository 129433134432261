import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import React, { useEffect, useState, useContext } from "react";
import AppBarCreate from "../common/AppBarCreate";
import Loading from "../common/Loading";
import CreateContactBanigualdad from "./Banigualdad/ContactCreate";
import BaseCreateContact from "./base/ContactCreate";
import CreateContactBCIUrgencias from "./BCI Urgencia/ContactCreate";
import { AppContext } from "../App";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100vw"
  },
  container: {
    paddingTop: theme.spacing(2)
  },
  card: {
    margin: theme.spacing(2, 0)
  },
  pos: {
    marginBottom: theme.spacing(1)
  }
}));

export const createContact = async (tipo, name, body, tags, clientId, accountId, userId = null) => {
  let contacto = null;

  try {
    const init = {
      body: {
        tipo,
        origen: "Hub",
        nombre: name,
        body: JSON.stringify(body),
        cliente: clientId,
        cuenta: accountId,
        id_usuario_crea: userId
      }
    };

    if (tags) {
      init.body.tags = JSON.stringify(tags);
    }

    const result = await API.post(process.env.REACT_APP_HUB_API, "/contactos", init);

    if (result.error) throw result.error;

    if (result.success) {
      contacto = result.data;
    }
  } catch (err) {
    console.error("createContact", err);
  }

  return contacto;
};

export const addContactAddress = async (address, contactId) => {
  try {
    const init = {
      body: {
        id: contactId,
        direccion: JSON.stringify(address)
      }
    };
    const result = await API.post(process.env.REACT_APP_HUB_API, "/contactos/direccion", init);

    if (result.error) throw result.error;

    return result.success;
  } catch (err) {
    console.error("addContactAddress", err);
  }

  return false;
};

const ContactCreate = ({ mobile, open, onClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { user } = useContext(AppContext);

  useEffect(() => {
    const fetchCuentas = async () => {
      try {
        setLoading(true);

        const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

        let accounts = [];

        if (result.success) {
          if (result.data) accounts = result.data;
        } else console.warn("fetchCuentas", result.error);

        setAccounts(accounts);
      } catch (err) {
        console.error("fetchCuentas", err);
      } finally {
        setLoading(false);
      }
    };

    fetchCuentas();
  }, []);

  const create = async (type, name, body, tags, clientId, accountId) => {
    setSending(true);

    const contacto = await createContact(
      type,
      name,
      body,
      tags,
      clientId,
      accountId,
      user.id_usuario
    );

    if (contacto) {
      // TODO luego de crear enviar a detalle contacto
      //onCreated(cuenta, contacto.id_contacto);
      onClose();
    }

    setSending(false);
  };

  const ContactCreateAccount = () => {
    switch (selectedAccount.slug_cuenta) {
      case "banigualdad":
        return (
          <CreateContactBanigualdad
            mobile={mobile}
            account={selectedAccount}
            accounts={accounts}
            sending={sending}
            onBack={() => setSelectedAccount(null)}
            onClose={onClose}
            onCreate={create}
          />
        );
      case "BCI Urgencia":
        return (
          <CreateContactBCIUrgencias
            mobile={mobile}
            account={selectedAccount}
            accounts={accounts}
            sending={sending}
            onBack={() => setSelectedAccount(null)}
            onClose={onClose}
            onCreate={create}
          />
        );
      default:
        return (
          <BaseCreateContact
            mobile={mobile}
            account={selectedAccount}
            accounts={accounts}
            sending={sending}
            onBack={() => setSelectedAccount(null)}
            onClose={onClose}
            onCreate={create}
          />
        );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.paper
      }}
    >
      {!selectedAccount ? (
        <>
          <AppBarCreate title="Nuevo Contacto" onClose={onClose} />
          {loading ? (
            <Loading />
          ) : (
            <Container className={classes.container} maxWidth="sm">
              <Typography variant="h6" component="p">
                Seleccionar cuenta
              </Typography>
              {accounts
                .filter(account => account.config_cuenta.creaTicket)
                .map((account, i) => (
                  <Card key={`cuenta-${i}`} className={classes.card} variant="outlined">
                    <CardActionArea onClick={() => setSelectedAccount(account)}>
                      <CardContent>
                        <Typography variant="h6" component="p" align="center">
                          {account.alias_cuenta}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary" align="center">
                          {account.config_cuenta.descripcion}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
            </Container>
          )}
        </>
      ) : (
        <ContactCreateAccount />
      )}
    </Drawer>
  );
};

export default ContactCreate;
