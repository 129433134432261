import Grid from "@material-ui/core/Grid";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactsRemove from "../../components/contacts/ContactsRemove";
import { MobileContext } from "../Mobile";

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "hidden"
  }
}));

const Removal = () => {
  const { onDrawerOpen } = useContext(MobileContext);
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.overflowHidden}>
      <Grid item xs={12}>
        <ContactsRemove mobile onDrawerOpen={onDrawerOpen} />
      </Grid>
    </Grid>
  );
};

export default Removal;
