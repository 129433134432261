import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { getAwsAuthException } from "./authFunctions";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  logo: {
    width: "65%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  alertMsg: {
    margin: theme.spacing(2, 0)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: theme.spacing(6)
  }
}));

const CompleteNewPassword = props => {
  const { authState, authData, onStateChange } = props;

  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMsg] = useState({ open: false, type: "error", msg: "" });

  const reset = () => {
    setOldPassword("");
    setOldPasswordErrorMessage("");

    setPassword("");
    setPasswordErrorMessage("");

    setConfirmPassword("");
    setConfirmPasswordErrorMessage("");

    setAlertMsg({ ...alertMessage, open: false, msg: "" });
  };

  const validate = () => {
    let result = true;

    if (!oldPassword || oldPassword === "") {
      setOldPasswordErrorMessage("La contraseña antigua no puede estar vacía");
      result = false;
    } else if (oldPassword.length < 8) {
      setOldPasswordErrorMessage("La contraseña antigua debe tener al menos 8 caracteres");
      result = false;
    } else {
      setOldPasswordErrorMessage("");
    }

    if (!password || password === "") {
      setPasswordErrorMessage("La nueva contraseña no puede estar vacía");
      result = false;
    } else if (password.length < 8) {
      setPasswordErrorMessage("La nueva contraseña debe tener al menos 8 caracteres");
      result = false;
    } else {
      setPasswordErrorMessage("");
    }

    if (!confirmPassword || confirmPassword === "") {
      setConfirmPasswordErrorMessage("La confirmación no puede estar vacía");
      result = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage("La nueva contraseña y su confirmación no coinciden");
      result = false;
    } else {
      setConfirmPasswordErrorMessage("");
    }

    return result;
  };

  const completeNewPassword = async e => {
    e.preventDefault();
    setAlertMsg({ ...alertMessage, open: false, msg: "" });

    if (validate()) {
      setLoading(true);

      const username = authData.challengeParam.userAttributes.email;

      try {
        let user = await Auth.signIn(username, oldPassword);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(user, password);

          reset();
          setLoading(false);
          // TODO: Doing nothing because we here already is state "signedIn"
          ///onStateChange("signedIn", user);
          // TODO: Force on the signIn page for now
          onStateChange("signIn", user);
        }
      } catch (err) {
        setLoading(false);
        const msg = getAwsAuthException(err.code, err.message);
        setAlertMsg({
          open: true,
          type: "error",
          msg
        });
      }
    }
  };

  const gotoSignIn = () => {
    reset();
    onStateChange("signIn");
  };

  if (authState !== "signedIn" || !authData || authData.challengeName !== "NEW_PASSWORD_REQUIRED") {
    return null;
  }

  return (
    <Grid container component="main" justify="center" className={`auth ${classes.root}`}>
      <Grid item xs={12} sm={8} md={5} lg={3}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <img
            className={classes.logo}
            src={process.env.PUBLIC_URL + "/assets/images/logo_serviclick.png"}
            alt="home-logo"
          />
          <Typography component="h1" variant="h5" align="center">
            Cambia tu contraseña
          </Typography>

          <form
            className={classes.form}
            autoComplete="off"
            noValidate
            onSubmit={completeNewPassword}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Collapse in={alertMessage.open}>
                  <Alert
                    className={classes.alertMsg}
                    variant="filled"
                    severity={alertMessage.type}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setAlertMsg({ ...alertMessage, open: false })}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {alertMessage.msg}
                  </Alert>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña actual"
                  type="password"
                  id="oldPassword"
                  onChange={({ target }) => setOldPassword(target.value)}
                  disabled={loading}
                  value={oldPassword}
                  error={oldPasswordErrorMessage ? true : false}
                  helperText={oldPasswordErrorMessage}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">
                  Recuerda usar 8 o más caracteres y combinar letras y números.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Nueva contraseña"
                  type="password"
                  id="password"
                  onChange={({ target }) => setPassword(target.value)}
                  disabled={loading}
                  value={password}
                  error={passwordErrorMessage ? true : false}
                  helperText={passwordErrorMessage}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirmar nueva contraseña"
                  type="password"
                  id="confirmPassword"
                  onChange={({ target }) => setConfirmPassword(target.value)}
                  disabled={loading}
                  value={confirmPassword}
                  error={confirmPasswordErrorMessage ? true : false}
                  helperText={confirmPasswordErrorMessage}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              className={classes.submit}
              disabled={loading}
            >
              {!loading ? "Cambiar contraseña" : <CircularProgress size={30} />}
            </Button>

            <Button className={classes.forgot} color="primary" onClick={gotoSignIn}>
              Cancelar
            </Button>
          </form>
        </div>
        <Box mt={5} mb={1}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompleteNewPassword;
