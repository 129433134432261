import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import Detail from "./Detail";
import TicketHistory from "../tickets/TicketHistory";
import Task from "./Task";

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  h100: {
    height: "calc(100vh - 125px)",
    padding: 0
  },
  tabPanel: {
    overflow: "auto"
  }
}));

const TaskView = ({ task, onClose, onSelectedTask, mobile }) => {
  const classes = useStyles();
  const [maintab, setMaintab] = useState(0);

  useEffect(() => {
    setMaintab(0);
  }, [task]);

  if (!task) {
    return null;
  }

  const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tarea-tab-${index}`}
        className={classes.h100}
        {...other}
      >
        {value === index && <div className={classes.h100}>{children}</div>}
      </div>
    );
  };

  const handleChange = (_, newValue) => {
    setMaintab(newValue);
  };

  return (
    <div className={classes.h100}>
      <AppBar position="sticky">
        <Toolbar>
          {onClose && (
            <IconButton
              className={classes.backButton}
              edge="start"
              color="inherit"
              onClick={onClose}
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          <Typography className={classes.title} variant="h6" component="p">
            {task.nombre_tarea}
          </Typography>

          <IconButton className={classes.searchButton} edge="end" color="inherit">
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container className={classes.h100}>
        <AppBar position="sticky">
          <Tabs value={maintab} variant="fullWidth" onChange={handleChange}>
            <Tab label="Tarea" />
            <Tab label="Detalle" />
            <Tab label="Historial" />
          </Tabs>
        </AppBar>

        <TabPanel className={classes.tabPanel} value={maintab} index={0}>
          <Task task={task} onSelectedTask={onSelectedTask} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={maintab} index={1}>
          <Detail task={task} mobile={mobile} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={maintab} index={2}>
          <TicketHistory ticket={task.id_ticket} />
        </TabPanel>
      </Container>
    </div>
  );
};

export default TaskView;
