import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import TicketsListView from "../../components/tickets/TicketsListView";
import TicketDetailView from "../../components/tickets/TicketDetailView";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "hidden"
  }
}));

const Tickets = () => {
  const [ticket, setTicket] = useState(null);
  const classes = useStyles();

  const selectTicket = ticket => {
    setTicket(ticket);
  };

  return (
    <Grid container spacing={0} className={classes.overflowHidden}>
      <Grid item xs={12} sm={4}>
        <TicketsListView onClick={selectTicket} />
      </Grid>

      <Grid item xs={12} sm={8}>
        <TicketDetailView ticket={ticket} />
      </Grid>
    </Grid>
  );
};

export default Tickets;
