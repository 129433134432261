import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ImageLoader from "../../../common/ImageLoader";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0)
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 125,
    [theme.breakpoints.down("sm")]: {
      minHeight: 80
    }
  },
  cardActionArea: {
    width: "auto",
    padding: theme.spacing(2)
  },
  imageLoader: {
    "& img": {
      objectFit: "cover",
      width: theme.spacing(10),
      height: theme.spacing(10)
    }
  },
  img: {
    objectFit: "cover",
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    }
  }
}));

const ContentCardFiles = ({ mobile, archivos, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={!mobile && classes.card}>
      <CardContent>
        <div className={classes.cardTitle}>
          <AttachFileIcon color="disabled" />
        </div>
        <Divider className={classes.divider} />
        <Grid container item xs={12} spacing={3}>
          {archivos && archivos.length > 0 ? (
            archivos.map((file, i) => (
              <Grid key={`file-${i}`} item xs={6} sm={3}>
                <Card className={classes.cardContent} variant="outlined">
                  <CardActionArea className={classes.cardActionArea} onClick={e => onClick(file)}>
                    {file.type.indexOf("audio") === 0 || file.type.indexOf("video") === 0 ? (
                      <PlayCircleFilledIcon style={{ fontSize: 50 }} />
                    ) : file.type.indexOf("application/pdf") === 0 ? (
                      <PictureAsPdfIcon style={{ fontSize: 50 }} />
                    ) : file.type.indexOf("application") === 0 ? (
                      <DescriptionIcon style={{ fontSize: 50 }} />
                    ) : file.type.indexOf("text") === 0 ? (
                      <DescriptionIcon style={{ fontSize: 50 }} />
                    ) : file.url ? (
                      <img
                        src={file.url}
                        alt={`file-${file.extension.substr(1)}-${i}`}
                        className={classes.img}
                      />
                    ) : (
                      <ImageLoader
                        imgKey={file.key}
                        bucket={file.bucket}
                        alt={`file-${file.extension.substr(1)}-${i}`}
                        className={classes.imageLoader}
                      />
                    )}
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              Sin archivos adjuntos
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContentCardFiles;
