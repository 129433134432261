import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import NoteIcon from "@material-ui/icons/Note";
import { API } from "aws-amplify";
import React, { useContext, useState } from "react";
import { AppContext } from "../../App";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  addButton: {
    textAlign: "right"
  }
}));

const Seguimiento = ({ task, data, setData, setShowDialog }) => {
  const [loading, setLoading] = useState(false);
  const [asistencia, setAsistencia] = useState(data.asistencia ? data.asistencia : "si");
  const [bitacora, setBitacora] = useState(
    task.body_tarea.bitacora ? task.body_tarea.bitacora : []
  );
  const [commentary, setCommentary] = useState("");
  const [error, setError] = useState({
    commentary: false
  });
  const { user } = useContext(AppContext);
  const classes = useStyles();

  const validate = () => {
    commentary.length >= 1000 || commentary.length === 0
      ? setError({ ...error, commentary: true })
      : setError({ ...error, commentary: false });

    if (commentary.length < 1000 && commentary.length > 0) {
      return true;
    }

    return false;
  };

  const updateTask = async updateBitacora => {
    setLoading(true);

    try {
      const init = {
        body: {
          id: task.id_tarea,
          body: JSON.stringify({
            ...task.body_tarea,
            ...data,
            asistencia,
            bitacora: updateBitacora
          }),
          estado: 1,
          actividad: null,
          ticket: task.id_ticket,
          responsable: user.id_responsable
        }
      };

      const result = await API.put(process.env.REACT_APP_HUB_API, "/tickets/tarea", init);

      setLoading(false);

      if (result.success) {
        return { actividad: result.data.actividad, tarea: result.data.tarea };
      } else {
        console.warn("updateTask", result.error);
      }
    } catch (error) {
      console.error("updateTask", error);
    }

    return false;
  };

  const handleButtonClick = e => {
    setData({
      ...data,
      asistencia,
      bitacora: bitacora
    });

    setShowDialog(true);
  };

  const handleAddCommentary = async () => {
    if (validate()) {
      const date = new Date().toLocaleString("es-CL");
      const updateBitacora = [
        ...bitacora,
        {
          id: bitacora.length + 1,
          date,
          commentary
        }
      ];

      setBitacora(updateBitacora);

      const isTaskUpdated = await updateTask(updateBitacora);

      if (isTaskUpdated) {
        setCommentary("");
        return true;
      }
    }

    return false;
  };

  const ShowBitacora = () => {
    if (bitacora && bitacora.length > 0) {
      const list = (
        <Grid item xs={12}>
          <List>
            {bitacora.map(item => {
              return (
                <ListItem key={`commentary-${item.id}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <NoteIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.commentary} secondary={item.date} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      );

      return list;
    }

    return null;
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {task.body_ticket.gestionar_asistencia.red === false && (
            <Grid item xs={12}>
              <Typography variant="h6" component="p">
                Asistencia prestada
              </Typography>
              <RadioGroup value={asistencia} onChange={({ target }) => setAsistencia(target.value)}>
                <FormControlLabel className="mb-0" value={"si"} control={<Radio />} label="Si" />
                <FormControlLabel value={"no"} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            Comentarios
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows="3"
            size="small"
            disabled={loading}
            value={commentary}
            onChange={({ target }) => setCommentary(target.value)}
            InputLabelProps={{
              shrink: true
            }}
            error={error.commentary}
            helperText={error.commentary && "El comentario no debe tener mas de 1000 caracteres"}
          />
        </Grid>
        <Grid item xs={12} className={classes.addButton}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
              disabled={loading || commentary === ""}
              onClick={handleAddCommentary}
            >
              <AddIcon /> Agregar comentario
            </Button>
          </Hidden>
          <Hidden smDown>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disabled={loading || commentary === ""}
              onClick={handleAddCommentary}
            >
              <AddIcon /> Agregar comentario
            </Button>
          </Hidden>
        </Grid>

        <ShowBitacora />

        <Grid item xs={12}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Confirmar asistencia
            </Button>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="xs">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleButtonClick}
              >
                Confirmar asistencia
              </Button>
            </Container>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Seguimiento;
