import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SearchComponent from "../../components/search/Search";
import ContactDetailView from "../../components/contacts/ContactDetailView";
import TicketDetailView from "../../components/tickets/TicketDetailView";
import { MobileContext } from "../Mobile";

const drawerWidth = "100vw";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fafafa"
  }
}));

const Search = () => {
  const classes = useStyles();
  const { onDrawerOpen } = useContext(MobileContext);
  const [contact, setContact] = useState(null);
  const [ticket, setTicket] = useState(null);

  const selectContact = contact => {
    setTicket(null);
    setContact(contact);
  };

  const selectTicket = ticket => {
    setContact(null);
    setTicket(ticket);
  };

  const onClose = () => {
    setContact(null);
    setTicket(null);
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        open={Boolean(contact) || Boolean(ticket)}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {contact && <ContactDetailView mobile contact={contact} onClose={onClose} />}
        {ticket && <TicketDetailView mobile ticket={ticket} onClose={onClose} />}
      </Drawer>

      <SearchComponent
        mobile
        selectContact={selectContact}
        selectTicket={selectTicket}
        onDrawerOpen={onDrawerOpen}
      />
    </>
  );
};

export default Search;
