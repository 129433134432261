import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  },
  closeButton: {
    marginLeft: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

const AppBarCreate = ({ title, disabled, onBack, onClose }) => {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        {onBack && (
          <IconButton
            edge="start"
            className={classes.backButton}
            color="inherit"
            aria-label="back"
            disabled={disabled}
            onClick={onBack}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h6" component="p" align="center" className={classes.title}>
          {title}
        </Typography>
        {onClose && (
          <IconButton
            edge="end"
            className={classes.closeButton}
            color="inherit"
            aria-label="close"
            disabled={disabled}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarCreate;
