import React from "react";
import InboxIcon from "@material-ui/icons/Inbox";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

export const DRAWER_KEYS = {
  search: "search",
  tasks: "tasks",
  tickets: "tickets",
  contacts: "contacts",
  reports: "reports",
  user: "user",
  logout: "logout"
};

export const drawer_main = [
  { key: DRAWER_KEYS.search, icon: <SearchIcon />, label: "Buscador" },
  { key: DRAWER_KEYS.tasks, icon: <InboxIcon />, label: "Tareas" },
  { key: DRAWER_KEYS.tickets, icon: <AssignmentIcon />, label: "Casos" },
  { key: DRAWER_KEYS.contacts, icon: <ContactMailIcon />, label: "Contactos" },
  { key: DRAWER_KEYS.reports, icon: <InsertChartIcon />, label: "Reportes" }
];

export const drawer_secondary = [
  { key: DRAWER_KEYS.user, icon: <PersonIcon />, label: "" },
  { key: DRAWER_KEYS.logout, icon: <PowerSettingsNewIcon />, label: "Cerrar sesión" }
];
