const asistencias = {
    'Salud Universal - Individual': [
        'Sala de Urgencia',
        'Atención Medica Ambulatoria',
        'Descuento en Farmacia',
        'Telemedicina',
        'Orientación Medica Telefónica',
    ],
    'Salud Universal - Familiar': [
        'Sala de Urgencia',
        'Atención Medica Ambulatoria',
        'Descuento en Farmacia',
        'Telemedicina',
        'Orientación Medica Telefónica',
    ],
    'Salud Universal Pro - Individual': [
        'Sala De Urgencia',
        'Atención Medica Ambulatoria',
        'Descuento en Farmacia',
        'Telemedicina',
        'Orientación Medica Telefónica',
        'Medico a Domicilio',
    ],
    'Salud Universal Pro - Familiar': [
        'Sala De Urgencia',
        'Atención Medica Ambulatoria',
        'Descuento en Farmacia',
        'Telemedicina',
        'Orientación Medica Telefónica',
        'Medico a Domicilio',
    ],
    'Bicicultura': [
        'Sala De Urgencia',
        'Descuento en Farmacia',
        'Telemedicina',
        'Orientación Medica Telefónica',
        'Asistencia Legal',
    ],
    'Salud Joven': [
        'Sala De Urgencia',
        'Descuento en farmacia',
        'Telemedicina',
        'Orientación Medica Telefónica',
    ],
    'Hogar': [
        'Servicio De Plomería',
        'Servicio De Electricidad',
        'Servicio De Cerrajería',
        'Servicio De Vidriería',
        'Instalaciones',
        'Perforaciones en Muro',
    ],
    'Hogar Pro': [
        'Servicio De Plomería',
        'Servicio De Electricidad',
        'Servicio De Cerrajería',
        'Servicio De Vidriería',
        'Instalaciones De Luminaria y/o Lampara',
        'Perforaciones en Muro',
        'Mano de obra en Pintura',
        'Instalación de Cortina',
    ],
    'Automovil': [
        'Neumático',
        'Amortiguador',
        'Vidrio Lateral',
        'Cerrajería',
        'Insignia',
    ],
    'Mascota Protegida': [
        'Urgencia Veterinaria',
        'Consulta Medica',
        'Descuento En Farmacia',
        'Telemedicina',
        'Asistencia Legal Telefónica',
        'Vacuna Antirrábica',
    ],
    'Mascota Protegida Pro': [
        'Urgencia Veterinaria',
        'Consulta Medica',
        'Descuento En Farmacia',
        'Telemedicina',
        'Asistencia Legal Telefónica',
        'Vacuna Antirrábica',
    ],
    'Protección Total Ultra': [
        'Sala De Urgencia',
        'Atención Medica Ambulatoria',
        'Descuento En Farmacia',
        'Telemedicina',
        'Urgencia Medica Veterinaria',
        'Telemedicina Veterinaria',
        'Vacuna Antirrábica',
        'Asistencia De Plomería',
        'Servicio De Electricida',
        'Servicio De Cerrajería',
        'Servicio De Vidriería',
        'Neumático',
        'Amortiguador',
        'Vidrio Lateral',
        'Cerrajería',
        'Asistencia Funeraria',
        'Asistencia Legal Telefónica',
    ]
};

export { asistencias };