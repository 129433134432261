import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { API } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import FileUploader from "../../common/FileUploader";
import Loading from "../../common/Loading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  },
  red: {
    color: "#f44336"
  }
}));

const GestionarAsistencia = ({ task, data, setData, setShowDialog }) => {
  const [prestadores, setPrestadores] = useState([]);
  const [prestador, setPrestador] = useState(data.prestador ? data.prestador : {});
  const [red, setRed] = useState(data.red ? data.red : false);
  const [loading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState(data.archivos ? data.archivos : []);
  const [error, setError] = useState({
    monto: false,
    prestador: false
  });

  const classes = useStyles();

  const fetchPrestadores = useCallback(async () => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          tipo: "prestador",
          cuenta: task.id_cuenta
        }
      };

      const result = await API.get(process.env.REACT_APP_HUB_API, "/contactos", init);

      result.success && result.data.length > 0 && setPrestadores(result.data);
    } catch (error) {
      console.log("fetchPrestadores", error);
    }

    setLoading(false);
  }, [task]);

  useEffect(() => {
    fetchPrestadores();
  }, [fetchPrestadores]);

  const handleAdd = newFile => {
    if (!archivos) {
      setArchivos([newFile]);
    } else setArchivos([...archivos, newFile]);
  };

  const handleDelete = removeFile => {
    setArchivos({ archivos: archivos.filter(file => file !== removeFile) });
  };

  const handleButtonClick = e => {
    setData({
      ...data,
      saveToTicketBody: true,
      red,
      prestador: !red && prestador,
      archivos
    });

    validate() && setShowDialog(true);
  };

  const validate = () => {
    !red && !prestador.estado_contacto
      ? setError({ ...error, prestador: true })
      : setError({ ...error, prestador: false });

    if (red) {
      return true;
    }

    if (!red && prestador.estado_contacto) {
      return true;
    }

    return false;
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Loading />
          </Grid>
        ) : (
          <>
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="p">
                  Datos del asegurado
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" component="p">
                  Ubicación del asegurado
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" component="p">
                  {task.body_ticket.ubicacion}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" component="p">
                  Certificado de defunción
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" component="p">
                  {`${task.body_ticket.certificado
                    .charAt(0)
                    .toUpperCase()}${task.body_ticket.certificado.slice(1)}`}
                </Typography>
              </Grid>
            </Grid>
            {red === false && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" component="p">
                    Prestador
                  </Typography>
                  <Select
                    fullWidth
                    classes={{ selectMenu: classes.select }}
                    size="small"
                    variant="outlined"
                    value={prestador}
                    onChange={e => {
                      setPrestador(e.target.value);
                    }}
                  >
                    {prestadores.map(item => (
                      <MenuItem key={`item-${item.id}`} value={item}>
                        {item.nombre_contacto}
                      </MenuItem>
                    ))}
                  </Select>
                  {error.prestador && (
                    <FormHelperText className={classes.red}>
                      Debe seleccionar un prestador
                    </FormHelperText>
                  )}
                </Grid>
                {Object.keys(prestador).length !== 0 && (
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <strong>Nombre</strong>
                    </Grid>
                    <Grid item xs={6}>
                      {prestador.nombre_contacto}
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Dirección</strong>
                    </Grid>
                    <Grid item xs={6}>
                      {prestador.body_contacto.direccion.description}
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Teléfono</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <NumberFormat
                        format="+56 9 #### ####"
                        type="tel"
                        displayType={"text"}
                        value={prestador.body_contacto.telefono_contacto}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Correo</strong>
                    </Grid>
                    <Grid item xs={6}>
                      {prestador.body_contacto.correo_contacto}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={red} onChange={() => setRed(!red)} name="red" />}
                label="Sin prestador en zona."
              />
            </Grid>
            <Grid item xs={12}>
              <FileUploader
                parent="gestionar-asistencia"
                data={archivos}
                maxFiles={8}
                onAdd={handleAdd}
                onRemove={handleDelete}
              />
            </Grid>
            <Grid item xs={12}>
              <Hidden mdUp>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={handleButtonClick}
                >
                  Aceptar
                </Button>
              </Hidden>
              <Hidden smDown>
                <Container maxWidth="xs">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={handleButtonClick}
                  >
                    Aceptar
                  </Button>
                </Container>
              </Hidden>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default GestionarAsistencia;
