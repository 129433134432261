import React from "react";
import { formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import PersonIcon from "@material-ui/icons/Person";
import { formatRut } from "../../../functions";
import Conditions from "../../common/Conditions";
import ContentCardDetails from "./cards/ContentCardDetails";
import ContentCardFiles from "./cards/ContentCardFiles";
import ContentCardInsured from "./cards/ContentCardInsured";

const TicketDetails = ({ ticket, mobile, onSelectCard, onClickFile }) => {
  const handleSelectCard = option => {
    switch (option) {
      case "details":
        onSelectCard(<ContentCardDetails />);
        break;
      case "afiliated":
        onSelectCard(<ContentCardInsured />);
        break;
      case "files":
        onSelectCard(<ContentCardFiles />);
        break;
      default:
        onSelectCard(null);
        break;
    }
  };

  return mobile ? (
    <List>
      <ListItem button onClick={() => handleSelectCard("details")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText
          primary={ticket.title}
          secondary={
            <>
              {formatRelative(new Date(ticket.body_ticket.fecha), Date.now(), {
                locale: es
              })}
              <br />
              {ticket.alias_cuenta}
              {ticket.config_cuenta && ticket.config_cuenta.condiciones && (
                <Conditions condiciones={ticket.config_cuenta.condiciones} />
              )}
            </>
          }
          secondaryTypographyProps={{ variant: "caption" }}
        />
      </ListItem>

      <Divider />

      <ListItem button onClick={() => handleSelectCard("afiliated")}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText
          primary={ticket.body_ticket.asegurado.nombre_contacto}
          secondary={formatRut(ticket.body_ticket.asegurado.body_contacto.rut)}
          secondaryTypographyProps={{ variant: "caption" }}
        />
      </ListItem>

      <Divider />

      <ListItem button onClick={() => handleSelectCard("files")}>
        <ListItemIcon>
          <AttachFileIcon />
        </ListItemIcon>
        <ListItemText primary={`${ticket.archivos.length} archivos adjuntos`} />
      </ListItem>
    </List>
  ) : (
    <>
      <ContentCardDetails mobile={mobile} ticket={ticket} />
      <ContentCardInsured mobile={mobile} asegurado={ticket.body_ticket.asegurado} />
      <ContentCardFiles mobile={mobile} archivos={ticket.archivos} onClick={onClickFile} />
    </>
  );
};

export default TicketDetails;
