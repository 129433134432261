import { Container } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Loading = ({ variant, open, listSize = 3, listSubText }) => {
  const classes = useStyles();

  switch (variant) {
    case "fullscreen":
      return (
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );

    case "list":
      const dummyList = Array.from(new Array(listSize));

      return (
        <Container>
          <List className={classes.list}>
            {dummyList.map((_, index) => (
              <ListItem
                divider
                disableGutters
                key={`loading-item-${index}`}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  <Skeleton variant="circle">
                    <Avatar />
                  </Skeleton>
                </ListItemAvatar>
                <ListItemText
                  primary={<Skeleton width="60%" />}
                  secondary={Array.from(Array(listSubText)).map((_, i) => (
                    <Skeleton key={`loading-subtext-${i}`} width="35%" />
                  ))}
                />
              </ListItem>
            ))}
          </List>
        </Container>
      );

    default:
      return (
        <div className={classes.container}>
          <CircularProgress color="primary" />
        </div>
      );
  }
};

export default Loading;
