export const getAwsAuthException = (ex, message) => {
  const awsAuthExceptions = {
    UserNotFoundException: "El usuario no existe",
    InvalidPasswordException:
      "La contraseña no cumple con los niveles de seguridad. Debe tener al menos 8 caracteres, mayúsculas, minúsculas y números",
    UserNotConfirmedException:
      "Es necesario que confirme su email con el código de verificación que se envió a su correo",
    CodeMismatchException: "Código de verificación incorrecto",
    ExpiredCodeException: "Código de verificación inválido. Solicite uno nuevo",
    UsernameExistsException: "Ya existe una cuenta asociada a ese email",
    NotAuthorizedException: "No autorizado",
    LimitExceededException: "Límite de reintentos excedido",
    PasswordResetRequiredException: "Su contraseña ha sido reseteada por el administrador, Haga click en ¿Olvidaste tu contraseña?"
  };

  let mensaje = awsAuthExceptions[ex];

  if (ex === "NotAuthorizedException") {
    if (message.includes("Incorrect username or password")) {
      mensaje = "Usuario o contraseña incorrectos";
    } else if (message.includes("User is disabled")) {
      mensaje = "El usuario está deshabilitado";
    }
  }

  if (mensaje) {
    return mensaje;
  } else {
    console.error(ex);
    return "Ocurrió un problema";
  }
};
