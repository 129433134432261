export const TASK_FILTER_KEYS = {
  mine: "mine",
  all: "all",
  viaschile: "vias-chile",
  banigualdad: "banigualdad",
  autofin: "autofin"
};

export const tasks_filter = [
  { key: TASK_FILTER_KEYS.mine, checkbox: false, label: "Mis Tareas" },
  { key: TASK_FILTER_KEYS.all, checkbox: false, label: "Todas" },
  { key: TASK_FILTER_KEYS.viaschile, checkbox: true, label: "Vias Chile" },
  { key: TASK_FILTER_KEYS.banigualdad, checkbox: true, label: "Banigualdad" },
  { key: TASK_FILTER_KEYS.autofin, checkbox: true, label: "Autofin" }
];
