import React, { useCallback, useEffect, useRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { fade, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import BuildIcon from "@material-ui/icons/Build";
import FilterListIcon from "@material-ui/icons/FilterList";
import GroupIcon from "@material-ui/icons/Group";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { API } from "aws-amplify";
import { contacts_filter } from "../../constants/contacts_filter";
import { formatRut, rutEsValido, unformatRut } from "../../functions";
import Loading from "../common/Loading";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
  filterButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  secondaryAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  searchArea: {
    padding: theme.spacing(2),
  },
  list: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const ContactsListView = ({ mobile, onClick, onDrawerOpen }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [initialContacts, setInitialContacts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(
    contacts_filter.filter((item) => item.checkbox)
  );
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [title, setTitle] = useState("Todos");
  const openFilter = Boolean(anchorEl);
  const searchRef = useRef();

  /*
  const fetchContacts = useCallback(async () => {
    setLoading(true);

    try {
      const result = await API.get(process.env.REACT_APP_HUB_API, "/contactos");

      if (result.success) {
        setContacts(result.data);
        setInitialContacts(result.data);
      } else {
        console.warn("fetchContacts", result);
      }
    } catch (err) {
      console.error("fetchContacts", err);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);
  */

  const fetchContacts = async (query) => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: query,
      };

      const result = await API.get(
        process.env.REACT_APP_HUB_API,
        "/contactos",
        init
      );

      if (result.success) {
        setContacts(result.data);
      } else {
        console.warn("fetchContacts", result);
      }
    } catch (err) {
      console.error("fetchContacts", err);
    }

    setLoading(false);
  };

  const openFilterMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let tempContacts = [];

    if (value.checkbox) {
      setTitle("Todos");
      currentIndex === -1
        ? newChecked.push(value)
        : newChecked.splice(currentIndex, 1);
      setChecked(newChecked);
    } else {
      setTitle(value.label);
    }

    tempContacts = filterAccounts(newChecked);

    if (!value.checkbox) {
      if (value.key !== "all") {
        tempContacts = tempContacts.filter(
          (contact) => contact.tipo_contacto === value.key
        );
      }
    }

    setContacts(tempContacts);
  };

  const filterAccounts = (accounts) => {
    const results = [];

    accounts.map((account) => {
      results.push(
        ...initialContacts.filter((contact) => {
          return contact.slug_cuenta === account.key;
        })
      );

      return true;
    });

    return results;
  };

  const ContactListItem = ({ item }) => {
    const {
      estado_contacto,
      body_contacto,
      nombre_contacto,
      tipo_contacto,
      nombre_cuenta,
      tags_contacto,
      config_cuenta,
    } = item;

    let icon = <GroupIcon color="disabled" />;
    if (tipo_contacto === "prestador") icon = <BuildIcon color="disabled" />;

    return (
      <>
        <ListItem button alignItems="flex-start" onClick={() => onClick(item)}>
          <ListItemAvatar>{icon}</ListItemAvatar>
          <ListItemText
            className={classes.textCapitalize}
            primary={nombre_contacto}
            secondary={
              <>
                <div>{formatRut(body_contacto.rut)}</div>
                <div>
                  {tipo_contacto === "prestador"
                    ? tags_contacto.join(" , ")
                    : tipo_contacto}
                </div>
                <div>{nombre_cuenta}</div>
              </>
            }
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>
        <Divider variant={!mobile ? "middle" : "fullWidth"} />
      </>
    );
  };

  const isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  const search = (value) => {
    if (!value) {
      setContacts(initialContacts);
    }

    // Búsqueda por RUT
    if (rutEsValido(value)) {
      /*const results = initialContacts.filter((contact) => {
        return value === contact.body_contacto.rut;
      });

      setContacts(results);*/
      fetchContacts({ rut: unformatRut(value) });
    }

    // Búsqueda por nombre del asegurado
    if (value && !isNumeric(value) && !rutEsValido(value)) {
      /*const results = initialContacts.filter((contact) => {
        return contact.nombre_contacto
          .toLowerCase()
          .includes(value.toLowerCase());
      });

      setContacts(results);*/
      fetchContacts({ nombre: value });
    }
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {onDrawerOpen && (
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              onClick={onDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography className={classes.title} variant="h6" component="p">
            {/*`Contactos - ${title}`*/}
            Contactos
          </Typography>

          {/*{showSearchBar && (*/}
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              autoFocus
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "buscador" }}
              inputRef={searchRef}
              onKeyDown={(e) => {
                e.keyCode === 13 && search(e.target.value);
              }}
              {
                /*onBlur={(e) => {
                search(e.target.value);
                setShowSearchBar(false);
              }}*/ ...{}
              }
            />
          </div>
          {/*})}*/}

          {/*
          {!showSearchBar && (
            <>
              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
                onClick={() => fetchContacts()}
              >
                <RefreshIcon />
              </IconButton>

              <IconButton
                className={classes.searchButton}
                edge="end"
                color="inherit"
                onClick={() => {
                  setShowSearchBar(!showSearchBar);
                  setTimeout(() => {
                    searchRef.current.focus();
                  }, [100]);
                }}
              >
                <SearchIcon />
              </IconButton>

              <IconButton
                className={classes.filterButton}
                edge="end"
                color="inherit"
                onClick={openFilterMenu}
              >
                <FilterListIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={openFilter} onClose={closeFilterMenu}>
                {contacts_filter.map(item => {
                  const labelId = `checkbox-list-label-${item.key}`;

                  return (
                    <div key={`contacts-menu-item-${item.key}`}>
                      <MenuItem divider onClick={handleToggle(item)}>
                        {item.checkbox && (
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(item) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={item.label} />
                      </MenuItem>
                      {!item.checkbox && <Divider variant="fullWidth" />}
                    </div>
                  );
                })}
              </Menu>
            </>
          )}
          */}
        </Toolbar>
      </AppBar>
      {loading ? (
        <Loading variant="list" listSubText={3} />
      ) : (
        contacts && (
          <List className={classes.list}>
            {contacts.map((item, i) => (
              <ContactListItem key={`contact-item-${i}`} item={item} />
            ))}
          </List>
        )
      )}
    </>
  );
};

export default ContactsListView;
