import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Alert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { validateEmail } from "../../functions";
import { getAwsAuthException } from "./authFunctions";
import Copyright from "./Copyright";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  logo: {
    width: "65%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  alertMsg: {
    margin: theme.spacing(2, 0)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: theme.spacing(6)
  }
}));

const ForgotPassword = props => {
  const classes = useStyles();
  const { authState, onStateChange } = props;

  const [step, setStep] = useState(1);

  const [username, setUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");

  const [code, setCode] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMsg] = useState({ open: false, type: "error", msg: "" });

  const reset = () => {
    setStep(1);
    setUsername("");
    setUsernameErrorMessage("");
    setCode("");
    setCodeErrorMessage("");
    setPassword("");
    setPasswordErrorMessage("");
    setConfirmPassword("");
    setConfirmPasswordErrorMessage("");
    setAlertMsg({ ...alertMessage, open: false, msg: "" });
  };

  const validate = () => {
    let result = true;

    if (step === 1) {
      if (!username || username === "") {
        setUsernameErrorMessage("El email no puede estar vacío");
        result = false;
      } else if (!validateEmail(username)) {
        setUsernameErrorMessage("Debe ingresar un email válido");
        result = false;
      } else {
        setUsernameErrorMessage("");
      }
    }

    if (step === 2) {
      if (!code || code === "") {
        setCodeErrorMessage("El código de verificación no puede estar vacío");
        result = false;
      } else {
        setCodeErrorMessage("");
      }

      if (!password || password === "") {
        setPasswordErrorMessage("La contraseña no puede estar vacía");
        result = false;
      } else if (password.length < 8) {
        setPasswordErrorMessage("La contraseña debe tener al menos 8 caracteres");
        result = false;
      } else {
        setPasswordErrorMessage("");
      }

      if (password !== confirmPassword) {
        setConfirmPasswordErrorMessage("Las contraseñas no coinciden");
        result = false;
      } else {
        setConfirmPasswordErrorMessage("");
      }
    }

    return result;
  };

  const forgotPassword = async e => {
    e.preventDefault();
    setAlertMsg({ ...alertMessage, open: false, msg: "" });

    if (validate()) {
      setLoading(true);

      try {
        if (step === 1) {
          await Auth.forgotPassword(username);
          setStep(2);
          setLoading(false);
        } else if (step === 2) {
          await Auth.forgotPasswordSubmit(username, code, password);
          reset();
          setLoading(false);
          onStateChange("signIn", { forgotPassword: true });
        }
      } catch (err) {
        setLoading(false);
        const msg = getAwsAuthException(err.code, err.message);
        setAlertMsg({
          open: true,
          type: "error",
          msg
        });
      }
    }
  };

  if (authState !== "forgotPassword") {
    return null;
  }

  const renderButton = () => {
    let buttonText = "";

    if (step === 1) buttonText = "Solicitar constraseña";
    else if (step === 2) buttonText = "Restablecer contraseña";

    return (
      <Button
        type="submit"
        color="primary"
        fullWidth
        variant="contained"
        className={classes.submit}
        disabled={loading}
      >
        {!loading ? buttonText : <CircularProgress size={30} />}
      </Button>
    );
  };

  return (
    <Grid container component="main" justify="center" className={`auth ${classes.root}`}>
      <Grid item xs={12} sm={8} md={5} lg={3}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <img
            className={classes.logo}
            src={process.env.PUBLIC_URL + "/assets/images/logo_serviclick.png"}
            alt="home-logo"
          />
          <Typography component="h1" variant="h5">
            Restablecer contraseña
          </Typography>
          <form className={classes.form} autoComplete="off" noValidate onSubmit={forgotPassword}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Collapse in={alertMessage.open}>
                  <Alert
                    className={classes.alertMsg}
                    variant="filled"
                    severity={alertMessage.type}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setAlertMsg({ ...alertMessage, open: false })}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {alertMessage.msg}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                  disabled={step === 2 || loading}
                  error={usernameErrorMessage ? true : false}
                  helperText={usernameErrorMessage}
                />
              </Grid>

              {step === 2 && (
                <>
                  <Grid item xs={12}>
                    <NumberFormat
                      customInput={TextField}
                      format="######"
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                      name="code"
                      label="Código de verificación"
                      id="code"
                      onChange={({ target }) => setCode(target.value)}
                      disabled={loading}
                      value={code}
                      error={codeErrorMessage ? true : false}
                      helperText={codeErrorMessage}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Nueva contraseña"
                      type="password"
                      id="password"
                      onChange={({ target }) => setPassword(target.value)}
                      disabled={loading}
                      value={password}
                      error={passwordErrorMessage ? true : false}
                      helperText={passwordErrorMessage}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirmar nueva contraseña"
                      type="password"
                      id="confirmPassword"
                      onChange={({ target }) => setConfirmPassword(target.value)}
                      disabled={loading}
                      value={confirmPassword}
                      error={confirmPasswordErrorMessage ? true : false}
                      helperText={confirmPasswordErrorMessage}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {renderButton()}

            <Grid container justify="center">
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  ¿Ya te has registrado?
                  <Button
                    color="primary"
                    className="ml-1"
                    onClick={() => {
                      reset();
                      onStateChange("signIn");
                    }}
                  >
                    Inicia sesión
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5} mb={1}>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
