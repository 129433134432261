import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import TaskListView from "../../components/tasks/TasksListView";
import TaskView from "../../components/tasks/TaskView";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "hidden"
  }
}));

const Tasks = () => {
  const [task, setTask] = useState(null);
  const classes = useStyles();

  const handleSelectTask = task => {
    setTask(task);
  };

  return (
    <Grid container spacing={0} className={classes.overflowHidden}>
      <Grid item xs={12} sm={4}>
        <TaskListView task={task} onSelectedTask={handleSelectTask} />
      </Grid>

      <Grid item xs={12} sm={8}>
        <TaskView task={task} onSelectedTask={handleSelectTask} />
      </Grid>
    </Grid>
  );
};

export default Tasks;
