import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useReducer, useState } from "react";
import NumberFormat from "react-number-format";
import {
  formatRut,
  nombreEsValido,
  rutEsValido,
  toTitleCase,
  unformatRut,
  validateEmail
} from "../../../functions";
import AppBarCreate from "../../common/AppBarCreate";
import SelectAddress from "../../common/SelectAddress";
import SelectBank from "../../common/SelectBank";
import SelectBankAccount from "../../common/SelectBankAccount";
import { TYPES_KEYS, ACCOUNT_PRESTACIONES } from "./constants";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  alignRight: {
    textAlign: "right"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%"
    }
  },
  button: {
    margin: theme.spacing(2, 0)
  },
  plusButton: {
    backgroundColor: "#28A0C0",
    color: "white",
    "&:hover": {
      backgroundColor: "#1C7086"
    }
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  front: {
    zIndex: "1400 !important"
  },
  back: {
    zIndex: "1200 !important"
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  }
}));

const initialState = {
  rut: "",
  nombre: "",
  direccion: "",
  telefono_contacto: "",
  correo_contacto: "",
  rut_cuenta: "",
  banco: "",
  tipo_cuenta: "",
  numero_cuenta: "",
  error: {
    rut: false,
    nombre: false,
    celular: false,
    correo_contacto: false,
    direccion: false,
    rut_cuenta: false,
    banco: false,
    tipo_cuenta: false,
    numero_cuenta: false
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "rut":
      return { ...state, [type]: payload, error: { ...state.error, rut: !rutEsValido(payload) } };
    case "nombre":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, nombre: !nombreEsValido(payload) }
      };
    case "telefono_contacto":
      return {
        ...state,
        [type]: payload,
        error: {
          ...state.error,
          telefono_contacto: !payload || payload === "" || payload.length < 8
        }
      };
    case "correo_contacto":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, correo_contacto: !validateEmail(payload) }
      };
    case "direccion":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, direccion: !payload || payload === "" }
      };
    case "rut_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, rut_cuenta: !rutEsValido(payload) }
      };
    case "banco":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, banco: !payload || payload === "" }
      };
    case "tipo_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, tipo_cuenta: !payload || payload === "" }
      };
    case "numero_cuenta":
      return {
        ...state,
        [type]: payload,
        error: { ...state.error, numero_cuenta: !payload || payload === "" || payload.length < 4 }
      };
    case "error":
      return {
        ...state,
        error: { ...state.error, [payload]: true }
      };
    default:
      return { ...initialState };
  }
};

const PrestadorCreate = ({ account, accounts, sending, onBack, onClose, onCreate }) => {
  const classes = useStyles();
  const stepCount = 3;
  const [step, setStep] = useState(0);
  const [data, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);

  const validate = () => {
    switch (step) {
      case 0:
        if (!rutEsValido(data.rut)) dispatch({ type: "error", payload: "rut" });

        if (!nombreEsValido(data.nombre)) dispatch({ type: "error", payload: "nombre" });

        if (
          !data.telefono_contacto ||
          data.telefono_contacto === "" ||
          data.telefono_contacto.length < 8
        )
          dispatch({ type: "error", payload: "telefono_contacto" });

        if (!data.direccion || data.direccion === "")
          dispatch({ type: "error", payload: "direccion" });

        if (!validateEmail(data.correo_contacto))
          dispatch({ type: "error", payload: "correo_contacto" });

        return (
          rutEsValido(data.rut) &&
          nombreEsValido(data.nombre) &&
          data.direccion &&
          data.direccion !== "" &&
          data.telefono_contacto &&
          data.telefono_contacto !== "" &&
          validateEmail(data.correo_contacto)
        );
      case 1:
        if (!rutEsValido(data.rut_cuenta)) dispatch({ type: "error", payload: "rut_cuenta" });

        if (!data.banco) dispatch({ type: "error", payload: "banco" });

        if (!data.tipo_cuenta) dispatch({ type: "error", payload: "tipo_cuenta" });

        if (!data.numero_cuenta || data.numero_cuenta === "")
          dispatch({ type: "error", payload: "numero_cuenta" });

        return (
          rutEsValido(data.rut_cuenta) &&
          data.banco !== null &&
          data.tipo_cuenta !== null &&
          data.numero_cuenta &&
          data.numero_cuenta.length > 2
        );
      case 2:
        return true;
      default:
        return false;
    }
  };

  const create = () => {
    const {
      rut,
      nombre,
      telefono_contacto,
      correo_contacto,
      direccion,
      rut_cuenta,
      banco,
      tipo_cuenta,
      numero_cuenta
    } = data;

    const body = {
      rut,
      correo_contacto,
      telefono_contacto,
      direccion,
      datos_bancarios: { rut_cuenta, banco, tipo_cuenta, numero_cuenta },
      servicios: services
    };

    const tags = [];
    const id_cliente = null;
    const id_cuenta = account.id_cuenta;

    if (validate()) {
      onCreate(TYPES_KEYS.prestador, nombre.trim(), body, tags, id_cliente, id_cuenta);
    }
  };

  const onPrevStep = () => {
    if (step === 0) {
      onBack();
    } else {
      setStep(s => --s);
    }
  };

  const onNextStep = () => {
    if (step < stepCount - 1) {
      validate() && setStep(s => ++s);
    } else {
      create();
    }
  };

  const PrestadorCreatePersonal = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <Typography variant="h6" component="p">
          Datos personales
        </Typography>

        <TextField
          id="rut"
          key="rut"
          size="small"
          variant="outlined"
          label="Rut *"
          placeholder="Ej: 12.345.678-9"
          InputLabelProps={{
            shrink: true
          }}
          value={formatRut(data.rut)}
          disabled={sending}
          onChange={({ target }) => {
            dispatch({ type: "rut", payload: unformatRut(target.value) });
          }}
          onBlur={() => dispatch({ type: "rut", payload: data.rut })}
          error={data.error.rut}
          helperText={data.error.rut && "Debe ingresar un Rut valido"}
        />

        <TextField
          id="name"
          size="small"
          variant="outlined"
          label="Nombre o razón social *"
          placeholder="Ej: Juan Pérez"
          InputLabelProps={{
            shrink: true
          }}
          value={data.nombre && toTitleCase(data.nombre)}
          disabled={sending}
          onChange={({ target }) => dispatch({ type: "nombre", payload: target.value })}
          onBlur={() => dispatch({ type: "nombre", payload: data.nombre })}
          error={data.error.nombre}
          helperText={data.error.nombre && "Debe ingresar un nombre"}
        />
        <Typography variant="h6" component="p">
          Datos de contacto
        </Typography>

        <NumberFormat
          id="phone"
          customInput={TextField}
          format="+56 9 #### ####"
          allowEmptyFormatting
          size="small"
          variant="outlined"
          type="tel"
          label="Teléfono celular *"
          placeholder="Ej: +56 9 1234 5678"
          InputLabelProps={{
            shrink: true
          }}
          value={data.telefono_contacto}
          disabled={sending}
          onValueChange={({ value }) => dispatch({ type: "telefono_contacto", payload: value })}
          onBlur={() => dispatch({ type: "telefono_contacto", payload: data.telefono_contacto })}
          error={data.error.telefono_contacto}
          helperText={data.error.telefono_contacto && "Debe ingresar un celular valido"}
        />

        <TextField
          id="email"
          size="small"
          variant="outlined"
          type="email"
          label="Email *"
          placeholder="Ej: juanperez@gmail.com"
          InputLabelProps={{
            shrink: true
          }}
          value={data.correo_contacto}
          disabled={sending}
          onChange={({ target }) =>
            dispatch({ type: "correo_contacto", payload: target.value.toLowerCase() })
          }
          onBlur={() => dispatch({ type: "correo_contacto", payload: data.correo_contacto })}
          error={data.error.correo_contacto}
          helperText={data.error.correo_contacto && "Debe ingresar un correo valido"}
        />

        <Typography variant="h6" component="p">
          Ubicación
        </Typography>

        {/* <SelectAddress
          id="address"
          size="small"
          variant="outlined"
          label="Dirección *"
          value={data.direccion}
          disabled={sending}
          onChange={value =>
            dispatch({
              type: "direccion",
              payload: value
            })
          }
          onBlur={() => dispatch({ type: "direccion", payload: data.direccion })}
          error={data.error.direccion}
          helperText={data.error.direccion && "Debe ingresar una dirección"}
        /> */}
        <TextField
          id="address"
          size="small"
          variant="outlined"
          label="Dirección *"
          placeholder="Ej: Pje. 1, Santiago Centro, Region Metropolitana"
          InputLabelProps={{
            shrink: true
          }}
          value={data.direccion}
          disabled={sending}
          onChange={({ target }) => dispatch({ type: "direccion", payload: target.value })}
          onBlur={() => dispatch({ type: "direccion", payload: data.direccion })}
          error={data.error.direccion}
          helperText={data.error.direccion && "Debe ingresar una dirección"}
        />
      </form>
    );
  };

  const PrestadorCreateBank = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <Typography variant="h6" component="p">
          Datos bancarios
        </Typography>

        <TextField
          id="rut_cuenta"
          key="rut_cuenta"
          size="small"
          variant="outlined"
          label="Rut cuenta *"
          placeholder="Ej: 12.345.678-9"
          InputLabelProps={{
            shrink: true
          }}
          value={formatRut(data.rut_cuenta)}
          disabled={sending}
          onChange={({ target }) => {
            dispatch({ type: "rut_cuenta", payload: unformatRut(target.value) });
          }}
          onBlur={() => dispatch({ type: "rut_cuenta", payload: data.rut_cuenta })}
          error={data.error.rut_cuenta}
          helperText={data.error.rut_cuenta && "Debe ingresar un Rut valido"}
        />

        <SelectBank
          className="control"
          size="small"
          variant="outlined"
          value={data.banco}
          disabled={sending}
          onChange={value => dispatch({ type: "banco", payload: value })}
          onBlur={() => dispatch({ type: "banco", payload: data.banco })}
          error={data.error.banco}
          helperText={data.error.banco && "Debe seleccionar un banco"}
        />

        <SelectBankAccount
          className="control"
          size="small"
          variant="outlined"
          value={data.tipo_cuenta}
          disabled={sending}
          onChange={value => dispatch({ type: "tipo_cuenta", payload: value })}
          onBlur={() => dispatch({ type: "tipo_cuenta", payload: data.tipo_cuenta })}
          error={data.error.tipo_cuenta}
          helperText={data.error.tipo_cuenta && "Debe seleccionar un tipo de cuenta"}
        />

        <NumberFormat
          id="bank_account_number"
          customInput={TextField}
          size="small"
          variant="outlined"
          type="tel"
          label="Numero de cuenta *"
          placeholder="Ej: 123456789"
          InputLabelProps={{
            shrink: true
          }}
          value={data.numero_cuenta}
          disabled={sending}
          onValueChange={({ value }) => dispatch({ type: "numero_cuenta", payload: value })}
          onBlur={() => dispatch({ type: "numero_cuenta", payload: data.numero_cuenta })}
          error={data.error.numero_cuenta}
          helperText={data.error.numero_cuenta && "Debe ingresar un número de cuenta"}
        />
      </form>
    );
  };

  const PrestadorCreateProfessionalData = () => {
    const removeService = service => {
      const updatedServices = services.filter(item => item !== service);

      setServices(updatedServices);
    };

    return (
      <form className={classes.form} noValidate autoComplete="off">
        <Typography variant="h6" component="p">
          Datos Contractuales (Profesionales)
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="p">
              Servicios asociados
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.alignRight}>
            <IconButton className={classes.plusButton} onClick={handleClickOpen}>
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.button} />
          </Grid>
          <Grid item xs={12}>
            <List>
              {services.map(item => (
                <ListItem key={`service-${item.id}`}>
                  <ListItemText
                    secondaryTypographyProps={{ component: "p" }}
                    primary={item.tipo}
                    secondary={
                      <span>
                        <div>
                          <NumberFormat
                            prefix={"$ "}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            value={item.tarifa}
                          />
                        </div>
                        <div>{item.horario}</div>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => removeService(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </form>
    );
  };

  const CurrentStep = () => {
    switch (step) {
      case 0:
        return PrestadorCreatePersonal();
      case 1:
        return PrestadorCreateBank();
      case 2:
        return PrestadorCreateProfessionalData();
      default:
        return <></>;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ServiceDialog = () => {
    const [tipo, setTipo] = useState("");
    const [tarifa, setTarifa] = useState("");
    const [horario, setHorario] = useState("");
    const [cuenta, setCuenta] = useState(accounts ? accounts[0].alias_cuenta : "");
    const [tipos, setTipos] = useState(accounts ? ACCOUNT_PRESTACIONES.find(x => x.alias_cuenta === accounts[0].alias_cuenta).tipos : [
      {
        id: 0,
        name: "Electricidad"
      },
      {
        id: 1,
        name: "Gas"
      },
      {
        id: 2,
        name: "Plomería"
      }
    ]);
    

    const update_tipo_servicio = (alias) => {      
      //Se obtiene los tipos segun campanha a utilizar
      for (var acc_type in ACCOUNT_PRESTACIONES){      
        if (ACCOUNT_PRESTACIONES[acc_type].alias_cuenta === alias){
          setTipos(ACCOUNT_PRESTACIONES[acc_type].tipos);
          break;
        }
      }
    };

    const addService = () => {
      const selectedAccount = accounts.find(account => account.alias_cuenta === cuenta);

      setServices([
        ...services,
        {
          id: services.length + 1,
          cuenta: selectedAccount,
          tipo,
          tarifa,
          horario
        }
      ]);

      setTipo(0);
      setTarifa("");
      setHorario("");

      setOpen(false);
    };

    return (
      <Dialog
        fullScreen
        className={open ? classes.front : classes.back}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="p" align="center" className={classes.title}>
              Agregar un servicio
            </Typography>
            <Button autoFocus color="inherit" onClick={addService}>
              Aceptar
            </Button>
          </Toolbar>
        </AppBar>
        <form className={classes.form} noValidate autoComplete="off">
          <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="p">
                  Servicio
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  classes={{ selectMenu: classes.select }}
                  variant="outlined"
                  value={cuenta}
                  onChange={e => {
                    setCuenta(e.target.value);
                    update_tipo_servicio(e.target.value);
                  }}
                >
                  {accounts.map(item => (
                    <MenuItem key={`item-${item.id}`} value={item.alias_cuenta}>
                      {item.alias_cuenta}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  classes={{ selectMenu: classes.select }}
                  variant="outlined"
                  value={tipo}
                  onChange={e => {
                    setTipo(e.target.value);
                  }}
                >
                  {tipos.map(item => (
                    <MenuItem key={`item-${item.id}`} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <NumberFormat
                  fullWidth
                  label="Tarifa"
                  placeholder="Ej: 123456"
                  customInput={TextField}
                  prefix={"$ "}
                  thousandSeparator="."
                  decimalSeparator=","
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  size="small"
                  value={tarifa}
                  onValueChange={({ _, value }) => setTarifa(value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Horario de atención"
                  placeholder="Ej: Lu-Vi 09:00 - 18:30"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={horario}
                  disabled={sending}
                  onChange={({ target }) => {
                    setHorario(target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarCreate
        title="Crear Prestador"
        disabled={sending}
        onBack={onPrevStep}
        onClose={onClose}
      />
      <Container className={classes.container} maxWidth="sm">
        <ServiceDialog />
        {CurrentStep()}
        {step + 1 <= stepCount && (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            disabled={sending}
            onClick={onNextStep}
          >
            {step + 1 < stepCount ? "Siguiente" : "Guardar"}
          </Button>
        )}
      </Container>
    </>
  );
};

export default PrestadorCreate;
