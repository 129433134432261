import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import StarRateRoundedIcon from "@material-ui/icons/StarRateRounded";
import React, { useReducer } from "react";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const initialState = {
  time: 1,
  attention: 1,
  quality: 1
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "time":
    case "attention":
    case "quality":
      return {
        ...state,
        [type]: payload
      };
    default:
      return { ...initialState };
  }
};

const Encuesta = ({ task, data, setData, setShowDialog }) => {
  const [datos, dispatch] = useReducer(reducer, initialState);
  const stars = [1, 2, 3, 4, 5];

  const rating = type => {
    return stars.map(star => {
      return (
        <IconButton
          key={`${type}-star-${star}`}
          color="primary"
          size="medium"
          onClick={() => {
            dispatch({ type: type, payload: star });
          }}
        >
          {datos[type] >= star ? <StarRateRoundedIcon /> : <StarBorderRoundedIcon />}
        </IconButton>
      );
    });
  };

  const handleButtonClick = e => {
    setData({
      ...data,
      tiempo: datos.time,
      atencion: datos.attention,
      calidad: datos.quality
    });

    setShowDialog(true);
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h6" component="p">
            {`Calificar el servicio`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p">
            Tiempo de respuesta
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating("time")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p">
            Atención teléfonica
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating("attention")}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" component="p">
            Calidad del servicio
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {rating("quality")}
        </Grid>

        <Grid item xs={12}>
          <Hidden mdUp>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleButtonClick}
            >
              Aceptar
            </Button>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="xs">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={handleButtonClick}
              >
                Aceptar
              </Button>
            </Container>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Encuesta;
