import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import Chart from "react-google-charts";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Loading from "../common/Loading";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  centerGrid: {
    overflow: "hidden",
    textAlign: "center",
    height: "calc(100vh - 64px)",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  options: {
    flexGrow: 1,
    textAlign: "right",
    padding: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
  },
  topCard: {
    borderBottom: "1px solid #efefef",
    borderRight: "1px solid #efefef",
    width: "30%",
    padding: theme.spacing(2, 3),
  },
  topCardBlue: {
    borderBottom: "1px solid #efefef",
    borderRight: "1px solid #efefef",
    width: "30%",
    padding: theme.spacing(2, 3),
    color: "white",
    backgroundColor: "#28A0C0",
    border: "none",
  },
  noData: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    margin: theme.spacing(10, 5),
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px",
  },
}));

const Charts = () => {
  const [ticketsLastMonth, setTicketsLastMonth] = useState([
    ["Fecha", "Casos"],
    [0, 0],
  ]);
  const [paymentsTotalLastMonth, setPaymentsTotalLastMonth] = useState([
    ["Fecha", "Total"],
    [0, 0],
  ]);
  const [account, setAccount] = useState("");
  const [accounts, setAccounts] = useState([]);

  const [ticketsChart, setTicketsChart] = useState(ticketsLastMonth);
  const [totalTickets, setTotalTickets] = useState(0);

  const [paymentsChart, setPaymentsChart] = useState(paymentsTotalLastMonth);
  const [totalPayments, setTotalPayments] = useState(0);

  const [anchorElTickets, setAnchorElTickets] = useState(null);
  const [anchorElPayments, setAnchorElPayments] = useState(null);
  const [selectedIndexTicket, setSelectedIndexTicket] = useState(3);
  const [selectedIndexPayments, setSelectedIndexPayments] = useState(3);

  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  const fetchTicketsLastMonth = useCallback(async () => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          cuenta: account.id_cuenta,
        },
      };
      const result = await API.get(
        process.env.REACT_APP_HUB_API,
        "/charts/tickets-ultimo-mes",
        init
      );

      if (result.success) {
        if (result.data.length > 0) {
          let tickets = [];
          const thisMonth = new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              new Date().getDate()
            )
          );

          for (let i = 0; i < result.data.length; i++) {
            let ticket = result.data[i];
            let cantidad = ticket.cantidad_ticket ? ticket.cantidad_ticket : 0;

            tickets.push([
              ticket.fecha_creacion_ticket.substring(0, 10),
              cantidad,
            ]);
          }

          const ticketsThisMonth = tickets.filter(
            (date) => new Date(date[0]) >= thisMonth
          );

          setTicketsLastMonth(tickets);
          setTicketsChart([["Fecha", "Casos"], ...ticketsThisMonth]);
          setTotalTickets(total(ticketsThisMonth));
          setSelectedIndexTicket(3);
        } else {
          setTicketsLastMonth([]);
          setTicketsChart([
            ["Fecha", "Casos"],
            [0, 0],
          ]);
          setTotalTickets(0);
        }
      }
    } catch (err) {
      console.error("fetchTicketsLastMonth", err);
    }

    setLoading(false);
  }, [account]);

  const fetchPaymentsTotalLastMonth = useCallback(async () => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          cuenta: account.id_cuenta,
        },
      };
      const result = await API.get(
        process.env.REACT_APP_HUB_API,
        "/charts/montos-ultimo-mes",
        init
      );

      if (result.success) {
        if (result.data.length > 0) {
          let payments = [];

          const thisMonth = new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              new Date().getDate()
            )
          );

          for (let i = 0; i < result.data.length; i++) {
            let payment = result.data[i];
            let totalPayments = payment.monto ? parseInt(payment.monto) : 0;

            if (payment.fecha != null && payment.monto != null) {
              payments.push([payment.fecha.substring(0, 10), totalPayments]);
            }
          }

          const ventasThisMonth = payments.filter(
            (date) => new Date(date[0]) >= thisMonth
          );

          setPaymentsTotalLastMonth(payments);
          setPaymentsChart([["Fecha", "Total"], ...ventasThisMonth]);
          setTotalPayments(total(ventasThisMonth));
          setSelectedIndexPayments(3);
        } else {
          setTicketsLastMonth([]);
          setTicketsChart([
            ["Fecha", "Total"],
            [0, 0],
          ]);
          setTotalTickets(0);
        }
      }
    } catch (err) {
      console.error("fetchTicketsLastMonth", err);
    }
    setLoading(false);
  }, [account]);

  const fetchAccounts = useCallback(async () => {
    try {
      setLoading(true);

      const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

      if (result.success) {
        if (result.data) setAccounts(result.data);
      } else console.warn("fetchAccounts", result.error);
    } catch (err) {
      console.error("fetchAccounts", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (account) {
      fetchTicketsLastMonth();
      fetchPaymentsTotalLastMonth();
    } else {
      fetchAccounts();
    }
  }, [
    account,
    fetchTicketsLastMonth,
    fetchAccounts,
    fetchPaymentsTotalLastMonth,
  ]);

  const options = [
    "Hoy",
    "Ayer",
    "Últimos 7 días",
    "Últimos 30 días",
    "Mes pasado",
  ];

  const handleMenuTicketClick = (index) => {
    setSelectedIndexTicket(index);
    handleTickets(index);
    setAnchorElTickets(null);
  };

  const handleMenuPaymentsClick = (index) => {
    setSelectedIndexPayments(index);
    handlePayments(index);
    setAnchorElPayments(null);
  };

  const loadingCircle = () => {
    return (
      <div className={classes.noData}>
        <Loading />
      </div>
    );
  };

  const chart = (data) => {
    if (loading) {
      return loadingCircle();
    }

    if (!data[1] || data[1][0] === 0) {
      return (
        <div className={classes.noData}>
          <div className="text-center mb-3">
            <InfoOutlinedIcon fontSize="large" color="primary" />
          </div>
          <Typography variant="h5">
            No hay datos para la fecha seleccionada.
          </Typography>
        </div>
      );
    }

    return (
      <Chart
        height={"400px"}
        chartType="LineChart"
        // loader={loadingCircle}
        data={data}
        options={{
          fontSize: 12,
          fontName: "Roboto",
          chartArea: {
            left: 40,
            top: 40,
            width: "80%",
          },
          legend: {
            position: "none",
          },
          hAxis: {
            showTextEvery: data.length - 2,
          },
          vAxis: {
            format: "short",
            gridlines: {
              multiple: 1,
            },
            viewWindow: {
              min: 0,
            },
          },
        }}
      />
    );
  };

  const sortToday = (data) => {
    const array = data.filter((date) => new Date(date[0]) >= today);

    if (array.length > 0) {
      return array;
    } else {
      return [[0, 0]];
    }
  };

  const sortYesterday = (data) => {
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const array = data.filter(
      (date) => new Date(date[0]) >= yesterday && new Date(date[0]) <= today
    );

    if (array.length > 0) {
      return array;
    } else {
      return [[0, 0]];
    }
  };

  const sortLastWeek = (data) => {
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const array = data.filter((date) => new Date(date[0]) >= lastWeek);

    if (array.length > 0) {
      return array;
    } else {
      return [[0, 0]];
    }
  };

  const sortMonth = (data) => {
    const thisMonth = new Date(today);
    thisMonth.setMonth(thisMonth.getMonth() - 1);

    const array = data.filter((date) => new Date(date[0]) >= thisMonth);

    if (array.length > 0) {
      return array;
    } else {
      return [[0, 0]];
    }
  };

  const sortLastMonth = (data) => {
    const thisMonth = new Date(today);
    thisMonth.setMonth(thisMonth.getMonth() - 1);
    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 2);

    const array = data.filter(
      (date) => new Date(date[0]) >= lastMonth && new Date(date[0]) <= thisMonth
    );

    if (array.length > 0) {
      return array;
    } else {
      return [[0, 0]];
    }
  };

  const handleTickets = (index) => {
    let tickets;

    switch (index) {
      case 0:
        tickets = sortToday(ticketsLastMonth);
        setTicketsChart([["Fecha", "Casos"], ...tickets]);
        setTotalTickets(total(tickets));
        break;
      case 1:
        tickets = sortYesterday(ticketsLastMonth);
        setTicketsChart([["Fecha", "Casos"], ...tickets]);
        setTotalTickets(total(tickets));
        break;
      case 2:
        tickets = sortLastWeek(ticketsLastMonth);
        setTicketsChart([["Fecha", "Casos"], ...tickets]);
        setTotalTickets(total(tickets));
        break;
      case 3:
        tickets = sortMonth(ticketsLastMonth);
        setTicketsChart([["Fecha", "Casos"], ...tickets]);
        setTotalTickets(total(tickets));
        break;
      case 4:
        tickets = sortLastMonth(ticketsLastMonth);
        setTicketsChart([["Fecha", "Casos"], ...tickets]);
        setTotalTickets(total(tickets));
        break;
      default:
        break;
    }

    return true;
  };

  const handlePayments = (index) => {
    let payments;

    switch (index) {
      case 0:
        payments = sortToday(paymentsTotalLastMonth);
        setPaymentsChart([["Fecha", "Total"], ...payments]);
        setTotalPayments(total(payments));
        break;
      case 1:
        payments = sortYesterday(paymentsTotalLastMonth);
        setPaymentsChart([["Fecha", "Total"], ...payments]);
        setTotalPayments(total(payments));
        break;
      case 2:
        payments = sortLastWeek(paymentsTotalLastMonth);
        setPaymentsChart([["Fecha", "Total"], ...payments]);
        setTotalPayments(total(payments));
        break;
      case 3:
        payments = sortMonth(paymentsTotalLastMonth);
        setPaymentsChart([["Fecha", "Total"], ...payments]);
        setTotalPayments(total(payments));
        break;
      case 4:
        payments = sortLastMonth(paymentsTotalLastMonth);
        setPaymentsChart([["Fecha", "Total"], ...payments]);
        setTotalPayments(total(payments));
        break;
      default:
        break;
    }

    return true;
  };

  const total = (data) => {
    let total = 0;

    for (let i = 0; i < data.length; i++) {
      total += data[i][1];
    }

    return total;
  };

  return (
    <Grid container justify="center" alignItems="center" spacing={0}>
      <Grid item xs={12} md={8}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" component="p">
              Seleccione una cuenta
            </Typography>
            <Select
              fullWidth
              classes={{ selectMenu: classes.select }}
              variant="outlined"
              value={account}
              onChange={(e) => {
                setAccount(e.target.value);
              }}
            >
              {accounts.map((item) => (
                <MenuItem key={`item-${item.id_cuenta}`} value={item}>
                  {item.alias_cuenta}
                </MenuItem>
              ))}
            </Select>
          </CardContent>
        </Card>
      </Grid>
      {account && (
        <>
          <Grid item xs={12} md={8}>
            <Card className={classes.card}>
              <div className={classes.flexRow}>
                <div className={classes.topCardBlue}>
                  <Typography className="mb-2" variant="subtitle2">
                    Cantidad de casos
                  </Typography>
                  <Typography variant="subtitle1">{totalTickets}</Typography>
                </div>
                <div className={classes.topCard}>
                  <Typography
                    className="mb-2"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Tiempo
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {options[selectedIndexTicket]}
                  </Typography>
                </div>
                <div className={classes.options}>
                  <IconButton
                    aria-label="settings"
                    onClick={(event) => setAnchorElTickets(event.currentTarget)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElTickets}
                    keepMounted
                    open={Boolean(anchorElTickets)}
                    onClose={() => setAnchorElTickets(null)}
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndexTicket}
                        onClick={() => handleMenuTicketClick(index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
              {chart(ticketsChart)}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card className={classes.card}>
              <div className={classes.flexRow}>
                <div className={classes.topCardBlue}>
                  <Typography className="mb-2" variant="subtitle2">
                    Total
                  </Typography>
                  <Typography variant="subtitle1">
                    <NumberFormat
                      prefix={"$ "}
                      displayType={"text"}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={totalPayments}
                    />
                  </Typography>
                </div>
                <div className={classes.topCard}>
                  <Typography
                    className="mb-2"
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Tiempo
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {options[selectedIndexPayments]}
                  </Typography>
                </div>
                <div className={classes.options}>
                  <IconButton
                    aria-label="settings"
                    onClick={(event) =>
                      setAnchorElPayments(event.currentTarget)
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorElPayments}
                    keepMounted
                    open={Boolean(anchorElPayments)}
                    onClose={() => setAnchorElTickets(null)}
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndexPayments}
                        onClick={() => handleMenuPaymentsClick(index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
              {chart(paymentsChart)}
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Charts;
