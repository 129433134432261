import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BuildIcon from "@material-ui/icons/Build";
import React from "react";
import Conditions from "../../common/Conditions";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2, 3),
  },
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  cardTitle: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    minHeight: theme.spacing(4),
  },
  cardIcon: {
    marginRight: theme.spacing(3),
  },
  contactListItem: {
    minHeight: theme.spacing(9),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  container: {
    overflow: "auto",
    height: "calc(100vh - 64px)",
    padding: 0,
  },
}));

  const ContentCardAccount = ({ accountName, contactType, contactTags, contact, getLogo }) => {
    const classes = useStyles();
    let avatar = (
      <Avatar className={classes.cardIcon} src={getLogo(contact.slug_cuenta)} />
    );
    let title = "Datos Cuenta";
    let subtitle = (<>
    Cuenta
    <br/>
    Asistencia
    <br/>
    Vehículo
    <br/>
    Mascota
    </>);
    let subtitleText = (
      <>
        {accountName}        
        <br />
        {contact.body_contacto.asistencia}
        <br />
        {contact.body_contacto.vehiculo}
        <br/>
        {contact.body_contacto.mascota}
      </>
    );

    if (contactType.includes("prestador")) {
      avatar = <BuildIcon className={classes.cardIcon} color="disabled" />;
      title = "Datos Prestador";
      subtitle = "Cuenta";
      subtitleText = (
        <>
          {accountName}
          {contact.config_cuenta && contact.config_cuenta.condiciones && (
            <Conditions condiciones={contact.config_cuenta.condiciones} />
          )}
          <br />
          {contactTags && contactTags.join(" , ")}
        </>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardTitle}>
            {avatar}
            <Typography>{title}</Typography>
          </div>
          <Divider className={classes.divider} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {subtitle}
            </Grid>
            <Grid item xs={6}>
              {subtitleText}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  export default ContentCardAccount;
