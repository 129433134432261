import React, { useState, useCallback, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { API } from "aws-amplify";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import Loading from "../common/Loading";

const useStyles = makeStyles(theme => ({
  centerGrid: {
    overflow: "hidden",
    textAlign: "center",
    height: "calc(100vh - 64px)"
  },
  root: {
    width: "100%",
    margin: theme.spacing(0, 2, 2, 2)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  card: {
    margin: theme.spacing(2)
  },
  select: {
    paddingTop: "10.5px",
    paddingBottom: "10.5px"
  }
}));

const Payments = () => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const order = "desc";
  const orderBy = "folio";
  const selected = [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const classes = useStyles();

  const fetchPayments = useCallback(async () => {
    setLoading(true);

    try {
      const init = {
        queryStringParameters: {
          cuenta: account.id_cuenta
        }
      };
      const result = await API.get(process.env.REACT_APP_HUB_API, "/tickets/pagos", init);

      if (result.success) {
        const items = [];
        let subtotal = 0;

        if (result.data) {
          for (let i = 0; i < result.data.length; i++) {
            const row = result.data[i];
            subtotal += row.monto;

            if (getEstado(row) === "No Aplica") {
              continue;
            }

            items.push(
              createData(
                row.fecha ? formatDate(row.fecha) : "Pendiente",
                row.alias_cuenta,
                row.folio_ticket,
                row.body_ticket.gestionar_asistencia && !row.body_ticket.gestionar_asistencia.red
                  ? row.body_ticket.gestionar_asistencia.prestador.nombre_contacto
                  : row.body_ticket.beneficiario.nombre_contacto,
                getEstado(row),
                getMonto(row)
              )
            );
          }
        }

        setTotal(subtotal);
        setRows(items);
      }
    } catch (err) {
      console.error("fetchPayments", err);
    }

    setLoading(false);
  }, [account]);

  const fetchAccounts = useCallback(async () => {
    try {
      setLoading(true);

      const result = await API.get(process.env.REACT_APP_HUB_API, "/cuentas");

      if (result.success) {
        if (result.data) setAccounts(result.data);
      } else console.warn("fetchAccounts", result.error);
    } catch (err) {
      console.error("fetchAccounts", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    account ? fetchPayments() : fetchAccounts();
  }, [account, fetchPayments, fetchAccounts]);

  const getEstado = row => {
    let estado = row.fecha ? "Realizado" : "Pendiente";

    if (!row.body_ticket.gestionar_asistencia && row.estado_ticket >= 4) {
      estado = "No Aplica";
    }

    return estado;
  };

  const getMonto = row => {
    let monto;

    if (row.body_ticket.gestionar_asistencia && row.body_ticket.liquidar_siniestro) {
      monto = (
        <NumberFormat
          prefix={"$ "}
          displayType={"text"}
          thousandSeparator="."
          decimalSeparator=","
          value={row.body_ticket.liquidar_siniestro.monto}
        />
      );
    }

    if (row.body_ticket.gestionar_asistencia && !row.body_ticket.liquidar_siniestro) {
      monto = "Pendiente";
    }

    if (!row.body_ticket.gestionar_asistencia && !row.body_ticket.liquidar_siniestro) {
      monto = "No Aplica";
    }

    return monto;
  };

  function createData(fecha, cuenta, folio, beneficiario, estado, monto) {
    return { fecha, cuenta, folio, beneficiario, estado, monto };
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const headCells = [
    { id: "fecha", numeric: false, disablePadding: false, label: "Fecha" },
    { id: "cuenta", numeric: false, disablePadding: false, label: "Cuenta" },
    { id: "folio", numeric: false, disablePadding: false, label: "Caso" },
    { id: "beneficiario", numeric: false, disablePadding: false, label: "Beneficiario" },
    { id: "estado", numeric: false, disablePadding: false, label: "Estado" },
    { id: "monto", numeric: true, disablePadding: false, label: "Monto" }
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const useToolbarStyles = makeStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: "1"
    }
  }));

  const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();

    return (
      <Toolbar className={clsx(classes.root)}>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Pagos
        </Typography>
        <Typography variant="body1" component="div">
          {new Date().toLocaleDateString("es-CL", {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </Typography>
      </Toolbar>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const emptyRows = 0;

  const formatDate = date => {
    const formattedDate = new Date(date);

    return formattedDate.toLocaleString("es-CL");
  };

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={8}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="p">
                Seleccione una cuenta
              </Typography>
              <Select
                fullWidth
                classes={{ selectMenu: classes.select }}
                variant="outlined"
                value={account}
                onChange={e => {
                  setAccount(e.target.value);
                }}
              >
                {accounts.map(item => (
                  <MenuItem key={`item-${item.id_cuenta}`} value={item}>
                    {item.alias_cuenta}
                  </MenuItem>
                ))}
              </Select>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Loading />
          ) : (
            account && (
              <>
                <Paper className={classes.paper}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} />
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.cuenta);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={`row-${index}`}
                                selected={isItemSelected}
                              >
                                <TableCell component="th" id={labelId} scope="row">
                                  {row.fecha}
                                </TableCell>
                                <TableCell>{row.cuenta}</TableCell>
                                <TableCell>{row.folio}</TableCell>
                                <TableCell>{row.beneficiario}</TableCell>
                                <TableCell>{row.estado}</TableCell>
                                <TableCell align="right">{row.monto}</TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={4} />
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell colSpan={4}></TableCell>
                          <TableCell colSpan={1}>
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              <NumberFormat
                                prefix={"$ "}
                                displayType={"text"}
                                thousandSeparator="."
                                decimalSeparator=","
                                value={total}
                              />
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Payments;
