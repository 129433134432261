export const TYPES_KEYS = {
  contacto: "contacto",
  asegurado: "asegurado",
  beneficiario: "beneficiario",
  prestador: "prestador"
};

export const CONTACT_TYPES = [
  { key: TYPES_KEYS.contacto, label: "Contacto", create: false },
  { key: TYPES_KEYS.asegurado, label: "Asegurado", create: true },
  { key: TYPES_KEYS.beneficiario, label: "Beneficiario", create: false },
  { key: TYPES_KEYS.prestador, label: "Prestador", create: true }
];

export const ACCOUNT_PRESTACIONES = [
  { 
    alias_cuenta: "Apoyo Medico", tipos: [
    {id: 0, name: 'Traslado medico terrestre'},
    {id: 1, name: 'Medico a domicilio'}    
    ]
  },
  { 
    alias_cuenta: "Autofin", tipos: [
    {id: 0, name: 'Cerrajero'},
    {id: 1, name: 'Vidriero'},
    {id: 2, name: 'Mecánico'},
    {id: 3, name: 'Neumático'},    
    ]
  },
  { 
    alias_cuenta: "Banco Estado Hogar", tipos: [
    {id: 0, name: 'Plomería'},
    {id: 1, name: 'Electricidad'},
    {id: 2, name: 'Cerrajería'},
    {id: 3, name: 'Vidriería'},
    {id: 4, name: 'Instalaciones'},
    {id: 5, name: 'Otros'},
    ]
  },
  { 
    alias_cuenta: "Banigualdad", tipos: [
    {id: 0, name: 'Funeraria'},
    {id: 1, name: 'Abogado'},
    {id: 2, name: 'Asistente social'},
    {id: 3, name: 'Orientación Médica Telefónica'},
    ]
  },
  { 
    alias_cuenta: "BCI Urgencia", tipos: [
    {id: 0, name: 'Sala de urgencia'},
    {id: 1, name: 'Orientación Médica Telefónica'},
    {id: 2, name: 'Telemedicina'},
    {id: 3, name: 'Médico a domicilio'},
    {id: 4, name: 'Traslado médico terrestre'},
    ]
  },
];
