import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { S3Image } from "aws-amplify-react";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";

const ImageLoader = ({
  avatar,
  thumb = false,
  variant = "rounded",
  className,
  imgKey,
  alt,
  bucket
}) => {
  const [loading, setLoading] = useState(true);
  const [showFallback, setShowFallback] = useState(false);
  const [count, setCount] = useState(1);
  const [url, setUrl] = useState(null);

  async function fetchFile(bucket, key) {
    setLoading(true);
    if (bucket === "serviclickafiliadosstorage212216-master"){
      bucket = "serviclickafiliadosprod01234055-staging"
    }
    try {
      const config = {
        bucket
      };
      const result = await Storage.get(key, config);
      setUrl(result);
    } catch (err) {
      console.warn("ImageLoader.fetchFile", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (bucket && imgKey) {
      fetchFile(bucket, imgKey);
    }
  }, [bucket, imgKey]);

  const handleLoad = event => {
    if (event && event.includes("http")) {
      setLoading(false);
      setShowFallback(false);
    }
  };

  const handleError = event => {
    console.warn("ImageLoader", "error trying to load image");
    setLoading(false);
    setShowFallback(true);

    if (count < 4) {
      setTimeout(() => {
        setLoading(true);
        setShowFallback(false);
      }, 2500);

      setCount(count + 1);
    }
  };

  const renderS3Image = () => {
    if (avatar) {
      if (thumb) {
        let parts = imgKey.split("/");
        let file = parts[parts.length - 1];
        parts[parts.length - 1] = `thumbnail-${file}`;
        imgKey = parts.join("/");
      }

      return (
        <Avatar variant={variant} alt={alt} className={className}>
          <S3Image
            hidden={loading || showFallback}
            imgKey={showFallback ? null : imgKey}
            onLoad={handleLoad}
            onError={handleError}
          />
          {loading && <Skeleton variant="rect" />}
          {showFallback && !loading && <PhotoCameraOutlinedIcon color="primary" />}
        </Avatar>
      );
    } else {
      return (
        <div className={className}>
          {bucket ? (
            <>{loading ? <Skeleton variant="rect" /> : <img src={url} alt={alt} />}</>
          ) : (
            <>
              <S3Image hidden={loading} imgKey={imgKey} onLoad={handleLoad} onError={handleError} />
              {loading && <Skeleton variant="rect" />}
            </>
          )}
        </div>
      );
    }
  };

  return renderS3Image();
};

export default ImageLoader;
