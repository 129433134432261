import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ContactsUpload from "../../components/contacts/ContactsUpload";

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "hidden"
  }
}));

const Upload = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={0} className={classes.overflowHidden}>
      <Grid item xs={12}>
        <ContactsUpload />
      </Grid>
    </Grid>
  );
};

export default Upload;
