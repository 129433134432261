import React, { useState, useRef } from "react";
import { API } from "aws-amplify";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../common/Loading";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { formatRut, rutEsValido } from "../../functions";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1
  },
  card: {
    minWidth: "100%",
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  alignRight: {
    textAlign: "right"
  },
  list: {
    width: "100%",
    height: "calc(100vh - 212px)",
    overflow: "auto"
  }
}));

const Search = ({ mobile, onDrawerOpen, selectContact, selectTicket }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [tickets, setTickets] = useState([]);
  const searchInput = useRef();

  const search = async () => {
    setLoading(true);
    if (rutEsValido(searchInput.current.value)) {
      setError(false);
      try {
        setLoading(true);

        const value = searchInput.current.value.replace(/\./g, "").replace(/-/g, "");

        const init = {
          queryStringParameters: {
            rut: value
          }
        };

        const result = await API.get(process.env.REACT_APP_HUB_API, "/contactos/rut", init);

        if (result.success) {
          if (result.data) {
            setContacts(result.data.contactos);
            setTickets([...result.data.tickets]);
          }
        } else console.warn("fetchAccounts", result.error);
      } catch (err) {
        console.error("fetchAccounts", err);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const TicketAvatar = ({ cuenta }) => {
    let srcImg;

    switch (cuenta) {
      case "banigualdad":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_banigualdad.png`;
        break;
      case "vias-chile":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_viaschile.png`;
        break;
      case "autofin":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_autofin.png`;
        break;
      case "BCI Urgencia":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_bci.png`;
        break;
      case "banco-estado-hogar":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_banco-estado.jpg`
        break;
      case "asistencia-apoyo-medico":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_apoyo-medico.jpg`
        break;
      case "ripley-sura":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_ripley-sura.jpg`;
        break;
      case "southbridge-mascota":
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_southbridge-mascota.jpg`;
        break;
      default:
        srcImg = `${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`;
        break;
    }

    return <Avatar src={srcImg} classes={{ root: classes.logo }} alt="logo_cuenta" />;
  };

  const TicketItemView = ({ ticket }) => {
    const classes = useStyles();
    const {
      slug_cuenta,
      nombre_ticket,
      alias_cuenta,
      folio_ticket,
      body_ticket: { asegurado, beneficiario, gestionar_asistencia, tipo_siniestro }
    } = ticket;

    const prestador = gestionar_asistencia && gestionar_asistencia.prestador;

    const TicketPrimaryInfo = () => (
      <div className={classes.primaryInfo}>
        <Typography color="textPrimary" variant="body1">
          {nombre_ticket}
        </Typography>
      </div>
    );

    const TicketSecondaryInfo = () => (
      <>
        <Typography variant="caption" color="textSecondary" component="p">
          {alias_cuenta}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          component="p"
        >{`Caso N°: ${folio_ticket}`}</Typography>
        {asegurado && asegurado.body_contacto.rut === searchInput.current.value && (
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              Asegurado
            </Typography>
            <Typography variant="caption" color="textSecondary" component="p">
              {asegurado.nombre_contacto}
            </Typography>
            <Typography variant="caption" color="primary" component="p">
              {formatRut(asegurado.body_contacto.rut)}
            </Typography>
          </>
        )}
        {slug_cuenta === "banigualdad" &&
          beneficiario &&
          beneficiario.body_contacto.rut === searchInput.current.value && (
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                Beneficiario
              </Typography>
              <Typography variant="caption" color="textSecondary" component="p">
                {beneficiario.nombre_contacto}
              </Typography>
              <Typography variant="caption" color="primary" component="p">
                {formatRut(beneficiario.body_contacto.rut)}
              </Typography>
            </>
          )}
        {prestador && prestador.body_contacto.rut === searchInput.current.value && (
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              Prestador
            </Typography>
            <Typography variant="caption" color="textSecondary" component="p">
              {prestador.nombre_contacto}
            </Typography>
            <Typography variant="caption" color="primary" component="p">
              {formatRut(prestador.body_contacto.rut)}
            </Typography>
          </>
        )}
        {tipo_siniestro && (
          <>
          <Typography
          variant="caption"
          color="textSecondary"
          component="p"
        >{`Tipo servicio: ${tipo_siniestro}`}</Typography>
        </>
        )}
      </>
    );

    return (
      <ListItem button alignItems="flex-start" onClick={() => selectTicket(ticket)}>
        <ListItemAvatar>
          <TicketAvatar cuenta={slug_cuenta} />
        </ListItemAvatar>
        <ListItemText
          primary={<TicketPrimaryInfo />}
          secondary={<TicketSecondaryInfo />}
          disableTypography
        />
      </ListItem>
    );
  };

  const ContactItemView = ({ contact }) => {
    const classes = useStyles();
    const { estado_contacto, nombre_contacto, tipo_contacto, body_contacto, alias_cuenta, slug_cuenta } = contact;

    const ContactPrimaryInfo = () => (
      <div className={classes.primaryInfo}>
        <Typography color="textPrimary" variant="body1">
          {nombre_contacto}
        </Typography>
      </div>
    );

    const ContactSecondaryInfo = () => (
      <>
        <Typography variant="caption" color="textSecondary" component="p">
          {alias_cuenta}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {tipo_contacto}
        </Typography>
        <Typography variant="caption" color="primary" component="p">
          {formatRut(body_contacto.rut)}
        </Typography>
      </>
    );

    return (
      <ListItem button alignItems="flex-start" onClick={() => selectContact(contact)}>
        <ListItemAvatar>
          <TicketAvatar cuenta={slug_cuenta} />
        </ListItemAvatar>
        <ListItemText
          primary={<ContactPrimaryInfo />}
          secondary={<ContactSecondaryInfo />}
          disableTypography
        />
      </ListItem>
    );
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          {onDrawerOpen && (
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              onClick={onDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h6" component="p">
            Buscador
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="rut"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="RUT"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputRef={searchInput}
                    onKeyDown={e => {
                      e.keyCode === 13 && search();
                    }}
                    onBlur={() => {
                      search();
                    }}
                    error={error}
                    helperText={error && "Debe ingresar un Rut valido"}
                  />
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={search}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {loading ? (
          <Loading variant="list" listSubText={3} />
        ) : (
          <List className={classes.list}>
            {contacts &&
              contacts.map((contact, i) => (
                <span key={`contact-${i}`}>
                  <ContactItemView contact={contact} />
                  <Divider />
                </span>
              ))}
            {tickets &&
              tickets.map((ticket, i) => (
                <span key={`ticket-${i}`}>
                  <TicketItemView key={`ticket-${i}`} ticket={ticket} />
                  <Divider />
                </span>
              ))}
          </List>
        )}
      </Grid>
    </>
  );
};

export default Search;
