import { makeStyles } from "@material-ui/core/styles";
import React, { createContext, useState } from "react";
import ContactCreate from "../components/contacts/ContactCreate";
import HubDrawer, { WIDTH as sideBarWidth } from "../components/HubDrawer";
import TicketCreate from "../components/tickets/TicketCreate";
import { DRAWER_KEYS } from "../constants/drawer";
import Contacts from "./desktop/Contacts";
import Upload from "./desktop/Upload";
import Removal from "./desktop/Removal"
import Tasks from "./desktop/Tasks";
import Tickets from "./desktop/Tickets";
import Reports from "./desktop/Reports";
import Search from "./desktop/Search";



const useStyles = makeStyles(theme => ({
  main: {
    marginLeft: sideBarWidth
  }
}));

export const DesktopContext = createContext("DesktopContext");

const Desktop = () => {
  const classes = useStyles();
  const [active, setActive] = useState("tasks");
  const [openContactCreate, setOpenContactCreate] = useState(false);
  const [openTicketCreate, setOpenTicketCreate] = useState(false);

  //Creacion de ticket desde contacto
  const [contacto, setContacto] = useState(null);

  const setCrearTicket = (contacto) => {
    setOpenTicketCreate(true);
    setContacto(contacto);
  };
  const context = {
    onContactCreated: () => setActive("contacts"),
    onTicketCreated: () => setActive("tickets"),
    onTicketCreate: setCrearTicket,
    removeContactFromTicket: () => setContacto(null),
    getContactFromTicket: contacto ? contacto : null
  };

  const CurrentView = () => {
    switch (active) {
      case DRAWER_KEYS.tasks:
        return <Tasks />;
      case DRAWER_KEYS.tickets:
        return <Tickets />;
      case DRAWER_KEYS.contacts:
        return <Contacts />;
      case "upload":
        return <Upload />;
      case "removal":
        return <Removal />;
      case DRAWER_KEYS.reports:
        return <Reports />;
      case DRAWER_KEYS.search:
        return <Search />;
      default:
        return <></>;
    }
  };

  return (
    <DesktopContext.Provider value={context}>
      <HubDrawer
        onDrawerItemClick={key => setActive(key)}
        onCreateTicket={() => setOpenTicketCreate(true)}
        onCreateContact={() => setOpenContactCreate(true)}
      />

      <main className={classes.main}>
        <CurrentView />
      </main>

      {openContactCreate && (
        <ContactCreate
          mobile={false}
          open={openContactCreate}
          onClose={() => setOpenContactCreate(false)}
        />
      )}
      {openTicketCreate && (
        <TicketCreate
          mobile={false}
          open={openTicketCreate}
          onClose={() => setOpenTicketCreate(false)}
          contact={contacto}
        />
      )}
    </DesktopContext.Provider>
  );
};

export default Desktop;
