import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  errorPage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    maxWidth: "1000px",
    padding: "16px",
    margin: theme.spacing(0, "auto"),

    "& img": {
      width: "80%",
      maxWidth: "400px",
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "0.35rem"
  },
  text: {
    fontSize: "2rem"
  },
  emoji: {
    fontSize: "30px",
    verticalAlign: "middle"
  }
}));

const ServerError = () => {
  const classes = useStyles();

  return (
    <div className={classes.errorPage}>
      <img src={`${process.env.PUBLIC_URL}/assets/images/logo_serviclick.png`} alt="logo" />
      <div className={classes.title}>
        <Typography className={classes.text} component="span">
          Ha ocurrido un error, favor intenta más tarde.
        </Typography>
        <span className={classes.emoji} role="img" aria-label="emoticon">
          😕
        </span>
      </div>
    </div>
  );
};

export default ServerError;
